<script setup lang="ts">
// Header.vue

import setting from '@/storage/setting'
import stores from '@/storage/stores'
import { useStoreInfo } from '@/utils/useStoreInfo'
import { reactive, ref, watch, type Ref } from 'vue'
import Navbar from '@/components/main/Navbar.vue'
import { storeHeader } from '@/event/main/header/storeHeader'
import { watchersHeader } from '@/utils/watchers/header/watchersHeader'

const { title, classTitle, updateStore, storeData, testDay, testHour, testMinute } = useStoreInfo()

watchersHeader()
// const storeHeader = reactive({
//   title: 'Eronor.com',
//   text: '',
//   classTitle: 'default-siege'
// })

// watch(
//   () => stores.state.storeData,
//   () => {
//     const data: any = stores.getStoresData()
//     if (data) {
//       updateStore(data)
//       // Ne mets à jour que si les valeurs ont réellement changé
//       if (title.value && title.value !== storeHeader.title) {
//         storeHeader.title = title.value
//       }

//       if (classTitle.value && classTitle.value !== storeHeader.classTitle) {
//         storeHeader.classTitle = classTitle.value
//       }
//     }
//   },
//   { immediate: true }
// )

// watch(
//   [testDay, testHour, testMinute],
//   () => {
//     const data: any = stores.getStoresData()
//     if (data) {
//       updateStore(data)
//       storeHeader.title = title.value
//       storeHeader.classTitle = classTitle.value
//     }
//   },
//   { immediate: false }
// )
</script>

<template>
  <header>
    <section class="section-header" :class="storeHeader.classTitle">
      <h1 class="text-h1">{{ storeHeader.title }}</h1>
      <p v-if="storeHeader.text" class="text">{{ storeHeader.text }}</p>
    </section>

    <Navbar />
  </header>
</template>

<style scoped>
header {
  @apply w-full;
}
.section-header {
  @apply flex flex-col items-center;
  line-height: 1.5;
}

.content-site {
  @apply min-h-screen flex flex-col;
}

header {
}

.text-h1 {
  @apply text-center;
}

.default-siege {
  @apply bg-amber-eronor px-3 py-2 flex justify-center text-gray-800;
}

.open-store {
  @apply bg-lime-eronor px-3 py-2 flex justify-center;
}

.close-store {
  @apply bg-red-eronor px-3 py-2 flex justify-center;
}

.text {
  @apply underline;
}
</style>
