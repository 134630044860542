import stores from '@/storage/stores'
import { watch } from 'vue'
import {
  catalogueBase,
  // configTelephone,
  isNavbarVisible,
  logo,
  settingNavbar
} from '../../main/navbar/statesNavbar'
import { type RouteLocationNormalizedLoaded } from 'vue-router'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { cartBus } from '@/event/cartBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import { shouldShowSectionOrder } from '../../main/navbar/shouldShowSectionOrder'
import customer, { isCustomerDefined } from '@/storage/customer'
import { fetchAddress } from '@/utils/address/fetchAddress'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'
import { showAlertLogin, toggleAlertLogin } from '@/event/account/showAlertLogin'
import router from '@/router'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { dataLoaded } from '@/event/dataLoaded'
import { checkAndRefreshToken } from '@/utils/jwt/checkAndrefreshToken'
import { showNavAccount } from '@/event/account/showNavAccount'
import { dashboardBus } from '@/event/dashboard/dashboardBus'
import { selectLinkAriane } from '@/utils/ariane/selectLinkAriane'
import { selectLinkArianeOrder } from '@/utils/ariane/selectLinkArianeOrder'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { productIdBus } from '@/event/product/productIdBus'
import { productBus } from '@/event/product/productBus'
import { clearSelection } from '@/utils/product/clearSelection'
import { goLogin } from '@/utils/main/navbar/goLogin'
import { goOutLogin } from '@/utils/main/navbar/goOutLogin'
import { fetchUser } from '@/utils/account/fetchUser'
import {
  excludeRouteDropdownCategoryArray,
  excludeRouteShowNavbar,
  excludeRouteShowSectionOrderBar
} from '@/storage/navbar/excludeRoute'
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory'
import { showDropdownSidebarSiteMap } from '@/event/main/navbar/showDropdownSidebarSitemap'

export function watchersNavbar(route: RouteLocationNormalizedLoaded) {
  const handleLoginRedirect = (routePath: string) => {
    if (['dashboard'].includes(routePath) && !isAuthenticated.value) {
      router.push('/')
    }
  }

  watch(
    () => route.name,
    (newRoute, oldRoute) => {
      if (oldRoute === 'login' && newRoute === 'register') {
        // toggleAlertLogin()
        showAlertLogin.value = false
      }
    }
  )

  watch(
    () => stores.state.storeData,
    () => {
      catalogueBase.value = stores.state.storeData.category
    },
    { deep: true }
  )

  watch(
    () => route.params.category_id,
    (newCategoryId) => {
      // if (newCategoryId) {
      //   categoryIdBus.value = Number(newCategoryId)
      // } else {
      //   categoryIdBus.value = 0
      // }
      categoryIdBus.value = newCategoryId ? Number(newCategoryId) : 0
    },
    { immediate: true }
  )

  watch(
    () => stores.state.storeData,
    (newValue) => {
      if (newValue && newValue.setting) {
        settingNavbar.value = stores.getStoresData().setting
        // configTelephone.value = settingNavbar.value.find(
        //   (setting: { key: string }) => setting.key === 'config_telephone'
        // ).value
        const logoItem = settingNavbar.value.find(
          (item: { key: string }) => item.key === 'config_logo'
        ).value

        logo.value = logoItem ?? null
      }
    },
    { immediate: true }
  )

  watch(
    () => cartBus.value,
    () => {
      const routeName = route.name
      sectionOrderBus.value = shouldShowSectionOrder(routeName, cartBus.value)
    },
    { deep: true }
  )

  // watch(
  //   () => isNavbarVisible.value,
  //   (newValue, oldRoute) => {
  //     // const routeName = route.name
  //     console.log('Navbar visibility changed:', newValue)

  //     // const shouldShowOrder = shouldShowSectionOrder(routeName, cartBus.value)
  //     // console.log('Should show section order:', shouldShowOrder)
  //     // if (newValue && shouldShowOrder) {
  //     //   sectionOrderBus.value = true
  //     //   console.log('Section Order Bus set to true')
  //     // } else {
  //     //   sectionOrderBus.value = false
  //     //   console.log('Section Order Bus set to false')
  //     // }
  //     const shouldShowOrder = shouldShowSectionOrder(route.name, cartBus.value)
  //     sectionOrderBus.value = newValue && shouldShowOrder
  //   },
  //   { deep: true }
  // )

  watch(
    () => updateAddressData.value,
    async () => {
      if (updateAddressData.value === true) {
        await fetchAddress()
        updateAddressData.value = false
      }
    },
    { immediate: true }
  )
}
