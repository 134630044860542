<script setup lang="ts">
import { ref } from 'vue'
import ButtonFooter from '@/components/button/ButtonFooter.vue'

const props = defineProps({
  title: String,
  content: String
})

const isVisible = ref(false)

const toggleDisplay = () => {
  isVisible.value = !isVisible.value
}
</script>

<template>
  <div>
    <h5 class="content-h5 text-uppercase">
      <p class="title">{{ title }}</p>
      <ButtonFooter :isVisible="isVisible" @click="toggleDisplay" />
    </h5>
    <div :class="['content-form', { show: isVisible }]">
      <div class="row-content"></div>
    </div>
  </div>
</template>

<style scoped>
.title {
  @apply w-full p-2;
  /* width: 98%; */
}
a {
  @apply underline flex gap-2 fill-current;
}
a:hover .svg {
  @apply fill-limedark-eronor shadow-inner;
}
a:hover {
  @apply text-limedark-eronor;
}

.svg {
  @apply fill-lime-eronor w-5;
}
.svg-stroke {
  @apply stroke-lime-eronor w-5;
}

/* .custom-button {
  @apply absolute py-2;
  width: 97%;
  height: 35px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: space-between;
} */
.row-content {
  @apply flex gap-2 flex-wrap;
}
.content-h5 {
  @apply relative flex justify-between items-center;
}

.text-uppercase {
  @apply uppercase font-semibold;
}
/* 
.cross-button {
  position: relative;

  width: 20px;
  height: 20px;
} */

/* .cross-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  background: white;
} */

/* .cross-button span::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 100%;
  height: 2.5px;
  background: inherit;
  transition: transform 0.3s ease-in-out;
} */

/* .cross-button.rotated span::before {
  transform: translate(-50%, -50%) rotate(0deg);
} */

.content-form {
  @apply p-2;
  display: none;
  transition: 1s ease-in-out;
}

.content-form.show {
  @apply flex flex-col gap-3;
}

@media screen and (min-width: 800px) {
  .content-form {
    @apply flex flex-col p-2 flex-wrap;
    /* display: none; */
    transition: 1s ease-in-out;
  }
}
</style>
