import { ref } from 'vue'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import {
  messageAddAddress,
  messageAddAddressEmpty
} from '@/event/account/dashboard/messageAddAddress'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'
import { toggleShowAddressForm } from '@/utils/toggle/toggleShowAddressForm'

export function useModifyAddress(
  addressModify: any,
  addressForm: any,
  supaddress: any,
  selectedCityId: any,
  selectedCity: any,
  selectedCountryId: any,
  selectedZoneId: any,
  company: any,
  defaultAddress: any,
  customer: any
) {
  const modifyAddress = async () => {
    const url = 'address/modify'

    const addressData = addressModify.value
    const data = {
      address_id: addressModify.value?.address_id,
      customer_id: customer.state.customerData.customer_id,
      customer_firstname: customer.state.customerData.firstname,
      customer_lastname: customer.state.customerData.lastname,
      address: addressForm.value || addressData?.address_1,
      code_postal_id: selectedCityId.value || addressData?.code_postal_id,
      city: selectedCity.value || addressData?.city + ' (' + addressData?.postcode + ')',
      country_id: selectedCountryId.value || addressData?.country_id,
      zone_id: selectedZoneId.value || addressData?.zone_id,
      company: company.value || addressData?.company || '',
      address_2: supaddress.value || addressData?.address_2 || '',
      default: defaultAddress.value || addressData?.default
    }

    const isDataNotEmpty = data.address == '' && data.city == ''
    if (isDataNotEmpty) {
      const messageNotAdd = {
        message: 'Les données necessaire sont manquantes !',
        class: 'error',
        errors: [],
        address: 0
      }
      messageAddAddress.value = messageNotAdd
      return
    }

    const isDataUnchanged =
      data.address === (addressData?.address_1 || addressForm.value) &&
      data.code_postal_id === (addressData?.code_postal_id || selectedCityId.value) &&
      data.city ===
        (addressData?.city + ' (' + addressData?.postcode + ')' || selectedCity.value) &&
      data.country_id === (addressData?.country_id || selectedCountryId.value) &&
      data.zone_id === (addressData?.zone_id || selectedZoneId.value) &&
      data.company === (addressData?.company || company.value) &&
      data.address_2 === (addressData?.address_2 || supaddress.value) &&
      data.default === (String(addressData?.default) || defaultAddress.value)

    if (isDataUnchanged) {
      const messageIdenticalData = {
        message: 'Les données sont identiques. Aucun changement effectué.',
        class: 'error',
        errors: [],
        address: 0
      }
      messageAddAddress.value = messageIdenticalData
      setTimeout(() => {
        messageAddAddress.value = messageAddAddressEmpty
        toggleShowAddressForm()
      }, 2000)
      return
    }

    try {
      messageAddAddress.value = await fetchPostAuthorization(url, data)
      if (messageAddAddress.value.class == 'success') {
        updateAddressData.value = true
        setTimeout(() => {
          messageAddAddress.value = messageAddAddressEmpty
          toggleShowAddressForm()
        }, 5000)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { modifyAddress }
}
