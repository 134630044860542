import { ref, watch } from 'vue'
// import { route } from '@/event/route/route'

export const categoryNameBus = ref<string[] | string>()

// watch(
//   [() => categoryNameBus.value, () => route?.name],
//   (newCategory, routeName) => {
//     if (categoryNameBus.value && routeName) {
//       categoryNameBus.value = ''
//     }
//   },
//   { deep: true }
// )
