export function formatPostalCode(postcode: string) {
  // Vérifie si le code postal est composé de 4 chiffres
  const postcodeStr = postcode.toString()

  // Vérifier si le code postal a exactement 4 chiffres
  if (postcodeStr.length === 4) {
    return '0' + postcodeStr // Ajouter un 0 devant si c'est un code postal de 4 chiffres
  }
  return postcodeStr
}
