<script setup lang="ts">
// SelectHoursOrder.vue
import ButtonNextOrder from '@/components/button/order/ButtonNextOrder.vue'
import { storeIsOpen } from '@/event/closeBus'
import { updateFieldOrderData } from '@/event/order/data/orderData'
import { configDeliveryTime, type DeliveryTimeType } from '@/storage/configDeliveryTime'
import { configOpeningHours } from '@/storage/configOpeningHours'
import stores, { type HoursType, type OpeningHoursType } from '@/storage/stores'
import { computed, onMounted, ref, watch, watchEffect, type Ref } from 'vue'

const props = defineProps({ isOpenInitial: String })
const emitContinue = defineEmits()
const withdraw = ref('')
const showButton = ref(false)
const dropdownSelectHoursOrder = ref(false)
const selectHourFirstPart: Ref<number | string> = ref('')
const selectMinuteFirstPart = ref<string>('')
const selectHourSecondPart = ref('')
const selectMinuteSecondePart = ref<string>('')

const forwardOrdering = ref()
const afterOpening = ref()
const beforeClosing = ref()
const timeRange = ref()

const messageErrorSelectHoursEmpty = {
  message: '',
  class: ''
}

const messageErrorSelectHours = ref(messageErrorSelectHoursEmpty)

const availableTimeFirstPart: string[][] = []
const availableHoursFirstPart: number[] = []
const availableTimeSecondPart: string[][] = []
const availableHoursSecondPart: number[] = []

function setIntervalOpeningHours(
  configOpeningHours: { value: OpeningHoursType },
  configDeliveryTime: { value: DeliveryTimeType }
) {
  const openingHours = configOpeningHours.value
  const deliveryTime = configDeliveryTime.value

  const currentDate = new Date()
  let currentDay = currentDate.getDay() // 0 = dimanche, 1 = lundi, ..., 6 = samedi

  if (currentDay === 0) {
    currentDay = 7
  }

  const currentDayString = String(currentDay)

  function timeToMinutes(time: any): number {
    const [hours, minutes] = time.split(':').map((num: string) => parseInt(num, 10))
    return hours * 60 + minutes
  }

  function timeInHourAndMinute(minutes: number) {
    const hour = Math.floor(minutes / 60)
    let minute = minutes % 60
    // return `${hour}:${minute.toString().padStart(2, '0')}`
    // Appliquer padStart pour afficher un zéro devant les minutes si c'est un nombre à un chiffre
    const minuteFormatted = minute < 10 ? minute.toString().padStart(2, '0') : minute.toString()
    return [hour, minuteFormatted]
  }
  // function timeInHourAndMinute(time: any) {
  //   const hour = Math.floor(time / 60)
  //   let minute = String(time % 60)
  //   if (minute.length == 1) {
  //     minute = 0 + minute
  //   }
  //   const fullHour = `${hour}:${minute}`
  //   return fullHour
  // }

  // const pickupService =
  //   openingHours.pickup_service && openingHours.pickup_service.length > 0
  //     ? openingHours.pickup_service
  //     : openingHours.opening_hours
  const pickupService =
    openingHours.pickup_service && openingHours.pickup_service.length > 0
      ? openingHours.pickup_service.map((item) => ({ ...item }))
      : openingHours.opening_hours.map((item) => ({ ...item }))

  // const todaysHours = pickupService.filter((item) => item.days.includes(String(currentDay)))

  const todaysHours = pickupService.filter((item) => item.days.includes(currentDayString))

  // Normaliser les résultats après le filtre
  const normalizedTodaysHours = todaysHours.map((item) => ({ ...item }))

  // const currentTimeMinutes = timeToMinutes(`${currentDate.getHours()}:${currentDate.getMinutes()}`) // Heure actuelle en minutes
  const currentTimeMinutes =
    timeToMinutes(`${currentDate.getHours()}:${currentDate.getMinutes()}`) +
    Number(deliveryTime.time_range)

  let firstPart = false

  normalizedTodaysHours.forEach((pick: HoursType, index: number) => {
    if (pick.days) {
      const openTime = timeToMinutes(pick.open)
      let closeTime = timeToMinutes(pick.close)
      const orderAvailableAtOpenTime = openTime + Number(deliveryTime.after_opening)

      const orderAvailableUntilCloseTime = closeTime - Number(deliveryTime.before_closing)

      let prepTimeStart = orderAvailableAtOpenTime
      const prepTimeEnd = orderAvailableUntilCloseTime - Number(deliveryTime.time_range)

      if (!firstPart) {
        // if (currentTimeMinutes > orderAvailableUntilCloseTime) {
        //   // Si l'heure actuelle est au-delà de l'intervalle disponible, ne pas ajouter de données
        //   return
        // }
        if (prepTimeStart > currentTimeMinutes) {
          const [hour, minute] = timeInHourAndMinute(prepTimeStart)

          availableHoursFirstPart.push(Number(hour))
          if (!availableTimeFirstPart[Number(hour)]) {
            availableTimeFirstPart[Number(hour)] = [] // Initialise un tableau si la clé n'existe pas
          }
          availableTimeFirstPart[Number(hour)].push(String(minute))
        }
        while (prepTimeStart <= prepTimeEnd) {
          prepTimeStart += Number(deliveryTime.time_range)
          const [hour, minute] = timeInHourAndMinute(prepTimeStart)

          if (prepTimeStart > currentTimeMinutes) {
            if (!availableHoursFirstPart.find((h) => h == hour)) {
              availableHoursFirstPart.push(Number(hour))
            }

            if (!availableTimeFirstPart[Number(hour)]) {
              availableTimeFirstPart[Number(hour)] = [] // Initialise un tableau si la clé n'existe pas
            }
            availableTimeFirstPart[Number(hour)].push(String(minute))
            if (prepTimeStart >= prepTimeEnd) {
              firstPart = true
            }
          }
        }
        // }
      } else {
        if (prepTimeStart > currentTimeMinutes) {
          const [hour, minute] = timeInHourAndMinute(prepTimeStart)

          availableHoursSecondPart.push(Number(hour))
          if (!availableTimeSecondPart[Number(hour)]) {
            availableTimeSecondPart[Number(hour)] = [] // Initialise un tableau si la clé n'existe pas
          }
          availableTimeSecondPart[Number(hour)].push(String(minute))
        }
        while (prepTimeStart <= prepTimeEnd) {
          prepTimeStart += Number(deliveryTime.waiting_time)
          const [hour, minute] = timeInHourAndMinute(prepTimeStart)

          if (!availableHoursSecondPart.find((h) => h == hour)) {
            availableHoursSecondPart.push(Number(hour))
          }
          if (!availableTimeSecondPart[Number(hour)]) {
            availableTimeSecondPart[Number(hour)] = [] // Initialise un tableau si la clé n'existe pas
          }
          Number(hour)
          availableTimeSecondPart[Number(hour)].push(String(minute))
        }
      }
    }
  })
  // normalizedTodaysHours.forEach((pick) => {

  //   const openTime = timeToMinutes(pick.open)
  //   const closeTime = timeToMinutes(pick.close)

  //   const orderAvailableAtOpenTime = openTime + deliveryTime.after_opening
  //   const orderAvailableUntilCloseTime = closeTime - deliveryTime.before_closing

  //   let prepTimeStart = orderAvailableAtOpenTime
  //   const prepTimeEnd = orderAvailableUntilCloseTime - deliveryTime.waiting_time

  //   while (prepTimeStart <= prepTimeEnd) {
  //     const formattedTime = timeInHourAndMinute(prepTimeStart)

  //     if (!firstPart && currentTimeMinutes <= prepTimeStart) {
  //       availableTimeFirstPart.push(formattedTime)
  //     } else if (currentTimeMinutes <= prepTimeStart) {
  //       availableTimeSecondPart.push(formattedTime)
  //     }

  //     prepTimeStart += deliveryTime.waiting_time

  //     if (prepTimeStart >= prepTimeEnd) {
  //       firstPart = true
  //     }
  //   }
  // })
}

const availableMinutesForSelectedHour = computed(() => {
  if (
    selectHourFirstPart.value !== '' &&
    availableTimeFirstPart[Number(selectHourFirstPart.value)]
  ) {
    return availableTimeFirstPart[Number(selectHourFirstPart.value)]
  }

  return []
})

const availableMinutesForSelectedHourSecondPart = computed(() => {
  if (
    selectHourSecondPart.value !== '' &&
    availableTimeSecondPart[Number(selectHourSecondPart.value)]
  ) {
    return availableTimeSecondPart[Number(selectHourSecondPart.value)]
  }
})
// availableTimeFirstPart = []
// availableTimeSecondPart = []

watchEffect(() => {
  forwardOrdering.value = configDeliveryTime.value?.forward_ordering || null
  afterOpening.value = configDeliveryTime.value?.after_opening || null
  beforeClosing.value = configDeliveryTime.value?.before_closing || null
  timeRange.value = configDeliveryTime.value?.time_range || null
})

onMounted(() => {
  if (configOpeningHours.value && configDeliveryTime.value) {
    setIntervalOpeningHours(configOpeningHours, configDeliveryTime)
  }
})
// const updateDropdownState = (newProps: string) => {
//   dropdownSelectHoursOrder.value = newProps === 'step2'
//   showButton.value = dropdownSelectHoursOrder.value
// }
// watch(
//   () => props.isOpenInitial,
//   (newProps) => {
//     if (newProps) updateDropdownState(newProps)
//   }
// )

watch(
  () => props.isOpenInitial,
  (newProps, oldProps) => {
    if (newProps === 'step2') {
      dropdownSelectHoursOrder.value = true
      showButton.value = true
    } else {
      dropdownSelectHoursOrder.value = false
    }
  }
)

function toggleDropdownSelectHoursOrder() {
  emitContinue('continue', { step: 'step2' })
  dropdownSelectHoursOrder.value = !dropdownSelectHoursOrder.value
}

function addSelectOrderData() {
  let deliveryTimeSelect

  if (selectHourFirstPart.value && selectMinuteFirstPart.value) {
    deliveryTimeSelect = `${selectHourFirstPart.value}:${selectMinuteFirstPart}`
  }

  // if (selectHourSecondPart.value.length !== 0) {
  //   deliveryTimeSelect = selectHourSecondPart.value
  // }

  if (withdraw.value == 'selectHours') {
    updateFieldOrderData({
      delivery_time: deliveryTimeSelect,
      order_time: 'Today'
    })
  }
}

const onContinue = () => {
  const messageError = {
    message: '',
    class: 'error'
  }

  if (selectHourFirstPart.value !== 0 && selectHourSecondPart.value.length !== 0) {
    messageError.message = "Sélectionner qu'une seul plage horaire"
    messageErrorSelectHours.value = messageError
    setTimeout(() => {
      messageErrorSelectHours.value = messageErrorSelectHoursEmpty
    }, 3000)
  } else if (
    (withdraw.value.length !== 0 && withdraw.value == 'now') ||
    (withdraw.value == 'selectHours' &&
      (selectHourFirstPart.value !== 0 || selectHourSecondPart.value.length !== 0))
  ) {
    emitContinue('continue', { step: 'step3' })
    dropdownSelectHoursOrder.value = false // Fermer ce dropdown une fois "Continuer" cliqué
    addSelectOrderData()
  } else {
    messageError.message = 'Veuillez faire un choix'
    messageErrorSelectHours.value = messageError
    setTimeout(() => {
      messageErrorSelectHours.value = messageErrorSelectHoursEmpty
    }, 3000)
  }
}
</script>

<template>
  <section class="section-select-hours-order">
    <button
      class="button-select-hours-order"
      :class="{ isactive: dropdownSelectHoursOrder, 'rounded-md': !dropdownSelectHoursOrder }"
      @click="toggleDropdownSelectHoursOrder()"
      :disabled="!showButton"
    >
      <div class="flex items-center">
        <svg
          viewBox="0 0 510 510"
          id="acorder"
          class="svg-clock-select"
          :class="{ isactive: dropdownSelectHoursOrder }"
        >
          <path
            d="M267.75,12.75c-89.25,0-168.3,48.45-209.1,122.4L0,76.5v165.75h165.75
        l-71.4-71.4c33.15-63.75,96.9-107.1,173.4-107.1C372.3,63.75,459,150.45,459,255s-86.7,191.25-191.25,191.25
        c-84.15,0-153-53.55-181.05-127.5H33.15c28.05,102,122.4,178.5,234.6,178.5C402.9,497.25,510,387.6,510,255
        C510,122.4,400.35,12.75,267.75,12.75z M229.5,140.25V270.3l119.85,71.4l20.4-33.15l-102-61.2v-107.1H229.5z"
          ></path>
        </svg>
        <h4 class="pl-5">Retrait</h4>
      </div>
      <svg
        class="svg-arrowbottom"
        viewBox="0 0 24 24"
        :class="{
          'rotate-up': dropdownSelectHoursOrder,
          isactive: dropdownSelectHoursOrder,
          hidden: !showButton
        }"
      >
        <path
          d="M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.3897 15.171 12.1989 15.25 12 15.25C11.8011 15.25 11.6103 15.171 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z"
        />
      </svg>
    </button>

    <section v-if="dropdownSelectHoursOrder" class="section-content-select-hours">
      <label class="label-select-withdraw" for="now"
        ><input
          type="radio"
          name="withdraw"
          v-model="withdraw"
          id="now"
          value="now"
          :disabled="!storeIsOpen"
        />
        <div class="flex flex-col">
          <p :class="{ 'line-through': !storeIsOpen }">Dès que possible</p>
          <p v-if="!storeIsOpen" :class="{ notOpen: !storeIsOpen }">
            (Le restaurant ne peut pas prendre votre commande pour le moment. Merci de la passer
            ultérieurement)
          </p>
        </div>
      </label>
      <label
        class="label-select-withdraw"
        for="selectHours"
        v-if="availableTimeFirstPart.length > 0 || availableTimeSecondPart.length > 0"
      >
        <input
          type="radio"
          name="withdraw"
          v-model="withdraw"
          id="selectHours"
          value="selectHours"
        />
        Aujourd'hui
      </label>
      <!-- <p v-if="availableTimeFirstPart.length == 0 && availableTimeSecondPart.length == 0">
        Nous ne sommes pas ouvert aujourd'hui,
      </p> -->
      <div class="w-full text-center" v-if="withdraw == 'selectHours'">
        <!-- <p
          class="text-today"
          v-if="availableTimeFirstPart.length !== 0 && availableTimeSecondPart.length !== 0"
        >
          Sélectionner qu'une seul plage horaire
        </p> -->
        <div class="div-select-hours">
          <label
            v-if="availableTimeFirstPart.length !== 0 || availableTimeSecondPart.length !== 0"
            for="delivery_time_first_part"
          >
            <div class="label-hours-order">
              <!-- <span class="text-red-eronor">*</span> -->
              <!-- <p v-if="availableTimeFirstPart.length !== 0 && availableTimeSecondPart.length !== 0">
                Première partie de journée
              </p> -->
              <!-- <p v-else>Horaire disponible</p> -->
              <p>Horaire disponible</p>
            </div>

            <div class="div-select-hours-input">
              <select
                v-model="selectHourFirstPart"
                name="delivery_time_first_part"
                id="delivery_time_first_part"
                class="select-delivery-time"
              >
                <option value="" class="option-select-hours" selected disabled>Heure</option>
                <!-- Sélection des heures disponibles -->
                <option
                  v-for="(hour, index) in availableHoursFirstPart"
                  :key="hour"
                  :value="String(hour)"
                  class="option-select-hours"
                >
                  {{ hour + ' h' }}
                  <!-- Affiche l'heure exacte comme '10 h', '11 h' -->
                </option>
                <option
                  class="option-select-hours"
                  :value="hour"
                  v-for="(hour, index) in availableHoursSecondPart"
                  :key="index"
                >
                  {{ hour + ' h' }}
                </option>
              </select>
              <select
                v-model="selectMinuteFirstPart"
                name="delivery_time_first_part_minutes"
                id="delivery_time_first_part_minutes"
                class="select-delivery-time"
                :disabled="availableMinutesForSelectedHour.length === 0"
              >
                <option value="" class="option-select-hours" selected disabled>Minutes</option>
                <option
                  v-for="minute in availableMinutesForSelectedHour"
                  :key="minute"
                  :value="minute"
                  class="option-select-hours"
                >
                  {{ minute }}
                </option>
                <option
                  value=""
                  v-for="(minute, index) in availableMinutesForSelectedHourSecondPart"
                  :key="index"
                >
                  {{ minute }}
                </option>
              </select>
            </div>
          </label>
          <!-- <label v-if="availableTimeSecondPart.length !== 0" for="delivery_time_second_part">
            <div class="label-hours_order">
              <p>Seconde partie de journée</p>
            </div>
            <select
              v-model="selectHourSecondPart"
              name="delivery_time_second_part"
              id="delivery_time_second_part"
              class="select-delivery-time"
            >
              <option class="option-select-hours" value="" selected>Heure</option>
              <option
                class="option-select-hours"
                :value="hour"
                v-for="(hour, index) in availableHoursSecondPart"
                :key="index"
              >
                {{ hour + ' h' }}
              </option>
            </select>
            <select
              v-model="selectMinuteSecondePart"
              name="delivery_time_second_part_minute"
              id="delivery_time_second_part_minute"
              class="select-delivery-time"
            >
              <option value="" class="option-select-hours" selected>Minutes</option>
              <option
                value=""
                v-for="(minute, index) in availableMinutesForSelectedHourSecondPart"
                :key="index"
              >
                {{ minute }}
              </option>
            </select>
          </label> -->
        </div>
      </div>

      <p v-if="messageErrorSelectHours.message.length !== 0" :class="messageErrorSelectHours.class">
        {{ messageErrorSelectHours.message }}
      </p>

      <div class="div-btn-continue-hours">
        <ButtonNextOrder @click="onContinue" />
      </div>
    </section>
  </section>
</template>

<style scoped>
.section-select-hours-order {
  @apply bg-slate-eronor rounded-md;
}
.button-select-hours-order {
  @apply flex items-center gap-2 justify-between w-full py-3 pl-4 pr-2;
}
.svg-clock-select {
  @apply w-8 fill-white-eronor;
}
.svg-arrowbottom {
  @apply w-10 fill-white;
  transition: transform 0.5s ease;
}
.isactive {
  @apply fill-lime-eronor text-lime-eronor rounded-t-md bg-slate-hover;
}

.rotate-up {
  transform: rotate(180deg); /* Rotation à 180 degrés pour pointer vers le haut */
}

.button-select-hours-order:hover {
  @apply bg-slate-hover;
}
.button-select-hours-order:hover svg,
.button-select-hours-order:hover h4 {
  @apply fill-lime-eronor text-lime-eronor;
}
.section-content-select-hours {
  @apply w-full border-t py-2 flex flex-col items-center  gap-1;
}
.label-select-withdraw {
  @apply flex items-baseline gap-3 w-full px-4;
}
.text-today {
  @apply text-red-eronor;
}
.notOpen {
  @apply text-red-eronor;
}
.div-select-hours {
  @apply max-w-full relative w-full px-4 pt-2;
}

.label-hours-order {
  @apply flex w-full flex-col;
}
.div-select-hours-input {
  @apply flex gap-3 justify-center;
}
.select-delivery-time {
  @apply w-full max-w-28 rounded-md text-slate-eronor text-wrap my-1 p-1;
}
.option-select-hours {
  @apply text-center max-h-40;
}
.error {
  @apply bg-red-eronor text-center w-full mt-1 px-1 py-2;
}
.div-btn-continue-hours {
  @apply w-full pt-2 pr-2 flex justify-end;
}

@media screen and (min-width: 400px) {
  .select-delivery-time {
    @apply w-80;
  }
  .section-content-select-hours {
    @apply px-8;
  }
  .error {
    @apply rounded-md;
  }
}
@media screen and (min-width: 700px) {
  .div-select-hours-input {
    @apply justify-start;
  }
  .label-hours-order {
    @apply items-start pl-14;
  }
}
@media screen and (min-width: 900px) {
  .div-select-hours {
    /* @apply flex justify-around; */
    @apply pl-10;
  }
}
</style>
