import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import { computed } from 'vue'
import stores from './stores'

export const configDeliveryZones = computed<DeliveryZone[]>(() => {
  const setting = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_delivery_zones'
  )

  // Vérifiez si `setting` est défini avant d'accéder à `value`
  return setting?.value || []
})
