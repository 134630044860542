<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill=""
    version="1.1"
    id="Layer_1"
    viewBox="0 0 297.001 297.001"
    xml:space="preserve"
    class="svg-garbage"
  >
    <g>
      <g>
        <g>
          <path
            d="M241.348,85.53c-2.184-4.45-5.971-7.782-10.662-9.384L94.056,29.498c-9.686-3.303-20.251,1.884-23.558,11.567     l-5.573,16.324l171.753,58.638l5.574-16.323C243.854,95.014,243.533,89.979,241.348,85.53z"
          />
          <path
            d="M148.5,14.472l49.205,16.799l-6.157,18.036l12.069,4.12l8.218-24.07c1.138-3.333-0.642-6.957-3.974-8.095l-61.274-20.92     c-1.599-0.547-3.353-0.434-4.871,0.31c-1.518,0.745-2.679,2.064-3.225,3.663l-8.218,24.071l12.07,4.122L148.5,14.472z"
          />
          <path
            d="M183.237,275.016c0,5.099-4.133,9.232-9.232,9.232s-9.232-4.133-9.232-9.232V104.954l-42.338-14.455H60.256     c-0.926,0-1.857,0.152-2.701,0.531c-2.53,1.137-3.953,3.644-3.799,6.226l11.562,193.749c0.201,3.367,2.991,5.996,6.365,5.996     h138.741c3.374,0,6.164-2.629,6.365-5.996l9.843-164.931l-43.395-14.816V275.016z M117.333,275.016     c0,5.099-4.133,9.232-9.232,9.232s-9.232-4.133-9.232-9.232V103.252h18.464V275.016z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.svg-garbage {
  @apply px-1 w-8 fill-white-eronor;
}
</style>
