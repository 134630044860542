<script setup lang="ts"></script>

<template>
  <section class="section-button-back-dashboard">
    <router-link to="/dashboard" class="button-back-dashboard"
      ><svg
        class="svg-back-dashboard"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15 20L7 12L15 4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>Retour</p>
    </router-link>
  </section>
</template>

<style scoped>
.section-button-back-dashboard {
  @apply flex mt-2 w-full;
}
.button-back-dashboard {
  @apply bg-lime-eronor hover:bg-limedark-eronor py-1 px-2 rounded-md flex gap-2;
}
.button-back-dashboard:hover p,
.button-back-dashboard:hover .svg-back-dashboard {
  @apply text-black stroke-black;
}
.svg-back-dashboard {
  @apply w-6 stroke-white;
}
@media screen and (min-width: 800px) {
  .section-button-back-dashboard {
    /* @apply w-[50rem]; */
  }
}
</style>
