import { reactive } from 'vue'

interface Customer {
  customer_id: number
  store_id: number
  language_id: number
  firstname: string
  lastname: string
  email: string
  telephone: string
  address_id: number
}

const state = reactive({
  customerData: {} as Partial<Customer>
})
// Fonction pour mettre à jour les données du client
function setCustomerData(data: Customer) {
  state.customerData = data
}

// Fonction pour obtenir les données du client
function getCustomerData() {
  return state.customerData
}

export function isCustomerDefined() {
  return !!state.customerData.customer_id // Retourne `true` si `customer_id` est défini
}

export type { Customer }

export default {
  state,
  setCustomerData,
  getCustomerData
}
