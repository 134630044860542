import { watch } from 'vue'
import stores from '@/storage/stores'
import products from '@/storage/products'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

// Hook personnalisé pour observer les changements de données de catalogue et produits
export function useCatalogProductWatchers(
  catalogueBase: any,
  catalogProduct: any,
  categoryId: any,
  categoryNameBus: any,
  filterProductsByCategory: (categoryId: any) => void
) {
  const route = useRoute()

  // Observer les changements de storeData pour mettre à jour catalogueBase
  watch(
    () => stores.state.storeData,
    (newValue) => {
      catalogueBase.value = newValue.category
    }
  )

  // Observer les changements de productData pour mettre à jour catalogProduct et appliquer un filtre
  watch(
    () => products.state.productData,
    (newValue) => {
      catalogProduct.value = newValue
      filterProductsByCategory(categoryId.value)
    }
  )

  // Observer les changements de category_id dans la route
  watch(
    () => route.params.category_id,
    (newCategoryId) => {
      categoryNameBus.value = route.params.category_name
      categoryId.value = newCategoryId
      filterProductsByCategory(newCategoryId)
    }
  )

  // Observer les changements de catalogueBase ou catalogProduct pour filtrer les produits selon la catégorie
  watch([() => catalogueBase.value, () => catalogProduct.value], () => {
    if (categoryId.value) {
      filterProductsByCategory(categoryId.value)
    }
  })
}
