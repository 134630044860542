<template>
  <svg class="svg-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M4 12H20M12 4V20"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped>
.svg-plus {
  @apply w-4;
}
</style>
