import { fetchPostAuthorization } from '../fetch/fetchPostAuthorization'

export async function updateCancelPayment(orderId: string | string[]) {
  const url = 'order/update-cancel'

  const data = {
    order_id: Number(orderId)
  }

  const response = await fetchPostAuthorization(url, data)
}
