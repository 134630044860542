export const getOptionValueName = (
  optionValueId: any,
  languageId: number,
  product: { options: any[] }
) => {
  if (!product || !product.options) {
    return ''
  }

  const option = product.options.find((opt) =>
    opt.values.some((val: { option_value_id: any }) => val.option_value_id === optionValueId)
  )

  if (!option || !option.values) {
    return ''
  }

  const value = option.values.find(
    (val: { option_value_id: any }) => val.option_value_id === optionValueId
  )

  if (!value || !value.language) {
    return ''
  }

  const language = value.language.find(
    (lang: { language_id: number }) => lang.language_id === languageId
  )

  return language ? language.name : 'Unknown'
}
