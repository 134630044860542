<script setup lang="ts">
import { watch } from 'vue'

const props = defineProps<{ show: boolean; function: () => void }>()

const toggle = () => {
  props.function()
}
</script>

<template>
  <div v-if="props.show" @click="toggle" class="overlay"></div>
</template>

<style scoped>
.overlay {
  @apply fixed top-0 left-0 w-full h-screen flex content-center items-center z-20 opacity-90;

  background-color: rgba(0, 0, 0, 0.5); /* Fond noir semi-transparent */
}
</style>
