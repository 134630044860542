<script setup lang="ts">
import { forgotInputBus } from '@/event/account/forgotInputBus'

const toggleForgotInputEmail = () => {
  forgotInputBus.value = !forgotInputBus.value
}
</script>

<template>
  <button class="button-forgot" @click="toggleForgotInputEmail">
    <p>Mot de passe oublié</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="svg-padlock"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 380 380"
      xml:space="preserve"
    >
      <g id="XMLID_30_">
        <path
          id="XMLID_31_"
          d="M290,170H125V95c0-35.841,29.159-65,65-65c35.841,0,65,29.159,65,65v30c0,8.284,6.716,15,15,15   s15-6.716,15-15V95c0-52.383-42.617-95-95-95c-52.383,0-95,42.617-95,95v75h-5c-13.807,0-25,11.193-25,25v160   c0,13.807,11.193,25,25,25h200c13.807,0,25-11.193,25-25V195C315,181.193,303.807,170,290,170z M160,255c0-16.569,13.431-30,30-30   c16.568,0,30,13.431,30,30c0,11.103-6.035,20.789-15,25.977V325h-30v-44.023C166.035,275.789,160,266.103,160,255z"
        />
      </g>
    </svg>
  </button>
</template>

<style scoped>
.button-forgot {
  @apply bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white flex items-center gap-2 py-1 px-2 rounded-lg;
}

.svg-padlock {
  @apply w-5 fill-white-eronor;
}
</style>
