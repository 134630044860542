import { ref } from 'vue'
import { messageModify, typeMessageModify } from './messageModify'

export const showAlertModify = ref(false)

export const toggleAlertModify = () => {
  showAlertModify.value = !showAlertModify.value
  if (showAlertModify.value === false) {
    messageModify.value = typeMessageModify
  }
}
