import { ref, watch } from 'vue'
import { numberBasket } from './basketBus'
import stores from '@/storage/stores'
import { formatStoreName } from '@/utils/store/formatStoreName'

export interface SelectedProduct {
  optionName: string
  productName: string
  valueId: number
  option_id: number
  price: number
  type: string
  title: number
  // price_tax: number
  sort_order: number
  reset: number
  product_option_id: number
}
export interface CartItem {
  category_id: number
  product_image: string
  product_id: number
  product: string
  price: number
  total_price: number
  price_tax: number
  rate_tax: number
  quantity: number
  lbl_printer_id: number
  printer_port: number
  printer_id: number
  product_option: SelectedProduct[]
}
export interface CartItemWithSoloPrice {
  category_id: number
  product_image: string
  product_id: number
  product: string
  product_option: SelectedProduct[]
  price: number
  price_tax: number
  rate_tax: number
  quantity: number
  solo_price: string
}

// Vérifier si le localStorage contient des données valides

export const cartBus = ref<CartItem[]>([])
export const showCartBus = ref(false)
export const indexUpdateElement = ref()

watch(
  () => stores.state.storeData,
  () => {
    const store = stores.state.storeData.name
    let savedCart
    let initialCart: CartItem[] = []

    if (store) {
      const storeName = formatStoreName(store)
      // console.log(storeName)
      savedCart = localStorage.getItem(`${storeName}_cart`)
    }

    if (savedCart) {
      try {
        const parsedCart = JSON.parse(savedCart)

        // Vérifier que parsedCart est bien un tableau d'objets de type CartItem
        if (
          Array.isArray(parsedCart) &&
          parsedCart.every((item) => typeof item.product === 'string')
        ) {
          // initialCart = parsedCart
          cartBus.value = parsedCart
          // Mettre à jour numberBasket avec le nombre total d'articles
          // numberBasket.value = parsedCart.length
          numberBasket.value = parsedCart.reduce((acc, item) => acc + item.quantity, 0)
        }
      } catch (error) {}
    }
  }
)

watch(cartBus.value, (newValue) => {
  const store = stores.state.storeData.name

  if (store) {
    const storeName = formatStoreName(store)

    localStorage.setItem(`${storeName}_cart`, JSON.stringify(newValue))
  }
  // numberBasket.value = cartBus.value.length
  numberBasket.value = newValue.reduce(
    (acc: any, item: { quantity: any }) => acc + item.quantity,
    0
  )
})
