<script setup lang="ts">
const props = defineProps({ text: String })
</script>

<template>
  <button class="button-address-add">
    <svg
      class="svg-address-add"
      viewBox="0 0 780 1060"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M743.85 371.925C743.85 445.683 722.303 516.981 681.523 578.118L414.32 997.224L371.925 1059.99L329.539 997.224L62.3265 578.118C21.5473 516.972 0 445.683 0 371.925C0 166.849 166.84 0 371.925 0C577.01 0 743.85 166.849 743.85 371.925ZM557.887 371.925C557.887 269.382 474.468 185.963 371.925 185.963C269.382 185.963 185.962 269.382 185.962 371.925C185.962 475.421 270.027 557.888 371.925 557.888C473.796 557.888 557.887 475.421 557.887 371.925Z"
      />
      <path
        d="M747.676 901.644C765.273 901.644 779.554 887.384 779.554 869.766C779.554 852.17 765.273 837.889 747.676 837.889H702.665V792.878C702.665 775.26 688.384 761 670.788 761C653.191 761 638.91 775.26 638.91 792.878V837.889H593.878C576.281 837.889 562 852.149 562 869.766C562 887.384 576.281 901.644 593.878 901.644H638.91V946.676C638.91 964.294 653.191 978.554 670.788 978.554C688.384 978.554 702.665 964.294 702.665 946.676V901.644H747.676Z"
      />
    </svg>
    <p>{{ props.text }}</p>
  </button>
</template>

<style scoped>
.button-address-add {
  @apply bg-lime-eronor hover:bg-limedark-eronor flex gap-2 rounded-md items-center py-1 px-2;
}
.svg-address-add {
  @apply w-5 fill-white;
}
.button-address-add:hover p,
.button-address-add:hover .svg-address-add {
  @apply text-black fill-black;
}
</style>
