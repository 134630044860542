<script setup lang="ts">
import { ref } from 'vue'
import IconPlus from '@/components/icons/iconEronor/IconPlus.vue'
import IconSubstract from '@/components/icons/iconEronor/IconSubstract.vue'

const props = defineProps<{ quantity: number; index: number }>()
const quantity = ref<number>(1) // Initial quantity

if (props.quantity > quantity.value) {
  quantity.value = props.quantity
}

const emit = defineEmits(['update:quantity'])

const increment = () => {
  quantity.value++
  emit('update:quantity', quantity.value, props.index)
}

// Function to decrement quantity
const decrement = () => {
  if (quantity.value > 1) {
    quantity.value--
    emit('update:quantity', quantity.value, props.index)
  }
}
</script>

<template>
  <div class="section-quantity">
    <h4>Quantités</h4>
    <div class="div-quantity">
      <button @click="decrement" class="btn-quantity">
        <p><IconSubstract /></p>
      </button>
      <input type="text" name="quantity" v-model="quantity" class="" disabled />
      <button @click="increment" class="btn-quantity"><IconPlus /></button>
    </div>
  </div>
</template>

<style scoped>
/* quantity */
.quantity-input {
  @apply w-8;
}
/* .section-quantity {
  @apply pb-4;
} */
.section-quantity h4 {
  @apply text-xl ml-2 underline;
}
.div-quantity {
  @apply flex items-center gap-2 m-1;
}

/* .div-quantity input {
  @apply w-14 bg-transparent font-semibold text-3xl text-center align-middle;
} */
.div-quantity input {
  @apply w-8 bg-transparent font-semibold text-3xl text-center align-middle;
}

.btn-quantity {
  @apply flex justify-center items-center align-middle h-7 w-7 bg-white-eronor  text-black font-bold text-xl rounded-3xl;
}
/* .btn-quantity {
  @apply flex justify-center min-w-10 min-h-10 bg-white-eronor text-black font-bold text-2xl rounded-3xl;
} */
.btn-quantity:hover {
  @apply bg-limedark-eronor text-white-eronor shadow-md;
}
</style>
