import { computed } from 'vue'
import stores from './stores'

export const configTelephone = computed(() => {
  const dataConfigTelephone = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_telephone'
  )

  return dataConfigTelephone?.value || null
})
