<script setup lang="ts">
import ButtonClose from '@/components/button/ButtonClose.vue'
import { configDeliveryZones } from '@/storage/configDeliveryZones'
import stores from '@/storage/stores'
import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import { toggleShowDeliveryZones } from '@/utils/order/toggleShowDeliveryZones'
import { computed, ref } from 'vue'
import emptyCircle from '@/components/icons/eronor/svg/empty-circle.svg'

// const deliveryZone = computed<DeliveryZone[]>(() => {
//   return (
//     (stores.state.storeData.setting?.find(
//       (setting: { key: string }) => setting.key === 'config_delivery_zones'
//     )?.value as DeliveryZone[]) || []
//   )
// })
const dropdownDeliveryZone = ref(configDeliveryZones.value.map(() => false))

function toggleDeliveryZone(index: any) {
  dropdownDeliveryZone.value[index] = !dropdownDeliveryZone.value[index]
}
</script>

<template>
  <section class="section-alert-delivery-zones">
    <div class="div-h3-delivery-zones">
      <h3 class="h3-alert-delivery-zones">Zones de livraison</h3>
      <ButtonClose :function="toggleShowDeliveryZones" />
    </div>
    <div class="div-content-delivery-zones">
      <!-- <p class="p-legend-delivery">
        <span class="span-legend-delivery" style="width: 12px; height: 12px; content: ''"> </span>=
        prix minimum pour une livraison
      </p> -->
      <ul>
        <div v-for="(zone, index) in configDeliveryZones" :key="index" class="">
          <!-- <span class="bullet"></span>  -->
          <li @click="toggleDeliveryZone(index)" class="list-disc button-delivery">
            {{ zone.city }}
          </li>
          <div v-if="dropdownDeliveryZone[index]" class="hover:text-lime-eronor pl-4">
            <li class="li-delivery" :style="{ backgroundImage: `url(${emptyCircle})` }">
              Frais de livraison: {{ zone.price }} €
            </li>
            <li class="li-delivery" :style="{ backgroundImage: `url(${emptyCircle})` }">
              Montant minimum du panier: {{ zone.minimum_price }}€
            </li>
            <li class="li-delivery" :style="{ backgroundImage: `url(${emptyCircle})` }">
              Frais de livraison gratuite à partir de: {{ zone.free_price }} €
            </li>
          </div>
        </div>
      </ul>
    </div>
  </section>
</template>

<style scoped>
.section-alert-delivery-zones {
  @apply absolute flex flex-col z-20 bg-slate-medium-dark w-full pb-5 rounded-md -top-20;
}
.div-h3-delivery-zones {
  @apply bg-lime-eronor flex items-center pt-5 pb-2 justify-between px-6;
}
.h3-alert-delivery-zones {
  @apply align-middle font-semibold;
}
.div-content-delivery-zones {
  @apply pl-8 pr-2 pt-3;
}

.p-legend-delivery {
  @apply inline-flex items-center -ml-4 pb-3 text-red-eronor;
}
.span-legend-delivery {
  @apply bg-red-eronor mr-2  inline-flex;
}
.button-delivery {
  @apply hover:text-lime-eronor;
}
/* .li-delivery {
  @apply list-image-[url(/src/components/icons/eronor/svg/empty-circle.svg)];
} */
/* .li-delivery {
  @apply list-image-[url('@/components/icons/eronor/svg/empty-circle.svg')];
} */

@media screen and (min-width: 400px) {
  .h3-alert-delivery-zones {
    @apply px-10;
  }
  .div-content-delivery-zones {
    @apply pl-20;
  }
}

@media screen and (min-width: 600px) {
  .section-alert-delivery-zones {
    @apply max-w-[35rem];
  }
}
.bullet {
  @apply relative pl-4;
}
.bullet::before {
  @apply flex items-center;
  font-size: x-large;
  content: '•';
  position: absolute;
  left: 0;
}
</style>
