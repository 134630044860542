<script setup lang="ts"></script>

<template>
  <router-link to="/login" class="button-login">
    <svg
      class="svg-person-empty"
      viewBox="0 0 744 705"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M464.945 434.621L443.238 460.489L475.469 470.561L699.815 540.667C712.654 544.685 721.353 556.531 721.353 569.922V651.189C721.353 668.142 707.658 681.818 690.724 681.818H53.1293C36.1954 681.818 22.5 668.142 22.5 651.189V569.922C22.5 556.485 31.1997 544.672 44.0187 540.673L44.0285 540.67L268.404 470.561L300.652 460.485L278.923 434.614C234.447 381.66 206.221 295.542 206.221 214.147C206.221 153.507 223.477 105.89 251.831 73.7547C279.931 41.9069 320.616 23.3008 371.948 23.3008C423.255 23.3008 463.931 41.9051 492.026 73.7533C520.376 105.889 537.632 153.507 537.632 214.147C537.632 295.559 509.387 381.661 464.945 434.621Z"
        stroke=""
        stroke-width="50"
      />
    </svg>

    <p>Se connecter</p>
  </router-link>
</template>

<style scoped>
.button-login {
  @apply flex  items-center justify-center flex-nowrap gap-2 py-1 px-2 rounded-md bg-lime-eronor min-w-32 hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white;
}
.button-login p {
  @apply text-nowrap;
}

.svg-person-empty {
  @apply w-5 stroke-white;
}
</style>
