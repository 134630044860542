<script setup lang="ts">
import Alert from '@/components/alert/Alert.vue'
import ButtonModify from '@/components/button/ButtonModify.vue'
import { messageAlert } from '@/event/alert/messageAlert'
import { showAlertUpdateInfo } from '@/event/account/dashboard/showAlertUpdateInfo'
import customer from '@/storage/customer'

import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { toggleUpdateInformations } from '@/utils/toggle/dashboard/toggleUpdateInformations'
import { computed, ref, watch } from 'vue'
import { messageUpdateInformations } from '@/event/account/dashboard/messageUpdateInformation'

const firstname = ref('')
const lastname = ref('')
const email = ref('')
const telephone = ref('')
const focused = ref('')
const isLoadedFromStorage = ref(false)

const formattedTelephone = computed({
  get() {
    if (isLoadedFromStorage.value) {
      const newNumber = telephone.value
      return newNumber.replace(/^(\d{6})(\d+)/, '******$2').replace(/(\d{2})(?=\d)/g, '$1.')
    }
    return telephone.value.replace(/(\d{2})(?=\d)/g, '$1.')
  },
  set(value) {
    telephone.value = value.replace(/\./g, '')
  }
})

const handleTelephoneInput = (event: any) => {
  // Lorsque l'utilisateur commence à modifier, réinitialisez l'état de chargement
  if (isLoadedFromStorage.value) {
    isLoadedFromStorage.value = false // Indiquer que l'utilisateur a commencé à modifier
  }
}

if (customer.state.customerData) {
  isLoadedFromStorage.value = true
  firstname.value = customer.state.customerData.firstname ?? ''
  lastname.value = customer.state.customerData.lastname ?? ''
  email.value = customer.state.customerData.email ?? ''
  telephone.value = customer.state.customerData.telephone ?? ''
}

watch(
  () => customer.state.customerData,
  (newCustomer) => {
    if (newCustomer) {
      isLoadedFromStorage.value = true
      firstname.value = newCustomer.firstname ?? ''
      lastname.value = newCustomer.lastname ?? ''
      email.value = newCustomer.email ?? ''
      telephone.value = newCustomer.telephone ?? ''
    }
  },
  { deep: true }
)

async function modifyCustomerInformations() {
  const url = 'customer/update'

  const data = {
    customer_id: customer.state.customerData.customer_id,
    firstname: firstname.value,
    lastname: lastname.value.toUpperCase(),
    email: email.value,
    telephone: telephone.value
  }
  const isDataIdentical =
    data.firstname === customer.state.customerData.firstname &&
    data.lastname === customer.state.customerData.lastname &&
    data.email === customer.state.customerData.email &&
    data.telephone === customer.state.customerData.telephone

  if (isDataIdentical) {
    showAlertUpdateInfo.value = true
    const messageA = {
      message: "Les données n'ont pas changé. Aucune requête envoyée.",
      class: 'error',
      errors: []
    }
    messageUpdateInformations.value = messageA
    return
  }

  try {
    messageUpdateInformations.value = await fetchPostAuthorization(url, data)

    if (messageUpdateInformations.value.class) {
      showAlertUpdateInfo.value = true

      setTimeout(() => {
        messageUpdateInformations.value = messageAlert
        showAlertUpdateInfo.value = false
      }, 5000)
    }
  } catch (error) {}
}
</script>

<template>
  <section class="section-edit-form">
    <form class="form-edit-dashboard" @submit.prevent="modifyCustomerInformations">
      <div class="form-group-edit">
        <input
          type="text"
          v-model="firstname"
          id="firstname-edit"
          required
          @focus="focused = 'firstname'"
          @blur="focused = ''"
          :class="{ 'has-value': firstname !== '' || focused === 'firstname' }"
        />
        <label
          for="firstname-edit"
          :class="{ active: firstname !== '' || focused === 'firstname' }"
        >
          <span>*</span> Prénom
        </label>
      </div>

      <div class="form-group-edit">
        <input
          type="text"
          v-model="lastname"
          id="lastname-edit"
          required
          @focus="focused = 'lastname'"
          @blur="focused = ''"
          :class="{ 'has-value': lastname !== '' || focused === 'lastname' }"
        />
        <label for="lastname-edit" :class="{ active: lastname !== '' || focused === 'lastname' }">
          <span>*</span> Nom de famille
        </label>
      </div>

      <div class="form-group-edit">
        <input
          type="email"
          v-model="email"
          id="email-edit"
          required
          @focus="focused = 'email'"
          @blur="focused = ''"
          :class="{ 'has-value': email !== '' || focused === 'email' }"
        />
        <label for="email-edit" :class="{ active: email !== '' || focused === 'email' }">
          <span>*</span> Email
        </label>
      </div>

      <div class="form-group-edit">
        <input
          type="tel"
          v-model="formattedTelephone"
          id="telephone-edit"
          required
          @focus="focused = 'telephone'"
          @blur="focused = ''"
          @input="handleTelephoneInput"
          :class="{ 'has-value': telephone !== '' || focused === 'telephone' }"
        />
        <label
          for="telephone-edit"
          :class="{ active: telephone !== '' || focused === 'telephone' }"
        >
          <span>*</span> Téléphone
        </label>
      </div>
      <ButtonModify />
    </form>
  </section>
</template>

<style scoped>
.section-edit-form {
  @apply relative flex flex-col items-center;
}
.form-edit-dashboard {
  @apply flex flex-col items-center gap-7 pt-8 bg-slate-eronor p-3 rounded-md;
}
.form-edit-dashboard input {
  @apply rounded-2xl border-2 outline-none focus:border-red-eronor;
}
.form-group-edit {
  @apply relative flex flex-col items-center;
}
label {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor;
}
input {
  @apply text-slate-eronor text-center min-w-64;
}
label.active {
  transition: all 1s ease;
}
span {
  @apply text-red-eronor;
}
</style>
