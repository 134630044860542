import { computed } from 'vue'
import stores from './stores'

export const paymentStripeproStatus = computed(() => {
  const status = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'payment_stripepro_status'
  )

  return status?.value || null
})
