<template>
  <svg class="svg-warning logo-animation" viewBox="0 0 734 666" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M728.667 615.667L397 15.6667C393.807 10.8536 389.473 6.90542 384.384 4.17421C379.295 1.44301 373.609 0.0136719 367.833 0.0136719C362.058 0.0136719 356.372 1.44301 351.282 4.17421C346.193 6.90542 341.859 10.8536 338.667 15.6667L5.33326 615.667C2.42924 620.695 0.889068 626.394 0.86527 632.201C0.841473 638.008 2.33487 643.719 5.19758 648.771C8.06029 653.823 12.193 658.04 17.1864 661.003C22.1798 663.966 27.8608 665.574 33.6666 665.667H700.333C706.139 665.574 711.82 663.966 716.813 661.003C721.807 658.04 725.94 653.823 728.802 648.771C731.665 643.719 733.158 638.008 733.135 632.201C733.111 626.394 731.571 620.695 728.667 615.667ZM367 549C360.407 549 353.963 547.045 348.481 543.382C342.999 539.72 338.727 534.514 336.204 528.423C333.681 522.332 333.021 515.63 334.307 509.164C335.593 502.698 338.768 496.758 343.43 492.096C348.091 487.435 354.031 484.26 360.497 482.974C366.963 481.688 373.665 482.348 379.756 484.871C385.847 487.394 391.053 491.666 394.716 497.148C398.378 502.629 400.333 509.074 400.333 515.667C400.333 524.507 396.821 532.986 390.57 539.237C384.319 545.488 375.84 549 367 549ZM400.333 415.667C400.333 424.507 396.821 432.986 390.57 439.237C384.319 445.488 375.84 449 367 449C358.159 449 349.681 445.488 343.43 439.237C337.178 432.986 333.667 424.507 333.667 415.667V265.667C333.667 256.826 337.178 248.348 343.43 242.096C349.681 235.845 358.159 232.333 367 232.333C375.84 232.333 384.319 235.845 390.57 242.096C396.821 248.348 400.333 256.826 400.333 265.667V415.667Z"
    />
  </svg>
</template>

<style scoped>
.svg-warning {
  @apply w-10 fill-amber-eronor;
}
.logo-animation {
  animation: pulse 2s infinite; /* Lancement de l'animation pulse */
}
@keyframes pulse {
  0% {
    transform: scale(1); /* Taille initiale */
  }
  50% {
    transform: scale(1.4); /* Taille agrandie */
  }
  100% {
    transform: scale(1); /* Retour à la taille initiale */
  }
}
</style>
