<script setup lang="ts">
import {
  messageDeleteAddress,
  messageEmptyDeleteAddress,
  type TypeMessageDeleteAddress
} from '@/event/account/dashboard/address/messageDeleteAddress'
import { showAlertDeleteAddress } from '@/event/account/dashboard/address/showAlertDeleteAddress'
import { messageAlert, type TypeMessageAlert } from '@/event/alert/messageAlert'
import { ref, watch } from 'vue'
import ButtonClose from '@/components/button/ButtonClose.vue'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import ButtonValidateDeleteAddress from '@/components/button/address/ButtonValidateDeleteAddress.vue'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'

const props = defineProps<{ function: () => void; message: TypeMessageDeleteAddress }>()
const message = ref(props.message)

const messageEmptyDeleteSuccess = {
  message: '',
  class: ''
}
const messageDeleteSuccess = ref(messageEmptyDeleteSuccess)
const messageDeleteError = ref(messageEmptyDeleteSuccess)

const counter = ref(0)

watch(
  () => props.message,
  (newMessage) => {
    message.value = newMessage
  }
)
watch(
  () => showAlertDeleteAddress.value,
  () => {
    message.value = messageEmptyDeleteAddress
  }
)

function toggle() {
  // message.value = messageEmptyDeleteAddress
  props.function()
}

function toggleError() {
  props.function()
  messageDeleteError.value = messageEmptyDeleteSuccess
}

async function deleteAddressFunction() {
  const url = 'address/delete'

  const data = {
    address_id: message.value.address_id
  }

  if (counter.value == 0) {
    try {
      counter.value = 1
      const messageDeleteSubmit = await fetchPostAuthorization(url, data)

      if (messageDeleteSubmit.class === 'success') {
        messageDeleteAddress.value = { ...messageEmptyDeleteAddress }
        messageDeleteSuccess.value = messageDeleteSubmit
        // message.value = messageDeleteSubmit
        updateAddressData.value = true
        setTimeout(() => {
          toggle()
          messageDeleteSuccess.value = messageEmptyDeleteSuccess
        }, 2000)
        counter.value = 0
      } else {
        messageDeleteAddress.value = { ...messageEmptyDeleteAddress }
        message.value = messageEmptyDeleteAddress
        messageDeleteError.value = messageDeleteSubmit
        counter.value = 0
      }
    } catch (e) {}
  }
}
</script>

<template>
  <!-- <section v-if="message.class || messageDeleteSuccess.class" class="section-alert-delete-address"> -->
  <section
    v-if="message.class || messageDeleteSuccess.class || messageDeleteError.class"
    class="section-alert-delete-address"
  >
    <section v-if="message.class == 'error'">
      <div :class="message.class">
        <div class="flex flex-col">
          <p>{{ message.city }}</p>
          <p>Adresse : {{ message.address }}</p>
        </div>

        <p>voulez-vous supprimer cette adresse ?</p>

        <div class="w-full flex justify-between">
          <ButtonClose class="button" :function="toggle" />
          <ButtonValidateDeleteAddress
            class="button-validate-delete-address"
            :function="deleteAddressFunction"
            :disabled="counter == 1"
          />
        </div>
      </div>
    </section>
    <section v-if="messageDeleteSuccess.class == 'success'" :class="messageDeleteSuccess.class">
      <p>{{ messageDeleteSuccess.message }}</p>
    </section>
    <section
      v-if="messageDeleteError.class == 'error'"
      class="mt-8"
      :class="messageDeleteError.class"
    >
      <div class="w-full flex justify-end">
        <ButtonClose class="button" :function="toggleError" />
      </div>
      <p>{{ messageDeleteError.message }}</p>
    </section>
  </section>
</template>

<style scoped>
.button-validate-delete-address:hover button .svg-validate {
  @apply fill-white-eronor;
}
.section-alert-delete-address {
  @apply w-full flex flex-col items-center absolute max-w-[24rem] z-30 h-screen top-1/3;
}
.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-3 rounded-md   gap-3;
}
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-1 pb-3 rounded-md  gap-3;
}
</style>
