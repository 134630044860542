import { categoryIdBus } from '@/event/category/categoryIdBus'
import { productIdBus } from '@/event/product/productIdBus'
import { getElementFromCart } from './getElementFromCart'
import { quantityBus } from '@/event/product/quantityBus'
import { selectOptionsFromCart } from './seclectOptionsFromCart'
import { updateProduct } from './updateProduct'
import { updateCategory } from './updateCategory'
import { productBus } from '@/event/product/productBus'
import { extractRelatedOptions } from './extractRelatedOptions'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export const updateValues = async (route: RouteLocationNormalizedLoaded) => {
  //   categoryIdBus.value = Number(route.params.category_id)
  //   productIdBus.value = Number(route.params.product_id)
  try {
    const { category_id, product_id } = route.params as { category_id: any; product_id: any }
    categoryIdBus.value = Number(category_id)
    productIdBus.value = Number(product_id)

    const cartElement = getElementFromCart()
    if (cartElement) {
      quantityBus.value = cartElement.quantity
      selectOptionsFromCart(cartElement)
    }

    await updateProduct()
    updateCategory()

    if (productBus.value) {
      extractRelatedOptions(productBus.value.options)
    }
  } catch (e) {}
}
