import { languageIdBus } from '@/event/languageIdBus'

export const getProductDescription = (product: {
  description: { language_id: number; description: string }[]
}) => {
  if (!product || !product.description) {
    return 'Aucune description'
  }

  const description = product.description.find(
    (desc: { language_id: number }) => desc.language_id === languageIdBus.value
  )

  return description ? description.description : 'Aucune description'
}
