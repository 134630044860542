import products from '@/storage/products'
import stores from '@/storage/stores'
import { getStore } from '@/utils/getStore'
import { reactive, ref } from 'vue'
import { getProductToCategory } from './getProductToCategory'
import { dataLoaded } from '@/event/dataLoaded'
import { useRoute } from 'vue-router'

const fullUrl = window.location.href

const categoryIds = ref()
const catalogueBase = ref()
// const router = useRoute()

export async function loadData() {
  try {
    await getStore()

    if (stores.state.storeData) {
      catalogueBase.value = stores.state.storeData.category
      if (catalogueBase.value.length > 0) {
        categoryIds.value = catalogueBase.value.map(
          (category: { category_id: any }) => category.category_id
        )
      }

      if (products.getProductData().length === 0) {
        await getProductToCategory(categoryIds.value)
      }

      dataLoaded.value = 2
    }
  } catch (e) {
    dataLoaded.value = 1
  }
}
