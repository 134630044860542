import { ref } from 'vue'
import { messageLogin, typeMessageLogin } from './messageLogin'

export const showAlertLogin = ref(false)

export const toggleAlertLogin = () => {
  showAlertLogin.value = !showAlertLogin.value
  if (showAlertLogin.value === false) {
    messageLogin.value = typeMessageLogin
  }
}
