import { cartBus, indexUpdateElement } from '@/event/cartBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'

export const extractRelatedOptions = (productOptions: any[]) => {
  if (productOptions) {
    productOptions.forEach((option) => {
      option.values.forEach((value: { related_option_id: any }) => {
        if (value.related_option_id && Array.isArray(value.related_option_id)) {
          relatedGroupBus.value.push(...value.related_option_id)
        }
      })
    })
  }

  if (indexUpdateElement.value >= 0) {
    if (cartBus.value[indexUpdateElement.value]) {
      const cartElement = cartBus.value[indexUpdateElement.value].product_option
      if (cartElement) {
        cartElement.forEach((element: { option_id: number }) => {
          const index = relatedGroupBus.value.indexOf(element.option_id)
          if (index !== -1) {
            relatedGroupBus.value.splice(index, 1) // Enlever l'option_id trouvé
          }
        })
      }
    }
  }
}
