// useStoreInfo.ts

import { onUnmounted, ref, watch, type Ref } from 'vue'
import stores, { type HoursType, type StoresDataType } from '@/storage/stores'
import setting, { type OpeningHoursType } from '@/storage/setting'
import { storeIsOpen } from '@/event/closeBus'

export function useStoreInfo() {
  const title = ref()
  const classTitle = ref()
  const textTitle = ref('')
  const text = ref('')
  const storeSetting = ref()
  const arrayPlanning = ref<HoursType[]>([])
  const stopInterval = ref(false)

  const { state } = stores
  const { storeData } = state

  const testDay = ref<number | null>(null) // Simuler un jour (1 = Lundi, 2 = Mardi, etc.)
  const testHour = ref<number | null>(null) // Simuler une heure
  const testMinute = ref<number | null>(null) // Simuler des minutes
  const testTime = ref<number | null>(null) //new Date().getTime() + 6 * 105 * 60 * 1000

  const updateStore = (storeData: StoresDataType) => {
    const classOpen = 'open-store'
    const classClose = 'close-store'
    const classDefault = 'default-siege'

    const textForOpen = `Le restaurant ouvre à `
    const textOpenClose =
      'Le restaurant est fermé pour la commande en ligne. Veuillez réessayer plus tard.'
    const textTodayClose = "Le restaurant a fermé pour aujourd'hui. Veuillez revenir demain"
    const textClosedForTonight = 'Le restaurant est fermé pour ce soir. Veuillez revenir demain'
    const textNotsortedPlanning = "Le restaurant n'a pas fournit d'horaire d'ouverture"

    if (storeData.name) {
      const configWebOrder = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_web_order'
      )
      const configOpeningHours = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_opening_hours'
      )
      // arrayPlanning.value = (configOpeningHours.value as OpeningHoursType).opening_hours

      if (configOpeningHours) {
        // Proceed only if configOpeningHours is defined
        arrayPlanning.value = (configOpeningHours.value as OpeningHoursType).opening_hours
      } else {
        // Handle the case where configOpeningHours is not found
        arrayPlanning.value = [] // Or set to a default value
      }

      // console.log(configWebOrder.value)

      if (Number(configWebOrder.value) !== 0) {
        // console.log('la')
        const today = new Date()
        const todayDay = testDay.value !== null ? testDay.value : today.getUTCDay() || 7 // Le jour (0 = Dimanche devient 7)
        const todayHours = testHour.value !== null ? testHour.value : today.getHours() // Heure
        const todayMinutes = testMinute.value !== null ? testMinute.value : today.getMinutes() // Minutes
        const todayTime = testTime.value !== null ? testTime.value : today.getTime()

        const isOpen = ref(false)
        let nextOpeningTime = ''
        let closedForTonight = false

        // Trier les horaires par heure d'ouverture
        const sortedPlanning = [...arrayPlanning.value].sort((a, b) => {
          const [aHour] = a.open.split(':').map(Number)
          const [bHour] = b.open.split(':').map(Number)
          return aHour - bHour
        })

        let foundCurrentPeriod = false
        let foundNextPeriod = false
        let interval: number | undefined
        const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
        const allDays = sortedPlanning.map((planning) => planning.days).flat()

        // Si le jour actuel n'apparaît dans aucune période d'ouverture
        if (!allDays.includes(todayDay.toString()) && sortedPlanning.length > 0) {
          // Trouver le prochain jour d'ouverture
          const nextDayOpening = sortedPlanning.find((planning) =>
            planning.days.includes(((todayDay + 1) % 7).toString())
          )

          // Obtenir le jour en format numérique et convertir en écrit
          const nextOpeningDayNumber = nextDayOpening
            ? parseInt(nextDayOpening.days[0], 10) // Le premier jour dans la liste des jours d'ouverture
            : (todayDay + 1) % 7

          const nextOpeningDayName = daysOfWeek[nextOpeningDayNumber]

          nextOpeningTime = nextDayOpening ? nextDayOpening.open : ''

          // Mettre à jour l'interface utilisateur

          updateUI(
            `Le restaurant est fermé aujourd'hui. Il ouvrira ${nextOpeningDayName} à ${nextOpeningTime}`,
            classClose,
            storeData.name
          )
          return
        } else {
          storeIsOpen.value = true
        }

        for (const planning of sortedPlanning) {
          if (planning.days.includes(todayDay.toString())) {
            const [openHour, openMinute] = planning.open.split(':').map(Number)
            const [closeHour, closeMinute] = planning.close.split(':').map(Number)
            const timeClose = new Date()
            timeClose.setHours(closeHour, closeMinute)
            const fifteenMinutesBeforClose = timeClose.getTime() - 15 * 60 * 1000

            const isAfterOpen =
              todayHours > openHour || (todayHours === openHour && todayMinutes >= openMinute)
            const isBeforeClose =
              todayHours < closeHour || (todayHours === closeHour && todayMinutes < closeMinute)

            if (isAfterOpen && isBeforeClose) {
              // storeIsOpen.value = true
              isOpen.value = true
              foundCurrentPeriod = true
              // si l'heure courante et les minute courante sont plus grande que 15 minute avant la fermeture
              // alors je rentre dans la condition pour mettre mon interval
              const fifteenMinutesBeforClose = timeClose.getTime() - 15 * 60 * 1000
              if (todayTime >= fifteenMinutesBeforClose && isOpen) {
                interval = window.setInterval(
                  () => {
                    // Simuler le changement d'heure de fermeture après un certain temps
                    testTime.value = new Date().getTime() + 6 * 120 * 60 * 1000
                    updateStore(storeData)
                  },
                  5 * 60 * 1000
                )
              }
              break // Le restaurant est ouvert pendant cette période
            }

            // Si l'heure actuelle est avant l'ouverture de cette période, c'est la prochaine période d'ouverture
            if (todayHours < openHour || (todayHours === openHour && todayMinutes < openMinute)) {
              nextOpeningTime = planning.open
              foundNextPeriod = true
              break
            }

            // Si l'heure actuelle est après la fermeture de cette période, vérifier la prochaine période
            if (todayHours >= closeHour) {
              foundCurrentPeriod = false // Le restaurant est fermé pendant cette période
              clearInterval(interval)
            }
          }
        }

        // Vérifier si nous sommes après la dernière période d'ouverture et avant le lendemain
        if (!foundCurrentPeriod && !foundNextPeriod) {
          // Check if we are after the last opening period
          const lastPeriod = sortedPlanning
            .filter((planning) => planning.days.includes(todayDay.toString()))
            .pop()
          if (lastPeriod) {
            const [lastCloseHour, lastCloseMinute] = lastPeriod.close.split(':').map(Number)
            if (
              todayHours >= lastCloseHour &&
              (todayMinutes >= lastCloseMinute || todayHours > lastCloseHour)
            ) {
              closedForTonight = true
            }
          }
          // Find the next day's opening time
          const nextDayOpening = sortedPlanning.find((planning) =>
            planning.days.includes(((todayDay + 1) % 7).toString())
          )
          nextOpeningTime = nextDayOpening ? nextDayOpening.open : ''
        }

        // Mise à jour de l'interface utilisateur en fonction de l'état d'ouverture
        if (isOpen.value) {
          // storeIsOpen.value = true
          updateUI('', classOpen, storeData.name)
        } else if (closedForTonight) {
          // storeIsOpen.value = false
          updateUI(textClosedForTonight, classClose, storeData.name)
        } else if (nextOpeningTime) {
          // storeIsOpen.value = false
          updateUI(`${textForOpen} ${nextOpeningTime}`, classDefault, storeData.name)
        } else if (sortedPlanning.length === 0) {
          storeIsOpen.value = false
          updateUI(textNotsortedPlanning, classClose)
        } else {
          // storeIsOpen.value = false
          updateUI(textTodayClose, classClose, storeData.name)
        }
      } else {
        storeIsOpen.value = false
        updateUI(textOpenClose, classClose, storeData.name)
      }
    }
  }

  const updateUI = (message: string, className: string, storeName?: string) => {
    textTitle.value = message
    title.value = storeName ? (message ? `${storeName}: ${message}` : storeName) : message
    classTitle.value = className
  }

  // Simuler le changement d'heure de fermeture après 60 secondes
  const simulateCloseTimeChange = () => {
    testHour.value = 14
    testMinute.value = 30
    stopInterval.value = true
  }

  return {
    title,
    classTitle,
    textTitle,
    updateStore,
    storeData,
    testDay,
    testHour,
    testMinute
  }
}
