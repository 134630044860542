import { ref } from 'vue'

export const messageSendEmpty = {
  message: '',
  class: '',
  errors: []
}
// export interface MessageSendInterface {
//   message: string
//   class: string
// }

export const messageSend = ref(messageSendEmpty)
