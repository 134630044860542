<script setup lang="ts">
import { URL_IMAGE } from '@/config/constants'
import products from '@/storage/products'
import stores, { type CategoryType } from '@/storage/stores'
import { getCategoryName } from '@/utils/category/category'
import { getProductToCategory } from '@/utils/getProductToCategory'
import { onMounted, reactive, ref, watch } from 'vue'

const catalogueBase = ref(stores.state.storeData.category)
const showMoreCategories = ref(false)
const displayedCategories = ref()
const additionalCategories = ref()
const hasMoreCategories = ref(false)

watch(
  () => stores.state.storeData.category,
  (newValue) => {
    catalogueBase.value = newValue
    if (catalogueBase.value) {
      if (catalogueBase.value.length > 8) {
        hasMoreCategories.value = true
      }
      displayedCategories.value = catalogueBase.value.slice(0, 8)
      additionalCategories.value = catalogueBase.value.slice(8)
    }
  }
)

onMounted(() => {
  if (catalogueBase.value) {
    displayedCategories.value = catalogueBase.value.slice(0, 8)
    additionalCategories.value = catalogueBase.value.slice(8)
    if (catalogueBase.value.length > 8) {
      hasMoreCategories.value = !hasMoreCategories.value
    }
  }
})
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="nav-bar-category-border">
      <div class="nav-bar-category">
        <router-link
          class="link"
          v-for="(category, index) in displayedCategories"
          :key="index"
          :to="{
            name: 'catalog-product',
            params: {
              category_id: category.category_id.toString(),
              category_name: getCategoryName(category).toString()
            }
          }"
        >
          {{ getCategoryName(category) }}
        </router-link>
        <div class="relative">
          <button
            v-if="hasMoreCategories"
            @click="showMoreCategories = !showMoreCategories"
            class="show-more-button"
          >
            <svg class="svg-threepoint" id="_x32_" viewBox="0 0 512 512" xml:space="preserve">
              <g>
                <circle class="st0" cx="55.091" cy="256" r="55.091" />
                <circle class="st0" cx="256" cy="256" r="55.091" />
                <circle class="st0" cx="456.909" cy="256" r="55.091" />
              </g>
            </svg>
          </button>

          <!-- Div contenant les autres catégories après le clic sur le bouton -->
          <div v-if="showMoreCategories" class="additional-categories">
            <router-link
              class="link"
              v-for="(category, index) in additionalCategories"
              :key="index"
              :to="{
                name: 'catalog-product',
                params: {
                  category_id: category.category_id.toString(),
                  category_name: getCategoryName(category).toString()
                }
              }"
            >
              {{ getCategoryName(category) }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="catalog">
      <div class="category-layout" v-for="(category, index) in catalogueBase" :key="index">
        <router-link
          class="category"
          :to="{
            name: 'catalog-product',
            params: {
              category_id: category.category_id.toString(),
              category_name: getCategoryName(category).toString()
            }
          }"
        >
          <img class="img" :src="`${URL_IMAGE}${category.image}`" alt="" />
          <p class="text">{{ getCategoryName(category) }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.show-more-button {
  @apply bg-transparent;
}
.svg-threepoint {
  @apply w-5 fill-white-eronor;
}
.link:hover {
  @apply text-lime-eronor;
}
.nav-bar-category-border {
  @apply hidden;
}
.nav-bar-category {
  @apply hidden;
}
.nav-bar-category button .svg-threepoint:hover {
  @apply fill-lime-eronor;
}

/* catalog category*/

.text {
  @apply text-center uppercase font-semibold text-sm;
}
.category-layout {
  @apply flex flex-col items-center min-h-36  justify-center bg-slate-eronor hover:bg-slate-hover rounded-xl  relative;
}

.category {
  @apply flex flex-col items-center justify-center h-full;
}
.category p {
  @apply text-center overflow-hidden text-ellipsis  bottom-2 p-1;
}

.img {
  @apply object-contain w-full  rounded-xl max-w-full;
}

/* .category-layout:hover {
  @apply bg-slate-hover;
}
.category:active {
  @apply bg-slate-hover;
} */
.category:hover p {
  @apply text-lime-eronor /* Couleur du texte au survol, ajuste selon tes besoins */;
}

.catalog {
  @apply grid grid-cols-3 gap-2 my-3;
}

@media screen and (min-width: 800px) {
  .category-layout {
    @apply pb-1;
  }
  .category {
    @apply h-full;
  }
  .catalog {
    @apply grid grid-cols-4;
  }
}

@media screen and (min-width: 1030px) {
  .category-container {
    @apply relative inline-block;
  }
  .additional-categories {
    @apply absolute top-3 -right-7 flex bg-slate-hover z-30 shadow-lg p-4 m-4 rounded-lg gap-2;
  }
  .nav-bar-category-border {
    @apply flex justify-center border-t border-t-slate-hover mt-3 w-full mx-2;
  }
  .nav-bar-category {
    @apply uppercase w-[60rem] font-semibold flex flex-row justify-evenly px-1 p-2 mx-1;
  }

  .catalog {
    @apply grid grid-cols-5 gap-3 w-[60rem];
  }
}

@media screen and (min-width: 1280px) {
  .catalog {
    @apply w-[65rem];
  }
}
@media screen and (min-width: 1500px) {
  .category-layout {
    @apply max-w-xs;
  }
  .nav-bar-category-border {
    @apply w-[75rem];
  }
}
@media screen and (min-width: 1700px) {
  /* .additional-categories {
    @apply right-40;
  } */
}

@media screen and (min-width: 2000px) {
  /* .additional-categories {
    @apply right-72;
  } */
  .nav-bar-category {
    @apply w-[70rem];
  }

  .catalog {
    @apply grid-cols-5 gap-3 w-[60rem];
  }
}

@media screen and (min-width: 2200px) {
  /* .additional-categories {
    @apply right-96;
  } */
}
@media screen and (min-width: 2400px) {
  /* .additional-categories {
    @apply right-[30rem];
  } */
}
</style>
