<script setup lang="ts">
import IconWarning from '@/components/icons/iconEronor/IconWarning.vue'
import customer from '@/storage/customer'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { updateCancelPayment } from '@/utils/order/updateCancelPayment'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { order_id } = route.params

const customerGet = computed(() => customer.state.customerData)

updateCancelPayment(order_id)
</script>

<template>
  <section class="section-cancel-view">
    <div class="div-cancel-payment">
      <div class="w-full flex flex-col items-center gap-3">
        <p class="p-cancel-payment">Votre paiement n'a pas pu être finalisé !</p>

        <p class="p-cancel-payment">
          numéro de commande: <span class="text-amber-eronor">{{ order_id }}</span>
        </p>
      </div>
      <IconWarning />
      <p class="text-center">
        Pour toute assistance, nous vous invitons à appeler notre magasin ou à vous y rendre pour
        régler votre commande.
      </p>

      <p>Les informations du magasin se situe en bas de la page !</p>
    </div>
  </section>
</template>

<style scoped>
.section-cancel-view {
  @apply flex flex-col items-center px-2 pt-12 gap-2;
}
.div-cancel-payment {
  @apply flex flex-col items-center mt-3 w-full gap-8;
}
.p-cancel-payment {
  @apply font-bold text-2xl;
}
@media screen and (min-width: 700px) {
  .section-cancel-view {
    @apply max-w-[40rem];
  }
}
</style>
