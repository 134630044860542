<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import IconEye from '@/components/icons/iconEronor/IconEye.vue'
import IconEyeOff from '@/components/icons/iconEronor/IconEyeOff.vue'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { useRoute, useRouter } from 'vue-router'
import { URL_BACK } from '@/config/constants'
import ButtonModify from '@/components/button/ButtonModify.vue'
import { messageModify, typeMessageModify } from '@/event/account/messageModify'
import { idTimeout } from '@/event/account/idTimeout'
import { showAlertModify, toggleAlertModify } from '@/event/account/showAlertModify'
import ButtonClose from '@/components/button/ButtonClose.vue'
import stores from '@/storage/stores'
import { configEmail } from '@/storage/configEmail'
import IconSuccessRoundedPassword from '../icons/iconEronor/IconSuccessRoundedPassword.vue'

const focusedModify = ref()
const passwordModify = ref('')
const newPasswordModify = ref('')
const showPassword = ref(false)
const csrfToken = ref('')
const route = useRoute()
const router = useRouter()
const verifyModify = ref(false)
const typeMessageverify = {
  message: '',
  class: '',
  errors: []
}
// const configEmail = ref()
const messageVerify = ref(typeMessageverify)

const isMinLengthValid = computed(() => passwordModify.value.length >= 10)
const hasUpperCase = computed(() => /[A-Z]/.test(passwordModify.value))
const hasLowerCase = computed(() => /[a-z]/.test(passwordModify.value))
const hasNumber = computed(() => /[0-9]/.test(passwordModify.value))

const identicPassword = computed(
  () =>
    passwordModify.value.length > 0 &&
    newPasswordModify.value.length > 0 &&
    passwordModify.value === newPasswordModify.value
)

const hasSpecialChar = computed(() => {
  const forbiddenSpecialCharRegex = /[<>$"'&]/
  const allowedSpecialCharRegex = /[!@#%^*()[\]{};:,.\/?\\|`~+=_-]/
  return (
    passwordModify.value.length > 0 &&
    allowedSpecialCharRegex.test(passwordModify.value) &&
    !forbiddenSpecialCharRegex.test(passwordModify.value)
  )
})
async function verifyModifyIsPossible() {
  await fetchCsrf(csrfToken)

  const data = {
    // email: route.params.email,
    token: route.params.token
    // store_id: route.params.store_id
  }

  const url = `https://${URL_BACK}/api/customer/verify`

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })

    const response = await res.json()
    messageVerify.value = response

    if (messageVerify.value.class === 'success') {
      verifyModify.value = true
    }

    csrfToken.value = ''
  } catch (e) {}
}

async function modifyPassword() {
  await fetchCsrf(csrfToken)

  const data = {
    // email: route.params.email,
    token: route.params.token,
    store_id: stores.state.storeData.store_id,
    store_name: stores.state.storeData.name,
    store_email: configEmail.value,
    password: passwordModify.value,
    newpassword: newPasswordModify.value
  }
  const url = `https://${URL_BACK}/api/customer/modify-password`

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })

    const response = await res.json()
    messageModify.value = response
    showAlertModify.value = true

    if (messageModify.value.class === 'success') {
      idTimeout.value = setTimeout(() => {
        router.push('/login')
        showAlertModify.value = false
        messageModify.value = typeMessageModify
      }, 6000)
    }

    csrfToken.value = ''
  } catch (e) {}
}

onMounted(() => {
  verifyModifyIsPossible()

  const circles = document.querySelectorAll('.st0')

  let currentIndex = 0

  function animateCircle(index: number) {
    // Réinitialiser tous les cercles
    circles.forEach((circle) => {
      const htmlCircle = circle as HTMLElement
      htmlCircle.classList.remove('animation')
      // htmlCircle.style.animation = 'none' // Réinitialise l'animation
    })
    console.log(circles)
    // Démarrer l'animation pour le cercle courant
    const currentCircle = circles[index] as HTMLElement // Cast explicite
    currentCircle.classList.add('animation')

    // Passer au cercle suivant après 1 seconde
    setTimeout(() => {
      const nextIndex = (index + 1) % circles.length // Boucle infinie
      animateCircle(nextIndex)
    }, 1000) // Temps de l'animation (1 seconde)
  }

  // Lancer l'animation avec le premier cercle
  animateCircle(currentIndex)
})
</script>

<template>
  <section class="section-main-modify-form">
    <section v-if="verifyModify" class="section-modify-content">
      <section class="section-indicate-register">
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="identicPassword" />
          <p>Les 2 mot de passe sont identique</p>
        </div>
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="hasUpperCase" />
          <p>1 lettre en majuscule</p>
        </div>
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="hasLowerCase" />
          <p>1 lettre en minuscule</p>
        </div>
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="hasNumber" />
          <p>1 chiffre</p>
        </div>
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="hasSpecialChar" />
          <p>1 caractère spéciale</p>
        </div>
        <div class="text-indicate-register">
          <IconSuccessRoundedPassword :validate="isMinLengthValid" />
          <p class="text-pretty-register">Le mort de passe doit contenir minimum 10 caractères</p>
        </div>
      </section>

      <section class="section-modify-form">
        <div
          v-if="messageModify.errors && messageModify.errors.length > 0"
          :class="messageModify.class"
          class="hidden"
        >
          <div class="div-button-alert">
            <ButtonClose class="button" :function="toggleAlertModify" />
          </div>
          <p v-for="(error, index) in messageModify.errors" :key="index" class="bullet">
            {{ error }}
          </p>
        </div>
        <div v-else :class="messageModify.class" class="hidden">
          <div class="div-button-alert">
            <ButtonClose :function="toggleAlertModify" />
          </div>
          <p>{{ messageModify.message }}</p>
        </div>

        <section>
          <h3>Modifier votre mot de passe</h3>
          <form class="form-modify" @submit.prevent="modifyPassword">
            <div class="form-group">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="passwordModify"
                id="passwordModify"
                required
                @focus="focusedModify = 'passwordModify'"
                @blur="focusedModify = ''"
                :class="{
                  'has-value': passwordModify !== '' || focusedModify === 'passwordModify'
                }"
              />
              <label
                for="passwordModify"
                :class="{ active: passwordModify !== '' || focusedModify === 'passwordModify' }"
              >
                <span>*</span> Mot de passe
              </label>
              <span class="eye-icon" @click="showPassword = !showPassword"
                ><template v-if="showPassword"><IconEye /></template
                ><template v-else><IconEyeOff /></template
              ></span>
            </div>

            <div class="form-group">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="newPasswordModify"
                id="newPasswordModify"
                required
                @focus="focusedModify = 'newPasswordModify'"
                @blur="focusedModify = ''"
                :class="{
                  'has-value': newPasswordModify !== '' || focusedModify === 'newPasswordModify'
                }"
              />
              <label
                for="newPasswordModify"
                :class="{
                  active: newPasswordModify !== '' || focusedModify === 'newPasswordModify'
                }"
              >
                <span>*</span> Confirmer le mot de passe
              </label>
              <span class="eye-icon" @click="showPassword = !showPassword"
                ><template v-if="showPassword"><IconEye /></template
                ><template v-else><IconEyeOff /></template
              ></span>
            </div>

            <ButtonModify />
          </form>
        </section>
      </section>
    </section>
    <section v-else class="section-verify">
      <h3>Verification de modification</h3>
      <svg
        v-if="messageVerify.class == ''"
        class="w-20 fill-white"
        version="1.1"
        id="_x32_"
        viewBox="0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <circle class="st0" cx="55.091" cy="256" r="40" />
          <circle class="st0" cx="256" cy="256" r="40" />
          <circle class="st0" cx="456.909" cy="256" r="40" />
        </g>
      </svg>

      <div class="text-center pt-3" v-if="messageVerify.class == 'error'">
        <div v-if="messageVerify.errors && messageVerify.errors.length > 0">
          <p v-for="(message, index) of messageVerify.errors" :key="index">
            {{ message }}
          </p>
        </div>

        <p v-else :class="messageVerify.class">
          {{ messageVerify.message }}
        </p>
      </div>
    </section>
  </section>
</template>

<style scoped>
.section-verify {
  @apply max-w-72 flex flex-col items-center bg-slate-eronor p-4 mt-8 rounded-md;
}
h3 {
  @apply font-bold w-full text-center border-b pb-2;
}
.div-button-alert {
  @apply flex justify-end;
}
.section-indicate-register {
  @apply flex flex-col gap-1  max-w-72 pt-2;
}
.text-indicate-register {
  @apply flex gap-1 items-start;
}
.bullet {
  @apply relative pl-4;
}
.bullet::before {
  content: '•';
  position: absolute;
  left: 0;
}
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-2 pb-3 rounded-md  z-30 top-10;
}
.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-3 rounded-md  z-30 top-10;
}
.section-main-modify-form {
  @apply mt-3;
}
.section-modify-content {
  @apply flex flex-col  gap-3;
}
.section-modify-form {
  @apply relative flex flex-col items-center bg-slate-eronor rounded-md gap-6  p-5;
}
.form-modify {
  @apply flex flex-col items-center gap-7 pt-7;
}
.eye-icon {
  @apply absolute top-2/4 right-2;

  transform: translateY(-50%);
  cursor: pointer;
}
.form-group {
  @apply relative flex flex-col items-center;
}
input {
  @apply rounded-2xl border-2 outline-none focus:border-red-eronor text-slate-eronor text-center min-w-64;
}
label {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor;
}
label.active {
  transition: all 1s ease;
}
.svg-eye,
.svg-eye-off {
  @apply w-5 stroke-slate-eronor;
  /* width: 20px; */
  height: 20px;
  /* stroke: #666; */
}
span {
  @apply text-red-eronor;
}

.animation {
  animation: pulse 1s ease-in-out infinite;
  /* Initialement tous les cercles sont en pause */
}

/* Définition de l'animation pulsée */
@keyframes pulse {
  0% {
    r: 40; /* Taille initiale */
  }
  50% {
    r: 55; /* Taille maximale */
  }
  100% {
    r: 40; /* Retour à la taille initiale */
  }
}

@media screen and (min-width: 900px) {
  .section-modify-content {
    @apply flex-row-reverse items-center gap-5 mt-6;
  }
}
</style>
