import { isTokenExpired } from './isTokenExpired'
import { fetchRefreshToken } from './fetchRefreshToken'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { isRefreshTokenExpired } from './isRefreshTokenExpired'
import { isNotAuthenticated } from '@/event/account/auth/isNotAuthenticated'
import { fetchUserWithToken } from './fetchUserWithToken'
import { formatStoreName } from '../store/formatStoreName'
import stores from '@/storage/stores'
import customer from '@/storage/customer'

export async function checkAndRefreshToken() {
  const store = stores.getStoresData().name
  let token
  let refreshToken

  if (store) {
    const storeName = formatStoreName(store)
    token = localStorage.getItem(`${storeName}_jwt`)
    refreshToken = localStorage.getItem(`${storeName}_refresh_token`)
  } else {
    token = localStorage.getItem('jwt')
    refreshToken = localStorage.getItem('refresh_token')
  }

  // Vérifie si le token est expiré
  if (token && isTokenExpired(token)) {
    if (refreshToken && !isRefreshTokenExpired(refreshToken) && isNotAuthenticated.value === 0) {
      fetchRefreshToken(refreshToken)
    }
  }

  if (token && !isTokenExpired(token)) {
    let res
    if (customer.state.customerData.customer_id == undefined) {
      res = await fetchUserWithToken(token)
    }

    if (res) {
      isAuthenticated.value = true
    }
  }
}
