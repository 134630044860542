import { ref } from 'vue'

export const isImageOpen = ref<boolean>(false)
export const selectedImage = ref<string>('')

export const openImage = (image: string) => {
  selectedImage.value = image
  isImageOpen.value = true
}

export const closeImage = () => {
  isImageOpen.value = false
}
