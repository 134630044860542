<script setup lang="ts">
import stores, { type StoresDataType } from '@/storage/stores'
import { computed, ref, watch } from 'vue'
import ButtonFooter from '@/components/button/ButtonFooter.vue'
import { configAddress } from '@/storage/configAddress'
import { configTelephone } from '@/storage/configTelephone'
import { configEmail } from '@/storage/configEmail'
import { mapUrl, openMap } from '@/storage/mapUrl'

const props = defineProps({
  title: String,
  nameStore: String
})

const isVisible = ref(false)
// const configEmail = ref()
// const configAddress = ref()
// const configTelephone = ref()

// if (stores.state.storeData.setting) {
//   const data = stores.state.storeData.setting
//   configEmail.value = data.find((setting: { key: string }) => setting.key === 'config_email')
//   configAddress.value = data.find((setting: { key: string }) => setting.key === 'config_address')
//   configTelephone.value = data.find(
//     (setting: { key: string }) => setting.key === 'config_telephone'
//   )
// }

// watch(
//   () => stores.state.storeData,
//   () => {
//     const data: any = stores.state.storeData.setting
//     configEmail.value = data.find((setting: { key: string }) => setting.key === 'config_email')
//     configAddress.value = data.find((setting: { key: string }) => setting.key === 'config_address')
//     configTelephone.value = data.find(
//       (setting: { key: string }) => setting.key === 'config_telephone'
//     ).value
//   },
//   { deep: true }
// )

const toggleDisplay = () => {
  isVisible.value = !isVisible.value
}

// const mapUrl = computed(() => {
//   const encodedAddress = encodeURIComponent(configAddress.value)
//   return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
// })

// const openMap = () => {
//   const encodedAddress = encodeURIComponent(configAddress.value.value)
//   const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

//   if (isMobile) {
//     window.location.href = `geo:0,0?q=${encodedAddress}`
//   } else {
//     window.open(mapUrl.value, '_blank')
//   }
// }
</script>

<template>
  <div v-if="props.nameStore" class="contact">
    <h5 class="content-h5 text-uppercase">
      <p class="title">{{ title }}</p>

      <ButtonFooter :isVisible="isVisible" @click="toggleDisplay" />
    </h5>
    <div :class="['content-form', { show: isVisible }]">
      <div class="row-content">
        <svg viewBox="0 0 800 744" class="svg" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M672.627 425.905C676.257 428.748 678.378 433.103 678.378 437.715V728.659C678.378 736.943 671.663 743.659 663.378 743.659H496.752C488.468 743.659 481.752 736.943 481.752 728.659V562.911C481.752 554.626 475.036 547.911 466.752 547.911H333.254C324.969 547.911 318.254 554.626 318.254 562.911V728.659C318.254 736.943 311.538 743.659 303.254 743.659H136.627C128.343 743.659 121.627 736.943 121.627 728.659V437.715C121.627 433.103 123.748 428.748 127.379 425.905L390.755 219.652C396.187 215.398 403.82 215.398 409.252 219.652L672.627 425.905Z"
            fill=""
          />
          <path
            d="M7.97584 362.725C3.48676 356.233 4.79676 347.373 10.9724 342.458L390.659 40.2628C396.126 35.9113 403.874 35.9113 409.341 40.2628L789.028 342.458C795.203 347.373 796.513 356.233 792.024 362.725L762.578 405.31C757.641 412.45 747.692 413.921 740.899 408.515L409.341 144.625C403.874 140.274 396.126 140.274 390.659 144.625L59.1005 408.515C52.3082 413.921 42.359 412.45 37.4217 405.31L7.97584 362.725Z"
            fill=""
          />
          <path
            d="M672.271 0.34082C680.555 0.34082 687.271 7.05655 687.271 15.3408V172.968C687.271 185.53 672.752 192.527 662.925 184.701L594.05 129.845C590.477 126.999 588.396 122.68 588.396 118.111V15.3408C588.396 7.05655 595.111 0.34082 603.396 0.34082H672.271Z"
            fill=""
          />
        </svg>
        <p>{{ props.nameStore }}</p>
        <a :href="mapUrl" @click.prevent="openMap" target="_blank" v-if="configAddress">{{
          configAddress
        }}</a>
        <div>
          <div></div>
        </div>
      </div>

      <div class="row-content" v-if="configTelephone">
        <a :href="`tel:${configTelephone}`">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg" viewBox="0 0 24 24" fill="none">
            <path
              d="M2.00589 4.54166C1.905 3.11236 3.11531 2 4.54522 2H7.60606C8.34006 2 9.00207 2.44226 9.28438 3.1212L10.5643 6.19946C10.8761 6.94932 10.6548 7.81544 10.0218 8.32292L9.22394 8.96254C8.86788 9.24798 8.74683 9.74018 8.95794 10.1448C10.0429 12.2241 11.6464 13.9888 13.5964 15.2667C14.008 15.5364 14.5517 15.4291 14.8588 15.0445L15.6902 14.003C16.1966 13.3687 17.0609 13.147 17.8092 13.4594L20.8811 14.742C21.5587 15.0249 22 15.6883 22 16.4238V19.5C22 20.9329 20.8489 22.0955 19.4226 21.9941C10.3021 21.3452 2.65247 13.7017 2.00589 4.54166Z"
            />
          </svg>
          {{ configTelephone }}</a
        >
      </div>

      <div class="row-content" v-if="configEmail">
        <a :href="`mailto:${configEmail}`">
          <svg
            viewBox="0 0 800 600"
            class="svg"
            fill="hoverColor ? 'currentColor' : baseColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_338_3509)">
              <path
                d="M542.7 347.65C537.1 343.625 535.825 335.812 539.85 330.2C543.862 324.6 551.688 323.325 557.3 327.35L800 502.1V93.4375L407.462 385.038C405.237 386.688 402.625 387.5 400 387.5C397.375 387.5 394.763 386.688 392.538 385.038L0 93.4375V502.1L242.7 327.362C248.312 323.35 256.113 324.612 260.15 330.212C264.175 335.812 262.913 343.625 257.3 347.662L0 532.9V550C0 577.638 22.3625 600 50 600H750C777.638 600 800 577.638 800 550V532.9L542.7 347.65Z"
              />
              <path
                d="M799 61.7846L400 358.179L1 61.7721V50C1 22.9148 22.9148 1 50 1H750C777.085 1 799 22.9148 799 50V61.7846Z"
                stroke-width="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_338_3509">
                <rect width="800" height="600" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ configEmail }}</a
        >
      </div>

      <section class="flex gap-2">
        <div class="div-logo">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-insta" viewBox="0 0 24 24" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
            />
            <path
              d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
            />
          </svg>
        </div>

        <div class="div-logo">
          <svg class="svg-fb" viewBox="0 0 603 600" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M301.205 0C135.542 0 0 135.241 0 301.808C0 452.41 110.241 577.41 254.217 600V389.157H177.711V301.808H254.217V235.241C254.217 159.638 299.096 118.072 368.072 118.072C400.903 118.072 435.241 123.795 435.241 123.795V198.193H397.289C359.939 198.193 348.192 221.385 348.192 245.181V301.808H431.927L418.373 389.157H348.192V600C419.168 588.789 483.801 552.575 530.418 497.892C577.036 443.211 602.572 373.663 602.409 301.808C602.409 135.241 466.867 0 301.205 0Z"
            />
          </svg>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
/* .contact {
  @apply w-4/5;
} */

.title {
  @apply w-full p-2;
  /* width: 98%; */
}
a {
  @apply underline flex gap-2 fill-current;
}
a:hover .svg {
  @apply fill-limedark-eronor shadow-inner;
}
a:hover {
  @apply text-limedark-eronor;
}

.svg {
  @apply fill-lime-eronor w-5;
}

.row-content {
  @apply flex gap-2 flex-wrap;
}
.content-h5 {
  @apply relative flex justify-between items-center;
}

.text-uppercase {
  @apply uppercase font-semibold;
}

.content-form {
  @apply p-2;
  display: none;
  transition: 1s ease-in-out;
}

.content-form.show {
  @apply flex flex-col gap-3;
}

.div-logo {
  @apply rounded-md w-fit p-1 bg-slate-hover hover:bg-limedark-eronor;
}

.svg-insta {
  @apply w-6 fill-white;
}
.svg-fb {
  @apply w-6 fill-white;
}

@media screen and (min-width: 800px) {
  .content-form {
    @apply flex flex-col p-2 flex-wrap;
    /* display: none; */
    transition: 1s ease-in-out;
  }

  /* .custom-button {
    @apply hidden;
  } */
}
</style>
