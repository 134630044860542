import { getCurrentStoreId } from '@/storage/stores'

export function isRefreshTokenExpired(refreshToken: string) {
  if (!refreshToken) return true

  // console.log('Refresh Token:', refreshToken)

  // Décodage du Refresh Token
  const parts = refreshToken.split('.')
  // console.log('Parts du token:', parts)
  if (parts.length !== 3) {
    // console.log('Le token est mal formé.')
    return true
  }

  const payloadBase64 = parts[1]
  const payload = JSON.parse(atob(payloadBase64))
  // console.log('Payload décodé:', payload)

  // Temps actuel et store ID
  const currentTime = Math.floor(Date.now() / 1000)
  const currentStoreId = getCurrentStoreId()
  // console.log('Heure actuelle:', currentTime)
  // console.log('Payload expiration:', payload.exp)
  // console.log('Current Store ID:', Number(currentStoreId))
  // console.log('Store ID du payload:', Number(payload.storeId))

  // Vérifications
  if (payload.exp < currentTime) {
    // console.log('Le Refresh Token a expiré.')
    return true
  }

  if (Number(payload.storeId) !== Number(currentStoreId)) {
    // console.log('Le Store ID ne correspond pas.')
    return true
  }

  return false
}
