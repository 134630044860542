import { ref, watch } from 'vue'
// import { route } from '../route/route'

// export const categoryIdBus = ref<number>(
//   route.params && route.params.category_id ? Number(route.params.category_id) : 0
// )
export const categoryIdBus = ref<number>(0)

// watch(
//   () => route.params?.category_id, // Surveiller les changements de category_id
//   (newCategoryId) => {
//     // Mettre à jour uniquement si newCategoryId est défini
//     if (newCategoryId !== undefined) {
//       categoryIdBus.value = Number(newCategoryId)
//     }
//   }
// )
// watch(
//   () => route.name,
//   (routeName) => {
//     if (categoryIdBus.value && routeName) {
//       categoryIdBus.value = 0
//     }
//   },
//   { deep: true }
// )
