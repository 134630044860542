<script setup lang="ts"></script>

<template>
  <router-link to="/dashboard/edit" class="button-edit"
    ><svg viewBox="0 0 511.523 511.523" id="acpoder" class="svg-edit">
      <path
        d="M509.091,296.489l-58.182-93.091c-1.164-3.491-5.818-5.818-9.309-5.818H69.236c-3.491,0-8.145,2.327-9.309,5.818
      L1.745,296.489c-2.327,3.491-2.327,8.145,0,11.636c1.164,3.491,4.655,5.818,9.309,5.818H57.6v151.273
      c0,19.782,15.127,34.909,34.909,34.909h186.182h139.636c19.782,0,34.909-15.127,34.909-34.909V348.853
      c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v116.364c0,6.982-4.655,11.636-11.636,11.636h-128V279.034
      c0-6.982-4.655-11.636-11.636-11.636c-6.982,0-11.636,4.655-11.636,11.636v197.818H92.509c-6.982,0-11.636-4.655-11.636-11.636
      V302.307c0-6.982-4.655-11.636-11.636-11.636H32l44.218-69.818h181.527l-40.727,64c-2.327,3.491-5.818,5.818-9.309,5.818h-91.927
      c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h91.927c11.636,0,23.273-5.818,29.091-16.291l41.891-66.327
      l41.891,66.327c6.982,10.473,17.455,16.291,29.091,16.291h150.109c4.655,0,8.145-2.327,10.473-5.818
      C512.582,304.634,511.418,299.98,509.091,296.489z M349.673,290.671c-3.491,0-8.146-2.327-9.309-5.818l-40.727-64h134.982
      l44.218,69.818H349.673z"
      ></path>
      <path
        d="M185.6,395.398h-69.818c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636H185.6
      c6.982,0,11.636-4.655,11.636-11.636C197.236,400.053,192.582,395.398,185.6,395.398z"
      ></path>
      <path
        d="M150.691,430.307h-34.909c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h34.909
      c6.982,0,11.636-4.655,11.636-11.636S157.673,430.307,150.691,430.307z"
      ></path>
      <path
        d="M377.6,100.998c-2.327-5.818-8.145-9.309-15.127-8.146c-5.818,2.327-9.309,9.309-6.982,15.127
      c3.491,10.473,4.655,20.945,4.655,31.418c0,6.982-1.164,13.964-2.327,20.945c-1.164,5.818,2.327,12.8,9.309,13.964
      c1.164,0,1.164,0,2.327,0c5.818,0,10.473-3.491,11.636-9.309c1.164-8.145,2.327-17.455,2.327-25.6
      C383.418,126.598,381.091,113.798,377.6,100.998z"
      ></path>
      <path
        d="M310.109,24.198c-16.291-8.145-34.909-12.8-54.691-12.8c-70.982,0-128,57.018-128,128c0,8.145,1.164,17.455,2.327,25.6
      c1.164,5.818,5.818,9.309,11.636,9.309c1.164,0,1.164,0,2.327,0c5.818-1.164,10.473-6.982,9.309-13.964
      c-1.164-6.982-2.327-13.964-2.327-20.945c0-58.182,46.545-104.727,104.727-104.727c16.291,0,31.418,3.491,45.382,10.473
      c5.818,2.327,12.8,0,15.127-5.818S315.927,26.525,310.109,24.198z"
      ></path>
      <path
        d="M356.655,49.798c-4.655-4.655-11.636-4.655-16.291,0l-84.945,84.945l-26.764-26.764c-4.655-4.655-11.636-4.655-16.291,0
      s-4.655,11.636,0,16.291l34.909,34.909c2.327,2.327,5.818,3.491,8.145,3.491c2.327,0,5.818-1.164,8.146-3.491l93.091-93.091
      C361.309,61.434,361.309,54.452,356.655,49.798z"
      ></path>
    </svg>
    <div><p class="text-edit">Modifier mes informations</p></div>
  </router-link>
</template>

<style scoped>
.button-edit {
  @apply flex items-center w-full p-2 h-16 border gap-3;
}
.svg-edit {
  @apply pr-2 w-10 fill-lime-eronor border-r;
}
.text-edit {
  @apply font-semibold;
}

.button-edit:hover,
.button-edit:hover .svg-edit,
.button-edit:hover .text-edit {
  @apply border-lime-eronor text-lime-eronor;
}

@media (min-width: 900px) {
  .button-edit {
    @apply h-20;
  }
  .svg-edit {
    @apply w-12;
  }
}
</style>
