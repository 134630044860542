import { URL_BACK } from '@/config/constants'
import { ref } from 'vue'
import { decodeJWT } from '../jwt/decodeJwt'
import stores from '@/storage/stores'
import { fetchCsrf } from '../csrf/fetchCsrf'
import customer from '@/storage/customer'
import address from '@/storage/address'
import orders from '@/storage/orders'
import { formatStoreName } from '../store/formatStoreName'

const userId = ref<string | null>(null)
const csrfToken = ref()

export async function fetchUser() {
  await fetchCsrf(csrfToken)

  const store = stores.getStoresData().name
  let token

  if (store) {
    const storeName = formatStoreName(store)
    token = localStorage.getItem(`${storeName}_jwt`)
    if (token) {
      const decoded = await decodeJWT(token)
      userId.value = await decoded.uid // uid est l'ID de l'utilisateur
      console.log(userId.value)
    }
  }

  const data = {
    customer_id: await userId.value,
    store_id: stores.state.storeData.store_id
  }
  const url = `https://${URL_BACK}/api/customer/find`
  const urlDelete = `https://${URL_BACK}/api/order/delete-all`

  // await fetch(urlDelete, {
  //   method: 'POST',
  //   credentials: 'include',
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //     'X-CSRF-Token': csrfToken.value
  //   },
  //   body: JSON.stringify(data)
  // })

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })

    const response = await res.json()
    // console.log(response.user)
    customer.setCustomerData(response.user)
    address.setAddressData(response.user.addresses)
    orders.setOrderData(response.user.orders)
  } catch (e) {}
}
