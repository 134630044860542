<script setup lang="ts">
import { showCartBus } from '@/event/cartBus'

const toggleCartDetails = () => {
  if (showCartBus.value == true) showCartBus.value = !showCartBus.value
}
</script>

<template>
  <router-link class="btn-link-basket" to="/basket" @click="toggleCartDetails"
    ><p>Accéder au panier</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
      viewBox="0 -4.5 31 31"
      version="1.1"
      class="svg-list"
    >
      <g id="Page-1" stroke="" stroke-width="1" fill="" fill-rule="evenodd" sketch:type="MSPage">
        <g
          id="Icon-Set-Filled"
          sketch:type="MSLayerGroup"
          transform="translate(-206.000000, -626.000000)"
          fill=""
        >
          <path
            d="M235,643 L216,643 C214.896,643 214,643.896 214,645 C214,646.104 214.896,647 216,647 L235,647 C236.104,647 237,646.104 237,645 C237,643.896 236.104,643 235,643 L235,643 Z M235,635 L216,635 C214.896,635 214,635.896 214,637 C214,638.104 214.896,639 216,639 L235,639 C236.104,639 237,638.104 237,637 C237,635.896 236.104,635 235,635 L235,635 Z M216,631 L235,631 C236.104,631 237,630.104 237,629 C237,627.896 236.104,627 235,627 L216,627 C214.896,627 214,627.896 214,629 C214,630.104 214.896,631 216,631 L216,631 Z M209,642 C207.343,642 206,643.343 206,645 C206,646.657 207.343,648 209,648 C210.657,648 212,646.657 212,645 C212,643.343 210.657,642 209,642 L209,642 Z M209,634 C207.343,634 206,635.343 206,637 C206,638.657 207.343,640 209,640 C210.657,640 212,638.657 212,637 C212,635.343 210.657,634 209,634 L209,634 Z M209,626 C207.343,626 206,627.343 206,629 C206,630.657 207.343,632 209,632 C210.657,632 212,630.657 212,629 C212,627.343 210.657,626 209,626 L209,626 Z"
            id="list"
            sketch:type="MSShapeGroup"
          ></path>
        </g>
      </g>
    </svg>
  </router-link>
</template>

<style scoped>
.btn-link-basket {
  @apply flex justify-center items-center bg-lime-eronor hover:bg-limedark-eronor min-w-40 px-2 py-2 rounded-lg gap-1 hover:shadow-sm hover:shadow-white;
}
.btn-link-basket p {
  @apply text-sm;
}
.btn-link-basket:hover {
  @apply text-black; /* Changez la couleur du texte au survol */
}
.btn-link-basket:hover .svg-list {
  @apply fill-black;
}

.svg-list {
  @apply w-5 fill-white-eronor;
}
@media screen and (min-width: 350px) {
  .btn-link-basket {
    @apply gap-2 px-1 py-2;
  }
}
</style>
