<script setup lang="ts">
import { ref } from 'vue'
import ButtonLink from '../button/ButtonLink.vue'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { URL_BACK } from '@/config/constants'
import { messageLogin, typeMessageLogin } from '@/event/account/messageLogin'
import { showAlertLogin } from '@/event/account/showAlertLogin'
import { idTimeout } from '@/event/account/idTimeout'
import stores from '@/storage/stores'

const emailForgot = ref('')
const focusedForgot = ref('')
const csrfToken = ref()

async function forgotPassword() {
  await fetchCsrf(csrfToken)
  const url = `https://${URL_BACK}/api/customer/forget-password`
  const data = {
    email: emailForgot.value,
    store_id: stores.state.storeData.store_id,
    store_name: stores.state.storeData.name
  }

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })

    const response = await res.json()
    messageLogin.value = response
    showAlertLogin.value = true

    if (messageLogin.value.class) {
      idTimeout.value = setTimeout(() => {
        showAlertLogin.value = false
        messageLogin.value = typeMessageLogin
      }, 6000)
    }
  } catch (e) {}
}
</script>

<template>
  <section class="section-forgot">
    <p>Renseigné votre email pour modifier votre mot de passe</p>
    <form class="form-forgot" @submit.prevent="forgotPassword">
      <div class="form-group">
        <input
          type="email"
          v-model="emailForgot"
          id="emailForgot"
          required
          @focus="focusedForgot = 'emailForgot'"
          @blur="focusedForgot = ''"
          :class="{ 'has-value': emailForgot !== '' || focusedForgot === 'emailForgot' }"
        />
        <label
          for="emailForgot"
          :class="{ active: emailForgot !== '' || focusedForgot === 'emailForgot' }"
        >
          <span>*</span> Email
        </label>
      </div>

      <ButtonLink />
    </form>
  </section>
</template>

<style scoped>
p {
  @apply max-w-72 pb-1 border-b text-center;
}
.section-forgot {
  @apply w-full;
}
.form-forgot {
  @apply pt-7 flex flex-col items-center gap-5;
}

span {
  @apply text-red-eronor;
}
.form-group {
  @apply relative flex flex-col items-center;
}
label {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
input {
  @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
}
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor;
}
label.active {
  transition: all 1s ease;
}
</style>
