import type { DateInterface } from '@/types/date'
import { reactive } from 'vue'

export interface OrderInterface {
  date_added: DateInterface
  date_modified: DateInterface | null
  order_date: DateInterface
  order_id: number
  order_status_id: number
  payment_method: string
  shipping_method: string
  total: string
  total_CC: string
  total_NKT: string
  total_TIC: string
}

const state = reactive({
  orderData: [] as OrderInterface[]
})

function setOrderData(data: OrderInterface[]) {
  state.orderData = data
}

function getOrderData() {
  return state.orderData
}

export default {
  state,
  setOrderData,
  getOrderData
}
