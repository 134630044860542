import { computed } from 'vue'
import address from '../address'

export const addressTable = computed(() => {
  return address.state.addressData.sort((a, b) => {
    // Si 'a' est l'adresse par défaut, elle doit venir en premier
    if (a.default && !b.default) return -1
    if (!a.default && b.default) return 1
    return 0
  })
})
