<script setup lang="ts"></script>

<template>
  <button class="next-button">
    <svg
      class="svg-arrow-next"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="none"
      stroke=""
      stroke-width="3"
    >
      <polyline points="16 16 32 32 16 48" />
      <polyline points="32 16 48 32 32 48" />
    </svg>
  </button>
</template>

<style scoped>
.next-button {
  @apply fixed text-slate-eronor hover:text-white-eronor z-40 bottom-10 right-32 rounded bg-amber-eronor hover:bg-lime-eronor py-2 px-3 hover:shadow-sm hover:shadow-white;
}
.svg-arrow-next {
  @apply w-6 stroke-slate-eronor;
}

@media screen and (min-width: 1200px) {
  .next-button {
    @apply right-auto left-52;
  }
}
@media screen and (min-width: 1500px) {
  .next-button {
    @apply left-[20rem];
  }
}
@media screen and (min-width: 1700px) {
  .next-button {
    @apply left-[26rem];
  }
}
</style>
