// App.vue

<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStoreInfo } from './utils/useStoreInfo'
import Header from '@/components/main/Header.vue'
import Footer from '@/components/main/Footer.vue'
import stores from './storage/stores'
import { indexUpdateElement, showCartBus } from './event/cartBus'
import { deleteElementBasketBus } from './event/deleteElementBasketBus'

import SectionOrder from '@/components/section/SectionOrder.vue'
import { showValidateOrder } from '@/event/order/showValidateOrder'
import AlertDeleteElement from '@/components/alert/AlertDeleteElement.vue'
import AlertClearBasket from '@/components/alert/AlertClearBasket.vue'
import ButtonTop from '@/components/button/ButtonTop.vue'
import { loadData } from './utils/loadData'
import { toggleShowNavAccount } from './utils/toggle/toggleShowNavAccount'
import { showNavAccount } from './event/account/showNavAccount'
import { dataLoaded } from './event/dataLoaded'
import { showAlertRegister, toggleAlertRegister } from './event/account/showAlertRegister'
import SectionOverlay from '@/components/alert/SectionOverlay.vue'
import { idTimeout } from './event/account/idTimeout'
import SpinerLoader from './components/main/SpinerLoader.vue'
import AlertBasketLogin from './components/alert/AlertBasketLogin.vue'
import { watchersApp } from './utils/watchers/app/watchersApp'
import Error from './components/error/Error.vue'
// import Symbol from './components/symbolSvg/symbol.vue'

const route = useRoute()

// const router = useRouter()
// const nameStore = ref('Eronor.com')
// const showOverlay = ref(showCartBus.value)
// const deleteElementBasket = ref(deleteElementBasketBus.value)

// loadData()

watchersApp(route)

onMounted(() => {
  loadData()
  // browserVersion.value = getBrowserVersion()
})
// watch(
//   () => route.name,
//   (newRoute, oldRoute) => {
//     // Check if we are returning from error_urlchecker
//     if (oldRoute === 'error_urlchecker') {
//       loadData() // Call loadData when coming back
//     }
//     if (idTimeout.value) {
//       if (oldRoute === 'register' || oldRoute === 'login' || oldRoute === 'confirm-register') {
//         // console.log(idTimeout.value)
//         clearTimeout(idTimeout.value)
//         idTimeout.value = null
//       }
//     }
//   }
// )

// const { testDay, testHour, testMinute, updateStore } = useStoreInfo()

// const resetIndexUpdateElement = () => {
//   indexUpdateElement.value = null
// }
// watch(
//   () => deleteElementBasketBus.value,
//   (newValue) => {
//     deleteElementBasket.value = newValue
//   }
// )

// watch(
//   () => route.params,
//   (newParams) => {
//     if (newParams.product_id === undefined) {
//       resetIndexUpdateElement()
//     }
//   }
// )

// watch(
//   () => showCartBus.value, // Utiliser une fonction qui retourne la valeur réactive
//   (newValue) => {
//     showOverlay.value = newValue
//   }
// )

// watch([testDay, testHour, testMinute], () => {
//   const data: any = stores.getStoresData()
//   if (data) {
//     updateStore(data)
//   }
// })

// const toggleShowValidateOrder = () => {
//   showValidateOrder.value = !showValidateOrder.value
// }

// watch(
//   () => dataLoaded.value,
//   async () => {
//     if (dataLoaded.value === false) {
//       await router.push({ name: 'error_urlchecker' })
//     }
//   }
// )
// console.log(dataLoaded.value)
// const { title, classTitle, updateStore, storeData } = useStoreInfo()
</script>

<script lang="ts">
// import { defineOptions } from 'vue'

// const browserVersion = ref('')

// function getBrowserVersion() {
//   const userAgent = navigator.userAgent
//   let browserName = ''
//   let version = ''

//   if (!userAgent) {
//     return 'Unknown browser' // Si userAgent est null ou undefined, retourne une valeur par défaut
//   }

//   if (userAgent.indexOf('Chrome') > -1) {
//     browserName = 'Chrome'
//     const match = userAgent.match(/Chrome\/(\d+\.\d+)/)
//     if (match) {
//       version = match[1] // Extraction de la version de Chrome
//     }
//   } else if (userAgent.indexOf('Firefox') > -1) {
//     browserName = 'Firefox'
//     const match = userAgent.match(/Firefox\/(\d+\.\d+)/)
//     if (match) {
//       version = match[1] // Extraction de la version de Firefox
//     }
//   } else if (userAgent.indexOf('Safari') > -1) {
//     browserName = 'Safari'
//     const match = userAgent.match(/Version\/(\d+\.\d+)/)
//     if (match) {
//       version = match[1] // Extraction de la version de Safari
//     }
//   } else if (userAgent.indexOf('Edge') > -1) {
//     browserName = 'Edge'
//     const match = userAgent.match(/Edge\/(\d+\.\d+)/)
//     if (match) {
//       version = match[1] // Extraction de la version de Edge
//     }
//   } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
//     browserName = 'Internet Explorer'
//     const match = userAgent.match(/(?:MSIE |rv:)(\d+\.\d+)/)
//     if (match) {
//       version = match[1] // Extraction de la version d'Internet Explorer
//     }
//   }

//   return `${browserName} ${version}`
// }

// // Garde de type pour s'assurer que l'objet est une instance de Error
// function isError(err: unknown): err is Error {
//   return err instanceof Error
// }

// // Fonction pour capturer les erreurs
// const captureError = (err: unknown, vm: any, info: string) => {
//   // Si l'erreur est une instance de Error, on l'affiche normalement
//   if (isError(err)) {
//     console.error('Erreur capturée :', err, info)
//     browserVersion.value = `Erreur capturée : ${err.message} (Version du navigateur: ${getBrowserVersion()})`
//   } else {
//     // Si ce n'est pas une instance d'Error, on la traite comme une erreur inconnue
//     console.error('Erreur inconnue :', err, info)
//     browserVersion.value = `Erreur inconnue : ${String(err)} (Version du navigateur: ${getBrowserVersion()})`
//   }

//   return false // Empêche la propagation de l'erreur
// }

// // Le hook `errorCaptured` va utiliser la fonction `captureError`
// defineOptions({
//   errorCaptured: captureError as (err: unknown, vm: any, info: string) => boolean
// })
</script>

<template>
  <div class="content-site">
    <div class="w-full h-full flex flex-col items-center">
      <Header />

      <!-- <p class="bg-red-eronor rounded-md px-2 py-1 text-white" v-if="browserVersion">
        {{ browserVersion }}
      </p> -->

      <SectionOverlay />

      <AlertDeleteElement />
      <AlertClearBasket />
      <AlertBasketLogin />

      <SectionOrder />

      <!-- <RouterView /> -->
      <RouterView v-if="dataLoaded == 2" class="router-view-class" />
      <Error v-else-if="dataLoaded == 1" />
      <SpinerLoader v-else />
    </div>
    <ButtonTop />

    <Footer />
    <!-- <Symbol /> -->
  </div>
</template>

<style scoped>
/* */

.content-site {
  @apply flex flex-col items-center justify-between min-h-screen;
}
@media screen and (min-width: 1030px) {
  .router-view-class {
    @apply w-[60rem];
  }
}
@media screen and (min-width: 1280px) {
  .router-view-class {
    @apply w-[65rem];
  }
}
@media screen and (min-width: 1500px) {
  .router-view-class {
    @apply w-[75rem];
  }
}
/* .side-bar-active {
  opacity: 1;
} */
</style>
