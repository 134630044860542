<script setup lang="ts">
import { orderData } from '@/event/order/data/orderData'
import { messageSend } from '@/event/order/messageSend'
import { fetchStripe } from '@/utils/stripe/fetchStripe'
import { ref } from 'vue'

const counterSend = ref(0)
const hostname = window.location.hostname
const messageClickEmpty = {
  message: '',
  class: 'error'
}
const messageClick = ref(messageClickEmpty)

async function payment() {
  const urlAddOrder = 'order/add'

  if (counterSend.value === 0) {
    try {
      await fetchStripe(urlAddOrder, orderData.value, hostname)
      counterSend.value++
    } catch (error) {}
  } else {
    const message = {
      message: 'La commande a bien été envoyer, veuillez patienter !',
      class: 'error'
    }
    messageClick.value = message
    setTimeout(() => {
      messageClick.value = messageClickEmpty
    }, 3000)
  }
}
</script>

<template>
  <section class="flex flex-col w-full items-center justify-center gap-2">
    <button class="btn-send-order" @click="payment">
      <p>Commander</p>
      <svg class="svg-order-send" viewBox="0 0 436 385" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M52.2708 133.557L66.7665 158.923C75.9948 175.073 80.6088 183.147 80.6088 192C80.6088 200.853 75.9948 208.927 66.7668 225.077L52.2708 250.443C10.9942 322.677 -9.64418 358.797 6.44982 376.707C22.5438 394.617 60.6542 377.943 136.875 344.597L346.064 253.077C405.914 226.893 435.837 213.8 435.837 192C435.837 170.2 405.914 157.107 346.064 130.923L136.875 39.4033C60.6545 6.05696 22.5438 -10.6164 6.44982 7.29429C-9.64418 25.205 10.9942 61.322 52.2708 133.557Z"
        />
      </svg>
    </button>
    <p v-if="messageClick.message" :class="messageClick.class">{{ messageClick.message }}</p>
  </section>
</template>

<style scoped>
.error {
  @apply bg-red-eronor px-3 py-2 rounded-sm;
}
.btn-send-order {
  @apply bg-lime-eronor hover:bg-limedark-eronor rounded-md p-2 flex items-center gap-1;
}

.svg-order-send {
  @apply w-5 fill-white-eronor;
}
</style>
