<script setup lang="ts">
import ButtonClose from '@/components/button/ButtonClose.vue'
import { messageAlert, type TypeMessageAlert } from '@/event/alert/messageAlert'
import { showAlertUpdateInfo } from '@/event/account/dashboard/showAlertUpdateInfo'
import { ref, watch, type Ref } from 'vue'

const props = defineProps<{ function: () => void; message: TypeMessageAlert }>()
const message = ref<TypeMessageAlert>(props.message)

watch(
  () => props.message,
  (newMessage) => {
    message.value = newMessage
  }
)
watch(
  () => showAlertUpdateInfo.value,
  (newMessage) => {
    message.value = messageAlert
  }
)
const clearMessage = () => {
  message.value = messageAlert
}

const toggle = () => {
  props.function()
  clearMessage()
}
</script>

<template>
  <section v-if="message" class="section-alert">
    <div v-if="message.errors && message.errors.length > 0" :class="message.class" class="hidden">
      <div class="div-button-alert">
        <ButtonClose class="button" :function="toggle" />
      </div>
      <p v-for="(error, i) in message.errors" :key="i" class="bullet">{{ error }}</p>
    </div>
    <div v-else :class="message.class" class="hidden">
      <div class="div-button-alert">
        <ButtonClose class="button" :function="toggle" />
      </div>
      <p>{{ message.message }}</p>
    </div>
  </section>
</template>

<style scoped>
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-2 pb-3 rounded-md absolute z-30 top-10;
}
.error {
  @apply flex flex-col text-center bg-red-eronor px-3 pt-1 pb-3 rounded-md absolute z-30 top-10;
}
.section-alert {
  @apply w-full flex flex-col items-center absolute max-w-[18rem];
}
/* .section-alert {
  @apply relative bg-slate-eronor rounded-md flex flex-col items-center gap-4;
  @apply mt-6 p-5;
} */
.div-button-alert {
  @apply flex justify-end;
}

.bullet {
  @apply relative pl-4;
}
.bullet::before {
  content: '•';
  position: absolute;
  left: 0;
}

@media screen and (min-width: 700px) {
  .section-alert {
    @apply max-w-[28rem];
  }
}
</style>
