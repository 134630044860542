import type { CartItem } from '@/event/cartBus'
import type { TransformedOption, TransformedProduct } from '@/types/basket/GoodProductInterface'

export const transformProductOptions = (cartItem: CartItem): TransformedOption[] => {
  const groupedOptions: Record<string, TransformedProduct[]> = {}

  cartItem.product_option.forEach((option) => {
    const { optionName, productName, valueId, option_id, price } = option

    const numericPrice = typeof price === 'string' ? parseFloat(price) : price
    const roundedPrice = numericPrice > 0 ? numericPrice.toFixed(2) : 0

    if (!groupedOptions[optionName]) {
      groupedOptions[optionName] = []
    }

    groupedOptions[optionName].push({
      productName,
      price: roundedPrice
    })
  })

  return Object.keys(groupedOptions).map((optionName) => ({
    optionName,
    products: groupedOptions[optionName],
    price_product: groupedOptions[optionName][0]?.price || 0
  }))
}
