import { productBus } from '@/event/product/productBus'
import type { Option } from '@/storage/products'

export const getOptionValueNameById = (option_value_id: number, languageId: number) => {
  let optionName = ''

  // Parcours des options pour trouver celle correspondant à l'option_value_id
  if (productBus.value) {
    productBus.value.options.forEach((option: Option) => {
      const value = option.values.find((v: any) => v.option_value_id === option_value_id)

      if (value) {
        // Recherche dans l'array "language" pour trouver la langue correspondante
        const languageObject = value.language.find((lang: any) => lang.language_id === languageId)

        // Si la langue correspondante est trouvée, récupère le nom
        if (languageObject) {
          optionName = languageObject.name
        }
      }
    })
  }

  return optionName
}
