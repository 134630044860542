import { selectionsBus } from '@/event/product/selectionsBus'
import { clearSelection } from './clearSelection'
import { extraPricesBus } from '@/event/product/extraPricesBus'

export const selectOptionsFromCart = (element: any) => {
  clearSelection()
  if (!element) return

  element.product_option.forEach((option: any) => {
    const optionName = option.optionName

    // Vérifiez si la clé existe déjà dans selections.value
    if (!selectionsBus.value[optionName]) {
      selectionsBus.value[optionName] = []
    }
    if (!extraPricesBus.value[optionName]) {
      extraPricesBus.value[optionName] = {}
    }
    selectionsBus.value[optionName].push(option.valueId)
    extraPricesBus.value[optionName][option.valueId] = option.price
  })
}
