<script setup lang="ts">
// OptionOrder.vue
import ButtonNextOrder from '@/components/button/order/ButtonNextOrder.vue'
import address from '@/storage/address'
import stores from '@/storage/stores'
import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import { toggleShowDeliveryZones } from '@/utils/order/toggleShowDeliveryZones'
import { totalPrice } from '@/utils/price/totalPrice'
import { computed, ref, watch, type ComputedRef } from 'vue'
import FormOrderAddress from './FormOrderAddress.vue'
import { configDeliveryZones } from '@/storage/configDeliveryZones'
import { addAddressBoolean } from '@/event/order/addAddressBoolean'
import { messageAddAddress } from '@/event/account/dashboard/messageAddAddress'
import { addressCustomer } from '@/event/account/dashboard/address/addressCustomer'
import { addressAdded } from '@/event/order/addressAdded'
// import { configCheckoutRestaurant } from '@/storage/configCheckoutRestaurant'
import { orderData, updateFieldOrderData } from '@/event/order/data/orderData'
import customer from '@/storage/customer'
import { shippingCost } from '@/event/order/data/shippingCost'
import { paymentStripeproStatus } from '@/storage/paymentStripeproStatus'
import { configHomeDeliveryService } from '@/storage/configHomeDeliveryService'

const props = defineProps({ isOpenInitial: String })
const showButton = ref(false)
const dropdownOptionOrder = ref(false)
const selectOptionOrder = ref('take')
const selectedOption = ref('Adresse') // Option sélectionnée
const selectedAllDataOption = ref()
const isDropdownOpen = ref(false) // Contrôle de l'affichage du menu
const childFormAddress = ref<InstanceType<typeof FormOrderAddress> | null>(null)
const radioAddress = ref('')

watch(
  () => address.state.addressData,
  async (newAddress) => {
    const newAddressAdded = await newAddress.find((ad) => {
      return ad.address_id == messageAddAddress.value.address
    })

    if (
      newAddressAdded &&
      (!addressAdded.value || addressAdded.value.postcode !== newAddressAdded.postcode)
    ) {
      addressAdded.value = newAddressAdded

      if (addressAdded.value.postcode.length === 4) {
        addressAdded.value.postcode = `0${addressAdded.value.postcode}`
      }
    }
  }
)

watch(
  () => props.isOpenInitial,
  (newProps) => {
    if (newProps == 'step1') {
      dropdownOptionOrder.value = true
      showButton.value = true
    } else {
      dropdownOptionOrder.value = false
    }
  }
)

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

// const selectOption = (option: any) => {
//   if (option.city && option.postcode) {
//     selectedOption.value = `${option.city} - (${option.postcode})` // Mise à jour de l'option sélectionnée
//     isDropdownOpen.value = false // Fermeture du menu
//   } else {
//     selectedOption.value = option
//     isDropdownOpen.value = false
//   }
//   selectedAllDataOption.value = option
// }
const selectOption = (option: any) => {
  selectedOption.value =
    option.city && option.postcode ? `${option.city} - (${option.postcode})` : option
  selectedAllDataOption.value = option
  isDropdownOpen.value = false
}

const price = ref(30)

function formatPostcode(address: { postcode: string | any[] }) {
  if (address.postcode.length === 4) {
    return { ...address, postcode: `0${address.postcode}` }
  }
  return address
}

function getPostalCodeFromZone(zone: { city: string }) {
  const match = zone.city.match(/\((\d+)\)$/)
  return match ? match[1] : null
}

function getMinDeliveryAmountForInvalid(postcode: string) {
  const matchingZone = configDeliveryZones.value.find((zone) => zone.city.includes(postcode))
  return matchingZone ? matchingZone.minimum_price : 'non défini'
}

// Filtrage et gestion des adresses clients selon les zones de livraison
const addressCustomerFilter = computed(() => {
  return addressCustomer.value.map((ad) => {
    const formattedAddress = formatPostcode(ad)

    const matchingZone = configDeliveryZones.value.find((zone) => {
      const postalCode = getPostalCodeFromZone(zone)
      return postalCode && Number(ad.postcode) === Number(postalCode)
    })

    if (matchingZone && Number(totalPrice.value) >= matchingZone.minimum_price) {
      return {
        ...ad,
        isSelectable: true,
        // message: 'Adresse valide pour la livraison.',
        minDeliveryAmount: null // Pas de restriction pour une adresse valide
      }
    } else {
      const minAmount = getMinDeliveryAmountForInvalid(ad.postcode)
      return {
        ...ad,
        isSelectable: false,
        // message: `Adresse non valide pour la livraison. Minimum: ${minAmount}`,
        minDeliveryAmount: minAmount
      }
    }
  })
})

// const addressCustomerFilter = computed(() => {
//   const filteredAddresses = addressCustomer.value.filter((ad) => {
//     return deliveryZones.value.some((zone) => {
//       const postalCode = getPostalCodeFromZone(zone)
//       return postalCode && Number(ad.postcode) === Number(postalCode)
//     })
//   })

//   radioAddress.value = filteredAddresses.length > 0 ? 'select' : 'new'
//   return filteredAddresses.map(formatPostcode)
// })

// const addressCustomerFilter = computed(() => {
//   const filteredAddresses = addressCustomer.value
//     .filter((ad) => {
//       return deliveryZones.value.some((zone) => {
//         const match = zone.city.match(/\((\d+)\)$/)
//         let postalCode = match ? match[1] : null

//         return (
//           postalCode !== null && Number(ad.postcode) === Number(postalCode)
//           // && Number(zone.minimum_price) <= price.value
//         )
//       })
//     })
//     .map((ad) => {
//       if (ad.postcode.length === 4) {
//         return {
//           ...ad,
//           postcode: `0${ad.postcode}`
//         }
//       }
//       return ad
//     })

//   if (filteredAddresses.length > 0) {
//     radioAddress.value = 'select'
//   } else {
//     radioAddress.value = 'new'
//   }

//   return filteredAddresses
// })

const emitContinue = defineEmits()

const toggleDropdownOptionOrder = () => {
  dropdownOptionOrder.value = !dropdownOptionOrder.value
  emitContinue('continue', { step: 'step1' })
}

// Obtenir le coût par zone de livraison
function costByDeliveryZones(price: number, codePostalId: number) {
  const zone = configDeliveryZones.value.find((z) => z.city_id === codePostalId)

  if (zone) {
    const { free_price, minimum_price, price: deliveryPrice } = zone
    if (price >= Number(free_price)) return 0 // Livraison gratuite
    if (price >= Number(minimum_price)) return Number(deliveryPrice) // Tarif fixe
  }

  return 0
}

// Calculer si une zone de livraison est disponible
function totalPriceIsGoodForDelivery(price: number) {
  const availableDeliveryZones = configDeliveryZones.value.filter(
    (zone) => price >= Number(zone.minimum_price)
  )

  return {
    availableDeliveryZones,
    canProceedToNextStep: availableDeliveryZones.length > 0
  }
}

const { availableDeliveryZones, canProceedToNextStep } = totalPriceIsGoodForDelivery(
  Number(totalPrice.value)
)

function addDataInOrderData() {
  shippingCost.value = 0

  if (selectOptionOrder.value == 'delivery') {
    // const cost
    shippingCost.value = costByDeliveryZones(
      Number(totalPrice.value),
      selectedAllDataOption.value.code_postal_id
    )
    // const totalPriceCost = (Number(totalPrice.value) + Number(shippingCost.value)) * 100
    const totalPriceCost = Number(totalPrice.value) + Number(shippingCost.value)

    updateFieldOrderData({
      // shipping_firstname: customer.state.customerData.firstname,
      // shipping_lastname: customer.state.customerData.lastname
      shipping_company: selectedAllDataOption.value.company,
      shipping_address_1: selectedAllDataOption.value.address_1,
      shipping_address_2: selectedAllDataOption.value.sup_address,
      shipping_city: selectedAllDataOption.value.city,
      shipping_postcode: selectedAllDataOption.value.postcode,
      shipping_country_id: selectedAllDataOption.value.country_id,
      shipping_zone_id: selectedAllDataOption.value.zone_id,
      shipping_method: 'Livraison',
      shipping_code: '6',
      shipping_price: shippingCost.value.toString(),
      total: totalPriceCost.toString()
    })
    // if (selectedOption.value !== 'Adresse') {
    // }
  } else {
    // const totalPriceStripe = totalPrice.value * 100

    updateFieldOrderData({
      shipping_company: null,
      shipping_address_1: null,
      shipping_address_2: null,
      shipping_city: null,
      shipping_postcode: null,
      shipping_country_id: null,
      shipping_zone_id: null,
      shipping_method: 'A Emporter',
      shipping_price: '',
      shipping_code: '5',
      total: totalPrice.value.toString()
    })
  }
}

const onContinue = async () => {
  if (childFormAddress.value) await childFormAddress.value.addAddressOrder()

  if (addressAdded.value) {
    selectedOption.value = await addressAdded.value
  }

  const canContinue =
    selectOptionOrder.value.trim() === 'take' ||
    selectedOption.value !== 'Adresse' ||
    addAddressBoolean.value === 2

  if (canContinue) {
    addDataInOrderData()
    // emitContinue('continue', { step: 'step2', option_order: selectOptionOrder.value })
    emitContinue('continue', { step: 'step2' })

    dropdownOptionOrder.value = false
  } else {
    addAddressBoolean.value = 1
    setTimeout(() => {
      addAddressBoolean.value = 0
    }, 3000)
  }
}
</script>

<template>
  <section class="section-option-order">
    <button
      class="button-option-order"
      :class="{ isactive: dropdownOptionOrder, 'rounded-md': !dropdownOptionOrder }"
      @click="toggleDropdownOptionOrder"
      :disabled="!showButton"
    >
      <div class="div-button-option-order">
        <svg
          :class="{ isactive: dropdownOptionOrder }"
          viewBox="-17 0 512 512.00429"
          id="acporderh"
          class="svg-order"
        >
          <path
            d="m153.601562 119.46875h8.535157v8.535156c0 14.136719 11.460937 25.597656 25.597656 25.597656 14.140625 0 25.601563-11.460937 25.601563-25.597656v-8.535156h8.53125c14.140624 0 25.601562-11.460938 25.601562-25.601562 0-14.136719-11.460938-25.597657-25.601562-25.597657h-8.53125v-8.535156c0-14.136719-11.460938-25.597656-25.601563-25.597656-14.136719 0-25.597656 11.460937-25.597656 25.597656v8.535156h-8.535157c-14.136718 0-25.597656 11.460938-25.597656 25.597657 0 14.140624 11.460938 25.601562 25.597656 25.601562zm0-34.132812h17.066407c4.714843 0 8.535156-3.820313 8.535156-8.535157v-17.066406c0-4.710937 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820313 8.535156 8.53125v17.066406c0 4.714844 3.820313 8.535157 8.53125 8.535157h17.066407c4.714843 0 8.535156 3.820312 8.535156 8.53125 0 4.714843-3.820313 8.535156-8.535156 8.535156h-17.066407c-4.710937 0-8.53125 3.820312-8.53125 8.53125v17.070312c0 4.710938-3.820312 8.53125-8.535156 8.53125-4.710937 0-8.53125-3.820312-8.53125-8.53125v-17.070312c0-4.710938-3.820313-8.53125-8.535156-8.53125h-17.066407c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125zm0 0"
          ></path>
          <path
            d="m332.800781 68.269531h-54.832031c-11.414062-40.386719-48.269531-68.269531-90.234375-68.269531s-78.820313 27.882812-90.230469 68.269531h-54.835937c-23.550781.027344-42.636719 19.113281-42.66406275 42.664063v358.402344c.02734375 23.550781 19.11328175 42.636718 42.66406275 42.667968h290.132812c23.554688-.03125 42.640625-19.117187 42.667969-42.667968v-358.402344c-.027344-23.550782-19.113281-42.636719-42.667969-42.664063zm-145.066406 119.464844c41.941406-.070313 78.757813-27.925781 90.234375-68.265625h46.300781v341.332031h-273.066406v-341.332031h46.300781c11.472656 40.339844 48.289063 68.195312 90.230469 68.265625zm0-170.664063c42.417969 0 76.800781 34.382813 76.800781 76.796876 0 42.417968-34.382812 76.800781-76.800781 76.800781-42.414063 0-76.800781-34.382813-76.800781-76.800781.050781-42.394532 34.40625-76.75 76.800781-76.796876zm170.667969 452.265626c0 14.136718-11.460938 25.597656-25.601563 25.597656h-290.132812c-14.136719 0-25.597657-11.460938-25.597657-25.597656v-358.402344c0-14.136719 11.460938-25.597656 25.597657-25.597656h51.636719c-.257813 2.816406-.4375 5.648437-.4375 8.53125 0 2.886718.179687 5.71875.4375 8.535156h-43.101563c-9.425781 0-17.066406 7.640625-17.066406 17.066406v341.332031c0 9.425781 7.640625 17.066407 17.066406 17.066407h273.066406c9.425781 0 17.066407-7.640626 17.066407-17.066407v-341.332031c0-9.425781-7.640626-17.066406-17.066407-17.066406h-43.101562c.253906-2.816406.433593-5.648438.433593-8.535156 0-2.882813-.179687-5.714844-.433593-8.53125h51.632812c14.140625 0 25.601563 11.460937 25.601563 25.597656zm0 0"
          ></path>
          <path
            d="m85.335938 273.070312c4.710937 0 8.53125-3.820312 8.53125-8.535156 0-4.710937-3.820313-8.53125-8.53125-8.53125v-34.136718h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820312-8.535157-8.53125-8.535157h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066407v34.136718c0 9.425782 7.640625 17.066406 17.066407 17.066406zm0 0"
          ></path>
          <path
            d="m116.96875 241.4375c-3.347656-3.234375-8.671875-3.1875-11.960938.101562-3.292968 3.292969-3.339843 8.613282-.105468 11.960938l17.066406 17.070312c3.332031 3.328126 8.734375 3.328126 12.066406 0l42.667969-42.667968c3.234375-3.347656 3.1875-8.671875-.105469-11.960938-3.292968-3.292968-8.613281-3.339844-11.960937-.105468l-36.632813 36.632812zm0 0"
          ></path>
          <path
            d="m290.136719 230.402344h8.53125c4.714843 0 8.535156-3.820313 8.535156-8.535156 0-4.710938-3.820313-8.53125-8.535156-8.53125h-8.53125c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
          ></path>
          <path
            d="m213.335938 230.402344h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820312-8.53125-8.53125-8.53125h-42.667968c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
          ></path>
          <path
            d="m213.335938 264.535156h68.265624c4.714844 0 8.535157-3.820312 8.535157-8.53125 0-4.714844-3.820313-8.535156-8.535157-8.535156h-68.265624c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710938 3.820313 8.53125 8.535157 8.53125zm0 0"
          ></path>
          <path
            d="m85.335938 358.402344c4.710937 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820313-8.53125-8.53125-8.53125v-34.132813h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820312-8.53125-8.53125-8.53125h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066406v34.132813c0 9.425781 7.640625 17.066406 17.066407 17.066406zm0 0"
          ></path>
          <path
            d="m164.636719 301.167969-36.632813 36.632812-11.035156-11.03125c-3.347656-3.234375-8.671875-3.1875-11.960938.101563-3.292968 3.292968-3.339843 8.617187-.105468 11.964844l17.066406 17.066406c3.332031 3.332031 8.734375 3.332031 12.066406 0l42.667969-42.667969c3.234375-3.347656 3.1875-8.667969-.105469-11.960937-3.292968-3.292969-8.613281-3.339844-11.960937-.105469zm0 0"
          ></path>
          <path
            d="m298.667969 298.667969h-8.53125c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710937 3.820313 8.53125 8.535157 8.53125h8.53125c4.714843 0 8.535156-3.820313 8.535156-8.53125 0-4.714844-3.820313-8.535156-8.535156-8.535156zm0 0"
          ></path>
          <path
            d="m213.335938 315.734375h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820312-8.535156-8.53125-8.535156h-42.667968c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710937 3.820313 8.53125 8.535157 8.53125zm0 0"
          ></path>
          <path
            d="m213.335938 349.867188h68.265624c4.714844 0 8.535157-3.820313 8.535157-8.53125 0-4.714844-3.820313-8.535157-8.535157-8.535157h-68.265624c-4.714844 0-8.535157 3.820313-8.535157 8.535157 0 4.710937 3.820313 8.53125 8.535157 8.53125zm0 0"
          ></path>
          <path
            d="m85.335938 443.734375c4.710937 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820313-8.535156-8.53125-8.535156v-34.132813h42.667968c4.710938 0 8.53125-3.820312 8.53125-8.53125 0-4.714844-3.820312-8.535156-8.53125-8.535156h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066406v34.132813c0 9.425781 7.640625 17.066406 17.066407 17.066406zm0 0"
          ></path>
          <path
            d="m164.636719 386.503906-36.632813 36.632813-11.035156-11.035157c-3.347656-3.234374-8.671875-3.1875-11.960938.105469-3.292968 3.289063-3.339843 8.613281-.105468 11.960938l17.066406 17.066406c3.332031 3.332031 8.734375 3.332031 12.066406 0l42.667969-42.664063c3.234375-3.351562 3.1875-8.671874-.105469-11.964843-3.292968-3.289063-8.613281-3.335938-11.960937-.101563zm0 0"
          ></path>
          <path
            d="m298.667969 384.003906h-8.53125c-4.714844 0-8.535157 3.820313-8.535157 8.53125 0 4.714844 3.820313 8.535156 8.535157 8.535156h8.53125c4.714843 0 8.535156-3.820312 8.535156-8.535156 0-4.710937-3.820313-8.53125-8.535156-8.53125zm0 0"
          ></path>
          <path
            d="m213.335938 401.070312h42.667968c4.710938 0 8.53125-3.820312 8.53125-8.535156 0-4.710937-3.820312-8.53125-8.53125-8.53125h-42.667968c-4.714844 0-8.535157 3.820313-8.535157 8.53125 0 4.714844 3.820313 8.535156 8.535157 8.535156zm0 0"
          ></path>
          <path
            d="m213.335938 435.203125h68.265624c4.714844 0 8.535157-3.820313 8.535157-8.535156 0-4.710938-3.820313-8.53125-8.535157-8.53125h-68.265624c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
          ></path>
          <path
            d="m455.683594 110.933594h-40.960938c-13.15625 1.023437-23.042968 12.433594-22.1875 25.601562v281.601563c0 .953125.160156 1.898437.476563 2.796875l27.792969 79.976562c1.71875 6.519532 7.601562 11.070313 14.339843 11.09375 6.742188.023438 12.65625-4.484375 14.417969-10.992187l27.828125-80.085938c.316406-.894531.476563-1.839843.476563-2.789062v-281.601563c.859374-13.167968-9.03125-24.578125-22.183594-25.601562zm-4.976563 334.789062c-10.167969-2.648437-20.847656-2.648437-31.019531 0l-6.621094-19.054687h44.269532zm-41.105469-232.386718v-25.601563h51.199219v25.601563zm34.132813 196.265624v-110.933593c0-4.710938-3.820313-8.53125-8.53125-8.53125-4.714844 0-8.535156 3.820312-8.535156 8.53125v110.933593h-17.066407v-179.199218h17.066407v34.132812c0 4.714844 3.820312 8.535156 8.535156 8.535156 4.710937 0 8.53125-3.820312 8.53125-8.535156v-34.132812h17.066406v179.199218zm-29.011719-281.597656h40.960938c2.421875 0 5.117187 3.652344 5.117187 8.53125v34.132813h-51.199219v-34.132813c0-4.878906 2.695313-8.53125 5.121094-8.53125zm20.480469 362.332032-9.898437-28.484376c6.527343-1.394531 13.277343-1.394531 19.804687 0zm0 0"
          ></path>
        </svg>
        <h4>Option de commande</h4>
      </div>
      <svg
        class="svg-arrowbottom"
        viewBox="0 0 24 24"
        :class="{
          'rotate-up': dropdownOptionOrder,
          isactive: dropdownOptionOrder,
          hidden: !showButton
        }"
      >
        <path
          d="M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.3897 15.171 12.1989 15.25 12 15.25C11.8011 15.25 11.6103 15.171 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z"
        />
      </svg>
    </button>

    <section v-if="dropdownOptionOrder" class="section-content-option-order">
      <div class="div-content-option-order">
        <label class="label-option-order" for="take">
          <input
            type="radio"
            name="order"
            id="take"
            value="take"
            checked
            v-model="selectOptionOrder"
          />
          Emporter
        </label>
        <label
          v-if="
            configDeliveryZones &&
            configDeliveryZones.length >= 0 &&
            paymentStripeproStatus == 1 &&
            configHomeDeliveryService == 1
          "
          class="label-option-order"
          for="delivery"
          ><input
            type="radio"
            name="order"
            id="delivery"
            value="delivery"
            v-model="selectOptionOrder"
            :disabled="configDeliveryZones.length === 0 || !canProceedToNextStep"
          />Livraison
          <button
            v-if="configDeliveryZones.length > 0"
            class="p-delivery-zone"
            @click="toggleShowDeliveryZones"
          >
            ( Zones de livraison )
          </button>
          <p v-if="configDeliveryZones.length == 0" class="text-red-eronor text-sm text-center">
            La livraison n'est pas disponible !
          </p>
        </label>
        <p
          v-if="configDeliveryZones.length > 0 && !canProceedToNextStep"
          class="bg-red-eronor mt-1 text-center text-sm"
        >
          Le prix ne remplis pas les conditions de livraison
        </p>
      </div>

      <div v-if="canProceedToNextStep" class="">
        <div for="" v-if="selectOptionOrder === 'delivery'">
          <label for="radioAddressSelect" v-if="addressCustomerFilter.length > 0" class="p-select">
            <input
              type="radio"
              name="radioAddressSelect"
              id="radioAddressSelect"
              value="select"
              v-model="radioAddress"
            />
            <p class="ml-1">Utiliser une adresse existante</p>
          </label>
          <div
            class="custom-select"
            @click="toggleDropdown"
            v-if="addressCustomerFilter.length > 0 && radioAddress === 'select'"
          >
            <div class="selected" :class="{ 'rounded-md': !isDropdownOpen }">
              <div v-if="addressAdded">
                <p v-if="addressAdded.sup_address">
                  {{ addressAdded.city }} - ({{ addressAdded.postcode }}),
                  {{ addressAdded.address_1 }},
                  {{ addressAdded.sup_address }}
                </p>
                <p v-else>
                  {{ addressAdded.city }} - ({{ addressAdded.postcode }}),
                  {{ addressAdded.address_1 }}
                </p>
              </div>
              <p v-else>{{ selectedOption || 'Adresse' }}</p>
              <div class="arrow" :class="{ 'rotate-up': isDropdownOpen }">
                &#9660;
                <!-- Symbole de flèche vers le bas -->
              </div>
            </div>

            <div v-if="isDropdownOpen" class="options-container">
              <div class="option" @click.stop="selectOption('Adresse')">Adresse</div>
              <div
                class="option"
                v-for="(a, i) in addressCustomerFilter"
                :key="i"
                @click.stop="a.isSelectable && selectOption(a)"
              >
                <p v-if="a.address_2">
                  {{ a.city }} - ({{ a.postcode }}), {{ a.address_1 }}, {{ a.address_2 }}
                </p>
                <p v-else>{{ a.city }} - ({{ a.postcode }}), {{ a.address_1 }}</p>
                <p v-if="a.minDeliveryAmount" class="text-red-eronor">
                  le prix de la commande minimum est {{ a.minDeliveryAmount }}€
                </p>
              </div>
            </div>
          </div>
          <label class="p-select" for="radioAddressNew">
            <input
              type="radio"
              id="radioAddressNew"
              name="radioAddressNew"
              v-model="radioAddress"
              value="new"
              :disabled="addressCustomer.length === 4"
            />
            <p :class="{ 'line-through': addressCustomer.length === 4 }" class="ml-1">
              Nouvelle adresse
            </p>
          </label>
          <div v-if="radioAddress == 'new'">
            <div class="div-option-newaddress">
              <span class="span-newaddress">* (obligatoire)</span>
            </div>
            <FormOrderAddress ref="childFormAddress" />
          </div>
        </div>
      </div>

      <p v-if="addAddressBoolean == 1" class="error-p">Vous n'avez pas selectionner d'adresse !</p>

      <div class="div-btn-continue-order">
        <ButtonNextOrder @click="onContinue" />
      </div>
    </section>
  </section>
</template>

<style scoped>
.p-select {
  @apply pl-8 flex;
}
.error-p {
  @apply bg-red-eronor w-full mt-1 px-1 py-2 text-center;
}

/** */
.section-option-order {
  @apply bg-slate-eronor rounded-md;
}
.button-option-order {
  @apply flex items-center gap-3 justify-between w-full pl-4 pr-2 py-3;
}
.div-button-option-order {
  @apply flex items-center gap-5;
}
.svg-order {
  @apply w-8 fill-white;
}
.svg-arrowbottom {
  @apply w-10 fill-white;
  transition: transform 0.5s ease;
}
.rotate-up {
  transform: rotate(180deg); /* Rotation à 180 degrés pour pointer vers le haut */
}
.button-option-order:hover {
  @apply bg-slate-hover;
}
.button-option-order:hover svg,
.button-option-order:hover h4 {
  @apply fill-lime-eronor text-lime-eronor;
}
.isactive {
  @apply fill-lime-eronor text-lime-eronor rounded-t-md bg-slate-hover;
}

.section-content-option-order {
  @apply py-2 border-t flex flex-col gap-1;
}
.div-content-option-order {
  @apply flex flex-col gap-1;
}
/* .text-rising {
  @apply pb-2 text-center;
} */
.label-option-order {
  @apply flex items-center gap-3 px-4;
}
.p-delivery-zone {
  @apply text-lime-eronor hover:text-limedark-eronor;
}
.div-btn-continue-order {
  @apply w-full pt-2 pr-2 flex justify-end;
}

.custom-select {
  @apply text-slate-eronor text-wrap rounded-md text-base relative m-1;
  /* background-color: #f5f5f5; */
  /* border: 2px solid #ccc; */
  /* border-radius: 8px; */
  /* padding: 10px; */
  /* font-size: 16px; */
  /* width: 100%; */
  cursor: pointer;
  box-sizing: border-box;
}
/* .arrow { */
/* font-size: 18px;
  margin-left: 10px; */
/* transition: transform 0.3s ease;  */
/* } */
.arrow {
  @apply flex items-center absolute top-3 right-4;
  position: absolute;
  /* top: 12px; */
  /* right: 15px; */
  /* height: 100%; */
  /* transform: translateY(-50%); */
  /* font-size: 18px; */
  transition: transform 0.3s ease;
}

.selected {
  @apply p-3 rounded-t-md;
  border: 1px solid #ccc;
}
.options-container {
  @apply absolute w-full  rounded-b-md  border-t;

  max-height: 200px;
  z-index: 30;
}
.option {
  padding: 10px;
  cursor: pointer;
  word-wrap: break-word; /* Permet au texte de se couper à la ligne si nécessaire */
  white-space: normal; /* Permet le retour à la ligne */
  z-index: 30;
}
.option:hover {
  @apply bg-lime-eronor;
}

.custom-select .selected {
  background-color: #fff;
  color: #333;
}

.custom-select .option {
  background-color: white;
  color: #333;
}

.custom-select .option:hover {
  background-color: #f0f0f0;
}

.div-option-newaddress {
  @apply flex flex-col w-full items-center;
}
.text-newaddress {
  @apply underline font-semibold;
}
.span-newaddress {
  @apply text-red-eronor;
}
/* .select-custom::-ms-expand {
  display: none;
} */

@media screen and (min-width: 400px) {
  .button-option-order {
    @apply justify-between;
  }
  .div-content-option-order {
    @apply px-2;
  }

  .p-select {
    @apply pl-12;
  }
  /* .section-content-option-order {
    @apply px-8;
  } */
  .error-p {
    @apply rounded-md;
  }
}
@media screen and (min-width: 600px) {
  .p-select,
  .options-container,
  .label-option-order,
  .selected {
    @apply w-96;
  }
  .div-content-option-order {
    @apply px-6;
  }
  .option-container {
    @apply pl-12;
  }
  .custom-select {
    @apply pl-12;
  }
  .p-select {
    @apply pl-16;
  }
  .selected {
    @apply relative;
  }
}
</style>
