import { URL_BACK } from '@/config/constants'
import { ref } from 'vue'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { isNotAuthenticated } from '@/event/account/auth/isNotAuthenticated'
import stores, { getCurrentStoreId } from '@/storage/stores'
import { formatStoreName } from '../store/formatStoreName'
const csrfToken = ref()

export async function fetchRefreshToken(refreshToken: string) {
  await fetchCsrf(csrfToken)

  const url = `https://${URL_BACK}/api/refresh-token`

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
        store_id: stores.getStoresData().store_id
      })
    })
    const response = await res.json()

    if (res.ok && response.jeton) {
      // const payload = JSON.parse(atob(response.jeton.split('.')[1]))
      // const currentStoreId = getCurrentStoreId()
      const store = stores.getStoresData().name

      if (store) {
        const storeName = formatStoreName(store)
        localStorage.setItem(`${storeName}_jwt`, response.jeton)
        localStorage.setItem(`${storeName}_refresh_token`, response.refresh_token)
      } else {
        localStorage.setItem('jwt', response.jeton)
        localStorage.setItem('refresh_token', response.refresh_token)
      }
      isAuthenticated.value = true
    } else {
      // localStorage.removeItem('')
      isNotAuthenticated.value = 1
    }
  } catch (error) {}
}
