<script setup lang="ts">
import IconArrowBottom from '@/components/icons/iconEronor/IconArrowBottom.vue'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory'
import { showDropdownSidebarSiteMap } from '@/event/main/navbar/showDropdownSidebarSitemap'
import { disconnect } from '@/utils/account/disconnect'
import { toggleMenu } from '@/utils/toggle/toggleMenu'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const arrayLinkProjectBase = ref([
  { name: 'Accueil', url: '/', route_name: 'racine' },
  { name: 'Panier', url: '/basket', route_name: 'basket' }
  //   { name: 'Produits', url: '/product' }
])

const arrayLinkProjectBeforeAccount = ref([
  { name: 'Connexion', url: '/login', route_name: 'login' },
  { name: "S'inscrire", url: '/register', route_name: 'register' }
])

const arrayLinkProjectAfterAccount = ref([
  { name: 'Tableau de bord', url: '/dashboard', route_name: 'dashboard' },
  { name: 'Déconnexion', url: '#', route_name: 'disconnect' }
  // { name: 'Modifier mes informations', url: '/dashboard/edit' },
  // { name: 'Modifier mon mot de passe', url: '/dashboard/password' },
  // { name: "Carnet d'adresses", url: ' /dashboard/address' },
  // { name: 'Historique de commandes', url: '/dashboard/order' },
  // { name: 'Transactions', url: '/dashboard/transaction' },
  // { name: 'Carte de paiement', url: '/dashboard/recurring' }
])

const toggleDropdownSitemap = () => {
  showDropdownSidebarSiteMap.value = !showDropdownSidebarSiteMap.value
  if (showDropdownSidebarCategory.value == true) {
    showDropdownSidebarCategory.value = false
  }
}

// watch(
//   () => route.params,
//   () => {
//     if (route.params) {
//     }
//   }
// )
</script>

<template>
  <section class="section-link-path">
    <button
      class="button-sitemap"
      :class="{ 'button-sitemap-isactive': showDropdownSidebarSiteMap }"
      @click="toggleDropdownSitemap"
    >
      <h2>Plan du site</h2>
      <IconArrowBottom
        class=""
        :class="{ 'dropdown-sitemap-isactive': showDropdownSidebarSiteMap }"
      />
    </button>

    <div v-if="showDropdownSidebarSiteMap" class="h-full">
      <!-- <div class="div-link-path"> -->
      <router-link
        class="link-sitemap"
        v-for="(link, index) in arrayLinkProjectBase"
        :to="{ path: link.url }"
        :key="index"
        @click="toggleMenu"
        ><p class="text-link-sitemap" :class="{ isactive: link.route_name == route.name }">
          {{ link.name }}
        </p>
      </router-link>
      <!-- </div> -->
      <div v-if="!isAuthenticated" class="div-link-path-non-authenticated">
        <router-link
          class="link-sitemap"
          v-for="(link, index) in arrayLinkProjectBeforeAccount"
          :to="{ path: link.url }"
          :key="index"
          @click="toggleMenu"
          ><p :class="{ isactive: link.route_name == route.name }" class="text-link-sitemap">
            {{ link.name }}
          </p></router-link
        >
      </div>

      <div v-if="isAuthenticated" class="div-link-path-authenticated">
        <router-link
          class="link-sitemap"
          v-for="(link, index) in arrayLinkProjectAfterAccount"
          :to="{ path: link.url }"
          :key="index"
          @click="toggleMenu"
        >
          <p v-if="link.route_name == 'disconnect'" @click="disconnect" class="text-link-sitemap">
            {{ link.name }}
          </p>
          <p v-else :class="{ isactive: link.route_name == route.name }" class="text-link-sitemap">
            {{ link.name }}
          </p>
        </router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-link-path {
  @apply flex flex-col text-center w-full uppercase font-medium h-3/5;
}
.button-sitemap {
  @apply uppercase border-t-4 px-2 border-b-4 border-slate-hover py-2 flex justify-between items-center;
}
.button-sitemap-isactive {
  @apply border-b-lime-eronor border-t-lime-eronor;
}
.div-link-path-non-authenticated {
  @apply flex flex-col h-full justify-end;
}
.div-link-path-authenticated {
  @apply flex flex-col h-full justify-between;
}
.link-sitemap {
  @apply block border-b border-s-slate-hover py-1 w-full;
}
.text-link-sitemap {
  @apply py-2 hover:bg-slate-hover;
}
.isactive {
  @apply bg-slate-hover;
}
.dropdown-sitemap-isactive {
  @apply fill-lime-eronor;
  transform: rotate(180deg);
}
</style>
