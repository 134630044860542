// stores.ts
import { reactive, ref } from 'vue'

interface Hours {
  days: string[]
  open: string
  close: string
}
interface OpeningHours {
  opening_hours: Hours[] // Updated to be an array of time slots
  pickup_service: Hours[] // Updated to be an array of time slots
  delivery_service: Hours[] // Updated to be an array of time slots
  table_reservation: Hours[] // Updated to be an array of time slots
}

interface Language {
  language_id: number
  name: string
}

interface Category {
  category_id: number
  column: number
  image: string
  language: Language[]
  show_in_suggested: boolean
  sort_order: number
  top: boolean
}

interface Printer {
  printer_id: number
  template_id: number
  name: string
  printer_type: string
  printer_ip: string
  printer_port: number
  print_delay: number
  printer_default: number
  copy_count: number
  sort_order: number
  store_id: number
  part_size: number
  buffer_size: string
}

interface StoresData {
  store_id: number
  partner_id: number
  name: string
  message_event: string
  banner_id: number
  banner_images: {
    banner_image_id: number
    title: string
    image: string
  }[]
  printers: Printer[]
  setting: {
    [key: string]: any
  }
  category: Category[]
}

const state = reactive({
  storeData: {} as Partial<StoresData>
})

// Fonction pour mettre à jour l'objet de réponse
function setStoresData(data: any) {
  state.storeData = data
}

// Fonction pour obtenir l'objet de réponse
function getStoresData() {
  return state.storeData
}

export function getCurrentStoreId() {
  const storeId = state.storeData.store_id
  return storeId ?? 0 // Si storeId est null ou undefined, retourne 0
}

// Exporte les méthodes et l'état
export type { StoresData as StoresDataType }
export type { OpeningHours as OpeningHoursType }
export type { Hours as HoursType }
export type { Category as CategoryType }

export default {
  state,
  setStoresData,
  getStoresData
}
