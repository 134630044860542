import { URL_BACK } from '@/config/constants'
import { idTimeout } from '@/event/account/idTimeout'
import { messageConfirmRegister } from '@/event/account/register/messageConfirmRegister'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { ref } from 'vue'
import type { Router } from 'vue-router'

export async function getUserConfirmRegister(
  emailValid: any,
  emailInvalide: any,

  token: any,

  router: Router
) {
  const csrfToken = ref()

  const data = {
    // email: email,
    token: token
    // store_id: storeId
  }
  try {
    await fetchCsrf(csrfToken)
    const url = `https://${URL_BACK}/api/customer/confirm-register`
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })

    const response = await res.json()
    messageConfirmRegister.value = response
    if (response.class == 'success') {
      emailValid.value = true
      idTimeout.value = setTimeout(() => {
        router.push('/login')
      }, 6000)
    } else {
      emailInvalide.value = true
    }
  } catch (e) {}
}
