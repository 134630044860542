<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import IconEye from '@/components/icons/iconEronor/IconEye.vue'
import IconEyeOff from '@/components/icons/iconEronor/IconEyeOff.vue'
import ButtonConnection from '@/components/button/account/ButtonConnection.vue'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { URL_BACK } from '@/config/constants'
import { messageLogin, typeMessageLogin } from '@/event/account/messageLogin'
import { showAlertLogin, toggleAlertLogin } from '@/event/account/showAlertLogin'
import ButtonClose from '@/components/button/ButtonClose.vue'
import { idTimeout } from '@/event/account/idTimeout'
import ButtonForgot from '@/components/button/account/ButtonForgot.vue'
import ForgotForm from '@/components/account/ForgotForm.vue'
import { forgotInputBus } from '@/event/account/forgotInputBus'
import { useRouter } from 'vue-router'
import { isAuthenticated } from '@/event/account/auth/authentification'
import stores from '@/storage/stores'
import { linkGoOrder } from '@/event/basket/linkGoOrder'
import ButtonLinkRegister from '../button/account/ButtonLinkRegister.vue'
import { formatStoreName } from '@/utils/store/formatStoreName'

const focused = ref('')
const email = ref('')
const password = ref('')
const showPassword = ref(false)
const csrfToken = ref()
const rememberMe = ref(false)
const router = useRouter()

async function login() {
  await fetchCsrf(csrfToken)

  const data = {
    store_id: stores.state.storeData.store_id,
    email: email.value,
    password: password.value,
    remember_me: rememberMe.value
  }

  const url = `https://${URL_BACK}/api/customer/login`

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })

    const response = await res.json()

    if (res.ok) {
      const store = stores.getStoresData().name

      if (store) {
        const storeName = formatStoreName(store)
        localStorage.setItem(`${storeName}_jwt`, response.jeton)
        localStorage.setItem(`${storeName}_refresh_token`, response.refresh_token)
      } else {
        localStorage.setItem('jwt', response.jeton)
        localStorage.setItem('refresh_token', response.refresh_token)
      }

      messageLogin.value = response
      showAlertLogin.value = true

      if (messageLogin.value.class === 'success' && messageLogin.value.action == 'login') {
        isAuthenticated.value = true
        idTimeout.value = setTimeout(() => {
          showAlertLogin.value = false
          messageLogin.value = typeMessageLogin
          if (linkGoOrder.value) {
            linkGoOrder.value = false
            router.push('/basket/order')
          } else {
            router.push('/dashboard')
          }
        }, 4000)
      }
    }
  } catch (e) {}
}
</script>

<template>
  <section class="section-login-form">
    <div
      v-if="messageLogin.errors && messageLogin.errors.length > 0"
      :class="messageLogin.class"
      class="hidden min-w-56"
    >
      <div class="div-button-alert-login">
        <ButtonClose class="button" :function="toggleAlertLogin" />
      </div>
      <p v-for="(error, index) in messageLogin.errors" :key="index" class="bullet">
        {{ error }}
      </p>
      <div v-if="messageLogin.action == 'not-exist'" class="pt-3"><ButtonLinkRegister /></div>
    </div>
    <div v-else :class="messageLogin.class" class="hidden min-w-56 items-center">
      <div class="div-button-alert-login">
        <ButtonClose :function="toggleAlertLogin" />
      </div>
      <p>{{ messageLogin.message }}</p>
      <div v-if="messageLogin.action == 'not-exist'" class="pt-3"><ButtonLinkRegister /></div>
    </div>

    <h3>Connexion</h3>
    <form action="" class="form-login" @submit.prevent="login">
      <div class="form-group">
        <input
          type="email"
          v-model="email"
          id="email"
          required
          @focus="focused = 'email'"
          @blur="focused = ''"
          :class="{ 'has-value': email !== '' || focused === 'email' }"
          class="input-login"
        />
        <label
          class="label-slate"
          for="email"
          :class="{ active: email !== '' || focused === 'email' }"
        >
          <span>*</span> Email
        </label>
      </div>
      <div class="form-group">
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          id="password"
          required
          @focus="focused = 'password'"
          @blur="focused = ''"
          :class="{ 'has-value': password !== '' || focused === 'password' }"
          class="input-login"
        />
        <label
          class="label-slate"
          for="password"
          :class="{ active: password !== '' || focused === 'password' }"
        >
          <span>*</span> Mot de passe
        </label>
        <span class="eye-icon" @click="showPassword = !showPassword"
          ><template v-if="showPassword"><IconEye /></template
          ><template v-else><IconEyeOff /></template
        ></span>
      </div>

      <div class="flex items-center gap-2">
        <p class="remember-label" @click="rememberMe = !rememberMe">Se souvenir de moi</p>
        <label class="custom-checkbox">
          <input type="checkbox" v-model="rememberMe" id="remember" />
          <span class="checkbox-indicator"
            ><span class="checkmark" v-if="rememberMe">✓</span>
            <!-- Checkmark icon -->
          </span>
        </label>
      </div>
      <ButtonConnection />
    </form>

    <ButtonForgot />
    <ForgotForm v-if="forgotInputBus" />
  </section>
</template>

<style scoped>
.remember-label {
  @apply cursor-pointer;
  margin-right: 10px; /* Add space between label and checkbox */
}

.custom-checkbox {
  @apply bg-white-eronor rounded-xl;
  display: inline-block;
  width: 20px; /* Width of the checkbox */
  height: 20px; /* Height of the checkbox */
  cursor: pointer;
  position: relative; /* Position relative for indicator */
}

input[type='checkbox'] {
  display: none; /* Hide the default checkbox */
}

input[type='checkbox']:checked + .checkbox-indicator {
  @apply bg-lime-eronor;
}

.checkbox-indicator {
  @apply rounded-xl;
  width: 100%;
  height: 100%;
  position: absolute; /* Use absolute positioning */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  transition: background-color 0.3s; /* Smooth transition */
  display: flex; /* Use flexbox to center the checkmark */
  align-items: center;
  justify-content: center;
}

/* Show the checkmark when checked */
.checkmark {
  color: white; /* Change checkmark color to white */
  font-size: 14px; /* Adjust font size */
  position: absolute; /* Position it correctly */
}

/* Add a hover effect */
.custom-checkbox:hover .checkbox-indicator {
  @apply bg-white-eronor;
}

/* input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 12px;
}
input[type='checkbox']:checked + label {
  background: limegreen;
} */
.div-button-alert-login {
  @apply flex justify-end w-full;
}
.bullet {
  @apply relative pl-4;
}
.bullet::before {
  content: '•';
  position: absolute;
  left: 0;
}
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-2 pb-3 rounded-md absolute z-30 top-10;
}
.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-3 rounded-md absolute z-30 top-10;
}
.eye-icon {
  @apply absolute top-2/4 right-2;

  transform: translateY(-50%);
  cursor: pointer;
}

.svg-eye,
.svg-eye-off {
  @apply w-5;
  width: 20px;
  height: 20px;
  stroke: #666;
}
h3 {
  @apply font-bold w-full text-center border-b pb-2;
}
span {
  @apply text-red-eronor;
}
.section-login-form {
  @apply relative bg-slate-eronor rounded-md flex flex-col items-center gap-4 mt-6 p-5;
}
.form-login {
  @apply flex flex-col items-center gap-3;
}
.form-login input {
  @apply rounded-2xl border-2 outline-none focus:border-red-eronor;
}
.form-group {
  @apply relative flex flex-col items-center mt-5;
}
label {
  /* @apply absolute  text-slate-eronor; */
}

.label-slate {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
.input-login {
  @apply text-slate-eronor text-center min-w-64;
}
/* input {
  @apply text-slate-eronor text-center min-w-64;
} */
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor;
}
label.active {
  transition: all 1s ease;
}
</style>
