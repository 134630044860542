import { ref } from 'vue'

export type TypeMessageAlert = {
  message: string
  class: string
  errors: string[]
}

export const messageAlert = {
  message: '',
  class: '',
  errors: []
}

// export const refMessageAlert = ref(messageAlert)
