import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { configDeliveryZones } from '@/storage/configDeliveryZones'
import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import {
  messageAddAddress,
  messageAddAddressEmpty
} from '@/event/account/dashboard/messageAddAddress'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'
import { showAdressForm } from '@/event/account/dashboard/showFormAddress'
import { toggleShowAddressForm } from '@/utils/toggle/toggleShowAddressForm'

export function useAddress(indexAddress: any, address: any, customer: any, stores: any) {
  const h1 = computed(() =>
    typeof indexAddress.value === 'number' ? "Modifier l'adresse" : 'Ajouter une adresse'
  )
  const textButton = computed(() =>
    typeof indexAddress.value === 'number' ? 'Modifier' : 'Ajouter'
  )

  const addressModify = computed(() => {
    if (indexAddress.value !== null && indexAddress.value !== undefined)
      return address.state.addressData[indexAddress.value]
  })

  const company = ref(addressModify.value?.company || '')
  const addressForm = ref(addressModify.value?.address_1 || '')
  const supaddress = ref(addressModify.value?.address_2 || '')
  const selectedRegion = ref('')
  const selectedCity = ref(
    addressModify.value?.city
      ? addressModify.value.city + ' (' + addressModify.value.postcode + ')'
      : ''
  )
  const optionRegion = ref<string[]>([])
  const optionCity = ref<string[]>([])
  const dropdownOpen = ref<string | null>(null)
  const defaultAddress = ref('false')
  const selectedCountryId = ref<number | string>('')
  const selectedZoneId = ref<number | string>('')
  const selectedCityId = ref<number | string>('')

  const loadOptions = () => {
    optionRegion.value = [
      ...new Set(configDeliveryZones.value.map((zone: DeliveryZone) => zone.zone))
    ].sort()
    optionCity.value = []
  }

  loadOptions()

  async function loadOptionsByZoneId() {
    if (configDeliveryZones.value) {
      const foundZone = await configDeliveryZones.value.find(
        (zone: DeliveryZone) => zone.zone_id === addressModify.value?.zone_id
      )
      if (foundZone) selectedRegion.value = foundZone.zone
    }
    if (addressModify.value?.default) defaultAddress.value = 'true'
    updateCityOptionModify()
  }

  if (addressModify.value) loadOptionsByZoneId()

  watch(
    () => stores.state.storeData.setting,
    () => loadOptions(),
    { immediate: true }
  )

  const updateCityOptions = () => {
    optionCity.value = []
    selectedCity.value = ''
    if (selectedRegion.value) {
      optionCity.value = configDeliveryZones.value
        .filter((zone) => zone.zone === selectedRegion.value)
        .map((zone) => zone.city)
        .sort()
    }
  }

  const updateCityOptionModify = () => {
    optionCity.value = []
    if (selectedRegion.value) {
      optionCity.value = configDeliveryZones.value
        .filter((zone) => zone.zone === selectedRegion.value)
        .map((zone) => zone.city)
        .sort()
    }
  }

  const updateSelectedCityId = () => {
    const selectedCityData = configDeliveryZones.value.find(
      (zone) => zone.city === selectedCity.value && zone.zone === selectedRegion.value
    )
    if (selectedCityData) {
      selectedZoneId.value = selectedCityData.zone_id
      selectedCountryId.value = selectedCityData.country
      selectedCityId.value = selectedCityData.city_id
    }
  }

  const toggleDropdown = (type: 'region' | 'city') => {
    dropdownOpen.value = dropdownOpen.value === type ? null : type
  }

  const selectOptionRegion = (option: string) => {
    selectedRegion.value = option
    dropdownOpen.value = null
    updateCityOptions()
  }

  const selectOptionCity = (option: string) => {
    selectedCity.value = option
    dropdownOpen.value = null
    updateSelectedCityId()
  }

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (!target.closest('.dropdown-select')) {
      dropdownOpen.value = null
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside)
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
  })

  const addAddress = async () => {
    const url = 'address/add'

    const data = {
      customer_id: customer.state.customerData.customer_id,
      customer_firstname: customer.state.customerData.firstname,
      customer_lastname: customer.state.customerData.lastname,
      address: addressForm.value,
      code_postal_id: selectedCityId.value,
      city: selectedCity.value,
      country_id: selectedCountryId.value,
      zone_id: selectedZoneId.value,
      company: company.value,
      address_2: supaddress.value,
      default: defaultAddress.value
    }

    // Vérification des données avant l'ajout
    const isDataNotEmpty = data.address === '' || data.city === ''
    if (isDataNotEmpty) {
      const messageNotAdd = {
        message: 'Les données nécessaires sont manquantes !',
        class: 'error',
        errors: [],
        address: 0
      }
      messageAddAddress.value = messageNotAdd
      return
    }

    try {
      messageAddAddress.value = await fetchPostAuthorization(url, data)
      if (messageAddAddress.value.class === 'success') {
        updateAddressData.value = true
        setTimeout(() => {
          messageAddAddress.value = messageAddAddressEmpty
          if (showAdressForm.value) {
            toggleShowAddressForm()
          }
        }, 5000)
      }
    } catch (e) {
      // console.error(e)
    }
  }

  return {
    h1,
    textButton,
    company,
    addressForm,
    supaddress,
    selectedRegion,
    selectedCity,
    optionRegion,
    optionCity,
    dropdownOpen,
    defaultAddress,
    selectedCountryId,
    selectedZoneId,
    selectedCityId,
    selectOptionRegion,
    selectOptionCity,
    toggleDropdown,
    updateCityOptions,
    updateCityOptionModify,
    updateSelectedCityId,
    addAddress
  }
}
