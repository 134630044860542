import { isAuthenticated } from '@/event/account/auth/authentification'
import { showNavAccountLogin } from '@/event/account/showNavAccountLogin'
import router from '@/router'
import stores from '@/storage/stores'
import { formatStoreName } from '../store/formatStoreName'

export function disconnect() {
  if (isAuthenticated.value === true) {
    showNavAccountLogin.value = !showNavAccountLogin.value
    isAuthenticated.value = !isAuthenticated.value

    const store = stores.getStoresData().name

    if (store) {
      const storeName = formatStoreName(store)
      // token = localStorage.getItem(`${storeName}_jwt`)
      localStorage.removeItem(`${storeName}_jwt`)
      localStorage.removeItem(`${storeName}_refresh_token`)
    }
    router.push('/')
  }
}
