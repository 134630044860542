import type { SelectedProduct } from '@/event/cartBus'
import { languageIdBus } from '@/event/languageIdBus'
import { productBus } from '@/event/product/productBus'
import { selectionsBus } from '@/event/product/selectionsBus'
import { getOptionName } from './getOptionName'
import { convertPriceToEuro } from '../price/convertPrice'
import { getOptionValueNameById } from './getOptionValueNameById'

export const getSelectedProducts = (): SelectedProduct[] => {
  const selectedProducts: SelectedProduct[] = []

  for (const [optionName, optionValues] of Object.entries(selectionsBus.value)) {
    let option
    if (productBus.value) {
      option = productBus.value.options.find(
        (opt: { language: any[] }) => getOptionName(opt, languageIdBus.value) === optionName
      )
    }

    if (option) {
      if (Array.isArray(optionValues)) {
        optionValues.forEach((valueId: number) => {
          const value = option.values.find(
            (val: { option_value_id: number }) => val.option_value_id === valueId
          )
          const price = convertPriceToEuro(value.ov_price)
          const optionProductName = getOptionValueNameById(valueId, languageIdBus.value) // Utiliser `getOptionNameById` pour obtenir le nom de l'option
          const optionName = getOptionName(option, languageIdBus.value)

          if (value && option.required) {
            if (!isNaN(price)) {
              if (option.po_sort_order === 1 && value.related_option_id) {
                selectedProducts.push({
                  optionName, // Ajouter le nom de l'option ici
                  productName: optionProductName,
                  valueId,
                  option_id: option.option_id,
                  price: value.ov_price,
                  sort_order: option.po_sort_order,
                  type: option.type,
                  title: option.title,
                  reset: value.reset,
                  product_option_id: option.product_option_id
                })
              } else if (
                selectionsBus.value[optionName].length > option.free_option_count &&
                option.max_option_count > 0
              ) {
                const numberAddOvPrice = option.free_option_count

                const freeItems = selectionsBus.value[optionName].slice(0, numberAddOvPrice)
                const pricedItems = selectionsBus.value[optionName].slice(numberAddOvPrice)

                if (freeItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName, // Utiliser la fonction pour récupérer le nom de l'élément
                    valueId,
                    option_id: option.option_id,
                    price: 0,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
                if (pricedItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: value.ov_price,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
              } else if (
                selectionsBus.value[optionName].length > option.free_option_count &&
                option.max_option_count === 0
              ) {
                const numberAddOvPrice = option.free_option_count

                // Divise les options sélectionnées entre gratuites (freeItems) et payantes (pricedItems)
                const freeItems = selectionsBus.value[optionName].slice(0, numberAddOvPrice)
                const pricedItems = selectionsBus.value[optionName].slice(numberAddOvPrice)

                // Si la valeur fait partie des items gratuits, ajoute-la avec un prix de 0
                if (freeItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName, // Nom de l'élément gratuit
                    valueId,
                    option_id: option.option_id,
                    price: 0, // Pas de prix car c'est gratuit
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }

                // Si la valeur fait partie des items payants, ajoute-la avec son prix
                if (pricedItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: value.ov_price, // Prix calculé pour les éléments payants
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
              } else {
                selectedProducts.push({
                  optionName, // Ajouter le nom de l'option ici
                  productName: optionProductName,
                  valueId,
                  option_id: option.option_id,
                  price: 0,
                  sort_order: option.po_sort_order,
                  type: option.type,
                  title: option.title,
                  reset: value.reset,
                  product_option_id: option.product_option_id
                })
              }
            }
          } else {
            if (!isNaN(price)) {
              selectedProducts.push({
                optionName, // Ajouter le nom de l'option ici
                productName: optionProductName,
                valueId,
                option_id: option.option_id,
                price: value.ov_price,
                sort_order: option.po_sort_order,
                type: option.type,
                title: option.title,
                reset: value.reset,
                product_option_id: option.product_option_id
              })
            }
          }
        })
      } else {
        const valueId = optionValues.valueId // Assign optionValues directly to valueId
        const optionProductName = getOptionValueNameById(valueId, languageIdBus.value)
        const value = option.values.find(
          (val: { option_value_id: number }) => val.option_value_id === valueId
        )

        selectedProducts.push({
          optionName: getOptionName(option, languageIdBus.value), // Add the name of the option
          productName: optionProductName, // Add the name of the option value
          valueId, // The valueId for this single value option
          option_id: option.option_id,
          price: 0, // Default price to 0 if not available
          sort_order: option.po_sort_order,
          type: option.type,
          title: option.title,
          reset: value ? value.reset : 0,
          product_option_id: option.product_option_id
        })
      }
    }
  }
  return selectedProducts
}
