<script setup lang="ts">
import { URL_BACK } from '@/config/constants'
import { orderData } from '@/event/order/data/orderData'
import customer from '@/storage/customer'
import orders from '@/storage/orders'
import stores from '@/storage/stores'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { fetchStripe } from '@/utils/stripe/fetchStripe'
import { onMounted, ref } from 'vue'
const props = defineProps({ finishStep: Boolean })
const counterSend = ref(0)
const hostname = window.location.hostname

const messageClickEmpty = {
  message: '',
  class: 'error'
}
const messageClick = ref(messageClickEmpty)

async function payment() {
  const urlAddOrder = 'order/add'
  if (counterSend.value === 0) {
    try {
      await fetchStripe(urlAddOrder, orderData.value, hostname)
      counterSend.value++
    } catch (error) {}

    const urlOrderFind = 'order/find'
    const data = {
      customer_id: customer.state.customerData.customer_id,
      store_id: stores.state.storeData.store_id
    }
    const response = await fetchPostAuthorization(urlOrderFind, data)
    if (response && response.order) {
      orders.setOrderData(response.order) // Mettez à jour le state avec les données des commandes
    }
  } else {
    const message = {
      message: 'La commande a bien été envoyer, veuillez patienter !',
      class: 'error'
    }
    messageClick.value = message
    setTimeout(() => {
      messageClick.value = messageClickEmpty
    }, 3000)
  }
}
</script>

<template>
  <section class="flex flex-col w-full items-center justify-center gap-2">
    <button
      :class="{ 'button-down': props.finishStep }"
      class="button-payment-order"
      @click="payment"
      :disabled="props.finishStep"
    >
      Payer {{ convertPriceToEuro(totalPrice) }} €
    </button>
    <p v-if="messageClick.message" :class="messageClick.class">{{ messageClick.message }}</p>
  </section>
</template>

<style scoped>
.error {
  @apply bg-red-eronor px-3 py-2 rounded-sm;
}
.button-payment-order {
  @apply bg-lime-eronor p-1 rounded-md font-semibold;
}
.button-down {
  @apply bg-slate-hover line-through;
}
</style>
