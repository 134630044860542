import { ref } from 'vue'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { URL_BACK } from '@/config/constants'
import { loadStripe } from '@stripe/stripe-js'
import stores from '@/storage/stores'
import { messageSend, messageSendEmpty } from '@/event/order/messageSend'
import { formatStoreName } from '../store/formatStoreName'

const publicKeyTest =
  'pk_test_51OoNEPGiP4cegitv4n2Ppfmsx6DXLslD5ilwhcb9YeWZ1eIahwjkuJES0l7nh1KWw4cvVC9liUpjIqW6j5IOjHoL00L1q9kA65'

const stripePromise = loadStripe(publicKeyTest)

export async function redirectToCheckout(sessionId: any) {
  const stripe = await stripePromise

  if (!stripe) {
    throw new Error('Stripe n’a pas pu être initialisé.')
  }

  // Rediriger vers Stripe Checkout
  return stripe.redirectToCheckout({ sessionId })
}

const csrfToken = ref()
// var stripe = Stripe('pk_test_oKhSR5nslBRnBZpjO6KuzZeX')

export async function fetchStripe(urlEnd: string, items: any, urlProject: string) {
  const store = stores.getStoresData().name
  let token

  if (store) {
    const storeName = formatStoreName(store)
    token = localStorage.getItem(`${storeName}_jwt`)
  }

  // const successUrl = 'http://localhost:8080/payment-success';
  // const cancelUrl = 'http://localhost:8080/payment-cancel';
  const url = `https://${URL_BACK}/api/${urlEnd}`

  const successUrl =
    urlProject == 'localhost'
      ? 'https://localhost:5173/success-payment'
      : `https://${urlProject}/success-payment`

  const cancelUrl =
    urlProject == 'localhost'
      ? 'https://localhost:5173/cancel-payment'
      : `https://${urlProject}/cancel-payment`

  try {
    await fetchCsrf(csrfToken)

    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify({
        // store_id: stores.state.storeData.store_id,
        items: items,
        success_url: successUrl,
        cancel_url: cancelUrl
      })
    })

    const data = await res.json()

    if (res.ok && data.id) {
      await redirectToCheckout(data.id)
    }

    messageSend.value = data
    setTimeout(() => {
      messageSend.value = messageSendEmpty
    }, 5000)
  } catch (e) {}
}
