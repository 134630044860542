import { computed, watch } from 'vue'
import { ref } from 'vue'
import stores from '@/storage/stores'

export const configEmail = computed(() => {
  const dataConfigEmailStorage = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'config_email'
  )

  return dataConfigEmailStorage?.value || ''
})
// watch(
//   () => stores.state.storeData,
//   () => {
//     const data: any = stores.state.storeData.setting
//     configEmailStorage.value =
//       data.find((setting: { key: string }) => setting.key === 'config_email').value || []
//   }
// )
