import { languageIdBus } from '@/event/languageIdBus'

export const getCategoryName = (category: { language: any[] | any }) => {
  if (Array.isArray(category.language)) {
    const language = category.language.find(
      (lang: { language_id: number }) => lang.language_id === languageIdBus.value
    )
    return language ? language.name : 'Nom non disponible'
  }

  return category.language?.language_id === languageIdBus.value
    ? category.language.name
    : 'Nom non disponible'
}
