import { cartBus } from '@/event/cartBus'
import { computed } from 'vue'

export const totalPrice = computed(() => {
  return cartBus.value
    .reduce((total, item) => {
      return total + item.total_price
    }, 0)
    .toFixed(2)
})
