<script setup lang="ts">
import { cartBus } from '@/event/cartBus'
import { computed, reactive, ref, watch } from 'vue'

const props = defineProps<{ totalPrice: string }>()

const taxGrouped = computed(() => {
  const taxGroups: Record<string, number> = {}

  // Itérer sur chaque produit du panier
  cartBus.value.forEach((product) => {
    const { rate_tax: rate, price_tax, quantity } = product

    // Vérifier si les informations essentielles sont présentes
    if (!rate || !price_tax || !quantity) {
      return
    }
    // Calculer le total des taxes par produit
    const totalTax = price_tax

    // Si le groupe de taxes n'existe pas encore, initialisez-le
    if (!taxGroups[rate]) {
      taxGroups[rate] = 0
    }

    // Ajouter le total des taxes au groupe existant
    taxGroups[rate] += totalTax
  })

  // Transformation pour le template
  return Object.entries(taxGroups).map(([rate, totalPriceTax]) => ({
    rate_tax: rate,
    total_price_tax: totalPriceTax
  }))
})

const totalTax = computed(() => {
  return taxGrouped.value.reduce((total, tax) => total + tax.total_price_tax, 0)
})

const subtotal = computed(() => {
  const totalPriceHT = Number(props.totalPrice) - totalTax.value
  return totalPriceHT.toFixed(2)
})

function getRateTaxString(rate: string) {
  const rateTax = parseFloat(rate)
  return Number.isInteger(rateTax) ? rateTax.toString() : parseFloat(rateTax.toFixed(2)).toString()
}

function getTotalTax(tax: any) {
  return Number.isInteger(tax) ? tax.toString() : parseFloat(tax.toFixed(2)).toString()
}
</script>

<template>
  <section class="section-basket-tax">
    <div class="div-basket-tax">
      <p class="text-basket-tax">Sous-total :</p>
      <p>{{ subtotal }} €</p>
    </div>
    <div class="div-basket-tax" v-for="(tax, index) in taxGrouped" :key="index">
      <p>TVA ({{ getRateTaxString(tax.rate_tax) }}%)</p>
      <p>{{ getTotalTax(tax.total_price_tax) }}</p>
    </div>
    <!-- <div class="div-basket-tax" v-if="priceTax10 !== 0">
      <p class="text-basket-tax">TVA (10%) :</p>
      <p>{{ props.priceTax10 }} €</p>
    </div> -->
    <div class="div-basket-tax">
      <p class="text-basket-tax">Total :</p>
      <p class="text-amber-5-eronor text-basket-tax">{{ totalPrice }} €</p>
    </div>
  </section>
</template>

<style scoped>
.section-basket-tax {
  @apply bg-slate-eronor mt-3 p-2 rounded-md min-w-60;
}
.div-basket-tax {
  @apply flex justify-between;
}
.text-basket-tax {
  @apply font-semibold;
}

@media screen and (min-width: 1000px) {
  .section-basket-tax {
    @apply mt-0;
  }
}
</style>
