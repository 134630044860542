<script setup lang="ts">
import stores from '@/storage/stores'
import { onMounted, ref, watch } from 'vue'
import { URL_IMAGE } from '@/config/constants'
import CatalogueCategory from '@/components/main/CatalogCategory.vue'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import SpinerLoader from '@/components/main/SpinerLoader.vue'
import { loadData } from '@/utils/loadData'
import { useRoute, useRouter } from 'vue-router'
import { watchersApp } from '@/utils/watchers/app/watchersApp'
import { useCarousel } from '@/utils/main/home/useCarousel'
import { cartBus } from '@/event/cartBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'

// const currentIndex = ref(0)
// const carouselInner = ref<HTMLElement | null>(null)
const timeInterval = 4000
let carouselInterval: ReturnType<typeof setInterval> | null = null
// const bannerData = ref<{ banner_image_id: number; title: string; image: string }[]>([])
const route = useRoute()

// function formatTime(num: number) {
//   return num < 10 ? '0' + num : num // Ajoutez un zéro devant les chiffres inférieurs à 10
// }

// function logCurrentTime() {
//   const now = new Date()
//   const hours = formatTime(now.getHours())
//   const minutes = formatTime(now.getMinutes())
//   const seconds = formatTime(now.getSeconds())
//   return `${hours}:${minutes}:${seconds}`
// }

// function addCarouselListeners() {
//   if (carouselInner.value) {
//     carouselInner.value.addEventListener('touchstart', touchStart)
//     carouselInner.value.addEventListener('touchmove', touchMove)
//     carouselInner.value.addEventListener('touchend', touchEnd)

//     carouselInner.value.addEventListener('mousedown', mouseDown)
//     carouselInner.value.addEventListener('mousemove', mouseMove)
//     carouselInner.value.addEventListener('mouseup', mouseUp)
//   }
// }

// watch(
//   () => currentIndex.value,
//   (newVal) => {
//     // console.log(newVal)
//   }
// )

// watch(
//   () => stores.state.storeData,
//   (newVal) => {
//     if (newVal.banner_images && newVal.banner_images.length > 0) {
//       bannerData.value = newVal.banner_images
//       startCarousel()
//     }
//   }
// )

// onMounted(() => {
//   if (
//     stores.state.storeData &&
//     stores.state.storeData.banner_images &&
//     stores.state.storeData.banner_images.length > 0
//   ) {
//     bannerData.value = stores.state.storeData.banner_images // Assurez-vous que les données sont bien assignées ici
//     startCarousel()
//   }

//   // addCarouselListeners()
// })

// function updateCarouselPosition() {
//   if (carouselInner.value) {
//     const offset = -currentIndex.value * carouselInner.value.clientWidth
//     carouselInner.value.style.transform = `translateX(${offset}px)`
//     carouselInner.value.style.transition = 'transform 0.5s ease-in-out'
//   }
// }

// function nextSlide() {
//   currentIndex.value = (currentIndex.value + 1) % bannerData.value.length
//   updateCarouselPosition()
// }

// function previousSlide() {
//   currentIndex.value = (currentIndex.value - 1 + bannerData.value.length) % bannerData.value.length
//   updateCarouselPosition()
// }

// function startCarousel() {
//   if (carouselInterval) clearInterval(carouselInterval)
//   carouselInterval = setInterval(nextSlide, timeInterval)
// }

// watch(
//   () => bannerData.value,
//   (newVal) => {
//     if (newVal && newVal.length > 0) {
//       startCarousel()
//     }
//   }
// )

// let startX = 0
// let endX = 0
// let isDragging = false

// function touchStart(event: TouchEvent) {
//   startX = event.touches[0].clientX
//   clearInterval(carouselInterval!)
//   isDragging = true
// }

// function touchMove(event: TouchEvent) {
//   if (!isDragging) return
//   endX = event.touches[0].clientX
// }

// function touchEnd() {
//   if (isDragging) {
//     if (startX - endX > 50) {
//       nextSlide() // Glissement à gauche
//     } else if (endX - startX > 50) {
//       previousSlide() // Glissement à droite
//     }
//     startCarousel() // Redémarre le carrousel
//     isDragging = false // Fin du glissement
//   }
// }

// function mouseDown(event: MouseEvent) {
//   startX = event.clientX
//   clearInterval(carouselInterval!)
//   isDragging = true
// }

// function mouseMove(event: MouseEvent) {
//   if (!isDragging) return
//   endX = event.clientX
// }

// function mouseUp() {
//   if (isDragging) {
//     if (startX - endX > 50) {
//       nextSlide() // Glissement à gauche
//     } else if (endX - startX > 50) {
//       previousSlide() // Glissement à droite
//     }
//     startCarousel() // Redémarre le carrousel
//     isDragging = false // Fin du glissement
//   }
// }

const {
  currentIndex,
  bannerData,
  carouselInner,
  nextSlide,
  previousSlide,
  startCarousel,
  touchStart,
  touchMove,
  touchEnd,
  mouseDown,
  mouseMove,
  mouseUp,
  updateCarouselPosition
} = useCarousel()

const toggleDisplay = () => {
  isNavbarVisible.value = true
  if (cartBus.value.length > 0) {
    sectionOrderBus.value = true
  }
}

// watchersApp(route)
</script>

<template>
  <div class="w-full">
    <section :class="{ section: isNavbarVisible, hidden: isNavbarVisible }">
      <div class="carousel" v-if="bannerData.length > 0">
        <div class="carousel-inner" ref="carouselInner">
          <div
            v-for="(image, index) in bannerData"
            :key="index"
            class="carousel-item"
            :class="{ active: currentIndex === index }"
            @touchstart="touchStart"
            @touchmove="touchMove"
            @touchend="touchEnd"
            @mousedown="mouseDown"
            @mousemove="mouseMove"
            @mouseup="mouseUp"
          >
            <img :src="`${URL_IMAGE}${image.image}`" :alt="image.title" class="carousel-image" />
          </div>
        </div>
        <div class="carousel-btn">
          <button class="btn-order" @click="toggleDisplay">Commander</button>
        </div>
        <div class="carousel-dots">
          <div v-for="(image, index) in bannerData" :key="image.title" class="dot-container">
            <div class="dot-white"></div>
            <span
              class="dot"
              :class="{ active: currentIndex === index }"
              @click="(currentIndex = index), updateCarouselPosition()"
            ></span>
          </div>
        </div>
      </div>
      <div v-else><SpinerLoader /></div>
    </section>
    <section class="section-mobile-first" :class="{ show: isNavbarVisible }">
      <CatalogueCategory class="catalog-category" />
    </section>
  </div>
</template>

<style scoped>
.catalog-category {
  @apply mx-2 w-full;
}

.section {
  @apply hidden;
}

.section-mobile-first {
  @apply hidden;
}

.show {
  @apply flex justify-center;
}

/* carousel */
.carousel {
  @apply mt-16;
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: 0.5s ease-in-out;
}

.carousel-item {
  flex: 0 0 100%;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-dots {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.dot-container {
  position: relative;
  margin: 0 10px;
}

.dot-white {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  position: absolute; /* Assurez-vous qu'il est superposé au point blanc */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centrer le point noir sur le blanc */
  z-index: 2; /* Positionner devant le point blanc */
}

.dot.active {
  background-color: gray;
}

.carousel-btn {
  @apply flex flex-col items-center absolute z-30 bottom-32 w-full;
}

.btn-order {
  @apply bg-lime-eronor py-3 px-8 rounded-full absolute z-30 mx-auto border-2 border-white;
  /* bottom: 4rem; */
}

@media screen and (min-width: 400px) {
  .carousel {
    @apply mt-8;
  }
}
@media screen and (min-width: 400px) {
  .carousel {
    @apply mt-0;
  }
}

@media screen and (min-width: 800px) {
  .carousel {
    @apply hidden;
  }

  .section-mobile-first {
    @apply flex justify-center;
  }

  .catalog-category {
    @apply mx-4;
  }
}

@media screen and (min-width: 1020px) {
  .catalog-category {
    @apply mx-8;
  }
  .section-mobile-first {
    @apply flex justify-center;
  }
}
@media screen and (min-width: 1200px) {
  /* .section-mobile-first {
    @apply w-[70rem];
  } */
}

@media screen and (min-width: 1500px) {
  .catalog-category {
    @apply mx-8;
  }
}
</style>
