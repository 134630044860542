<script setup lang="ts">
import { orderGet } from '@/storage/order/orderGet'
import orders from '@/storage/orders'
import { computed, ref } from 'vue'

const visibleCount = ref(5)

// const orderGet = computed(() => {
//   return orders.state.orderData
//     .map((order) => {
//       // Formatage de la date_added
//       const dateAdded = order.date_added?.date ? new Date(order.date_added.date) : null
//       const dateModified = order.date_modified?.date ? new Date(order.date_modified.date) : null
//       const orderDate = order.order_date?.date ? new Date(order.order_date.date) : null

//       // Formater date_added et date_modified pour afficher année, mois, jour, heure et minute
//       const formatDate = (date: Date | null) => {
//         if (!date) return ''
//         const year = date.getFullYear()
//         const month = String(date.getMonth() + 1).padStart(2, '0') // Mois de 01 à 12
//         const day = String(date.getDate()).padStart(2, '0') // Jour de 01 à 31
//         const hour = String(date.getHours()).padStart(2, '0') // Heure de 00 à 23
//         const minute = String(date.getMinutes()).padStart(2, '0') // Minute de 00 à 59
//         return `${year}/${month}/${day} ${hour}h${minute}`
//       }

//       // Formater order_date pour afficher seulement année, mois et jour
//       const formatOrderDate = (date: Date | null) => {
//         if (!date) return ''
//         const year = date.getFullYear()
//         const month = String(date.getMonth() + 1).padStart(2, '0') // Mois de 01 à 12
//         const day = String(date.getDate()).padStart(2, '0') // Jour de 01 à 31
//         return `${year}/${month}/${day}`
//       }

//       const isPaid = (order: any) => {
//         // Vérification si total_NKT, total_TIC, ou total_CC sont non vides et égaux à total
//         const total = parseFloat(order.total)
//         const totalNKT = parseFloat(order.total_NKT)
//         const totalCC = parseFloat(order.total_CC)
//         const totalTIC = parseFloat(order.total_TIC)

//         // Si l'un des totals est égal au total de la commande, on considère comme "réglé"
//         if (
//           (totalNKT > 0 && total === totalNKT) ||
//           (totalCC > 0 && total === totalCC) ||
//           (totalTIC > 0 && total === totalTIC)
//         ) {
//           return 'Réglé'
//         } else {
//           return 'Non réglé'
//         }
//       }

//       return {
//         ...order,
//         total: parseFloat(order.total),
//         date_added: formatDate(dateAdded),
//         date_modified: formatDate(dateModified),
//         order_date: formatOrderDate(orderDate),
//         raw_date_added: dateAdded,
//         payment_status: isPaid(order)
//       }
//     })
//     .sort((a, b) => {
//       // Trier par date_added (raw_date_added) du plus récent au plus ancien
//       const dateA = a.raw_date_added ? a.raw_date_added.getTime() : 0
//       const dateB = b.raw_date_added ? b.raw_date_added.getTime() : 0
//       return dateB - dateA
//     })
// })

const visibleOrders = computed(() => {
  return orderGet.value.slice(0, visibleCount.value) // Limiter les commandes visibles
})

const loadMoreOrders = () => {
  visibleCount.value += 5 // Augmenter de 5 à chaque clic
}

const numberTest = 10
</script>

<template>
  <section class="section-history">
    <div v-if="visibleOrders.length > 0" class="w-full p-1 flex flex-col gap-2">
      <!-- <div class="table-header">
        <div class="header-cell">N° de commande</div>
        <div class="header-cell">date</div>
        <div class="header-cell">Méthode de paiement</div>
        <div class="header-cell">Retrait</div>
        <div class="header-cell">Total</div>
      </div> -->

      <div v-for="(o, i) in visibleOrders" :key="i" class="div-order-for">
        <!-- {{ o }} -->
        <div class="flex justify-between">
          <div class="flex gap-2 font-semibold">
            <p>N°</p>
            <p class="table-cell">{{ o.order_id }}</p>
          </div>

          <p class="table-cell">{{ o.order_date }}</p>
        </div>

        <div class="flex justify-between">
          <div>
            <!-- <p>Paiement:</p> -->
            <p class="table-cell">{{ o.payment_method }}</p>
          </div>
          <div>
            <!-- <p>Retrait:</p> -->
            <p class="table-cell">{{ o.shipping_method }}</p>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div class="flex gap-2">
            <p>Total:</p>
            <p class="table-cell font-semibold text-lime-eronor">{{ o.total }} €</p>
          </div>
          <div>
            <p v-if="o.payment_status == 'Réglé'" class="bg-lime-eronor px-2 py-1 rounded-full">
              {{ o.payment_status }}
            </p>
            <p v-else class="bg-red-eronor px-2 py-1 rounded-full">{{ o.payment_status }}</p>
          </div>
        </div>
      </div>
      <button
        v-if="visibleOrders.length < orderGet.length"
        @click="loadMoreOrders"
        class="bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white text-white px-4 py-2 rounded-full mt-4 self-center"
      >
        Afficher plus
      </button>
    </div>
    <div v-else class="pt-5">L'historique de commandes est vide</div>
  </section>
</template>

<style scoped>
.section-history {
  @apply bg-slate-eronor min-h-96 p-2 rounded-md flex flex-col items-center;
}
.div-order-for {
  @apply w-full flex flex-col border p-2 rounded-md gap-2;
}
</style>
