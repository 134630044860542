<script setup lang="ts">
//certificates.dev/javascript?utm_source=vuejs&utm_medium=website&utm_campaign=affiliate&utm_content=top_banner&banner_type=top&friend=VUEJS
import InputQuantity from '@/components/form/InputQuantity.vue'
import ButtonBasket from '@/components/button/basket/ButtonBasket.vue'
import { URL_IMAGE } from '@/config/constants'
import products, { type Option, type Product, type ValueOption } from '@/storage/products'
import stores from '@/storage/stores'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { cartBus, indexUpdateElement, type SelectedProduct } from '@/event/cartBus'
// import { getProductName, getProductDescription } from '@/utils/product/hasDescriptionForLanguage'
import { getOptionValueName } from '@/utils/product/getOptionValueName'
import { getCategoryName } from '@/utils/category/category'
import {
  addBasketAlert,
  alertElement,
  alertMessage,
  alertWarning,
  alertWarningMessage,
  closeAlert,
  closeSuccess,
  closeWarning
} from '@/utils/product/alert'
import { closeImage, isImageOpen, openImage, selectedImage } from '@/utils/product/image'
import ButtonCloseProduct from '@/components/button/ButtonCloseProduct.vue'
import ButtonValidate from '@/components/button/ButtonValidate.vue'
import { languageIdBus } from '@/event/languageIdBus'
import ButtonClearSelection from '@/components/button/product/ButtonClearSelection.vue'
import { buttonNextOption, goToNextOptionFunction } from '@/utils/product/buttonNextOption'
import ButtonNext from '@/components/button/product/ButtonNext.vue'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import IconPlaceholder from '@/components/icons/iconEronor/IconPlaceholder.vue'
import { showCategoryBus } from '@/event/category/showCategoryBus'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import Ariane from '@/components/ariane/Ariane.vue'
import { productBus } from '@/event/product/productBus'
import { productIdBus } from '@/event/product/productIdBus'
import { getOptionName } from '@/utils/product/getOptionName'
import { getProductName } from '@/utils/product/getProductName'
import { getProductDescription } from '@/utils/product/getProductDescription'
import { getElementFromCart } from '@/utils/product/getElementFromCart'
import { clearSelection } from '@/utils/product/clearSelection'
import { updateValues } from '@/utils/product/updateValues'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import { quantityBus } from '@/event/product/quantityBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { showNextButtonBus } from '@/event/product/showNextButtonBus'
import { handleScroll } from '@/utils/product/handleScroll'
import { addElementBasketBus } from '@/event/product/addElementBasketBus'
import { hasDescriptionForLanguage } from '@/utils/product/hasDescriptionForLanguage'
import { convertFinalPrice } from '@/utils/price/convertFinalPrice'
import { updateParentQuantity } from '@/utils/product/updateParentQuantity'
import { isSelected } from '@/utils/product/isSelected'
import { handleCheckboxClick } from '@/utils/product/handleCheckboxClick'
import { handleRadioClick } from '@/utils/product/handleRadioClick'
import { formatPrice } from '@/utils/product/formatPrice'
import { addToCart } from '@/utils/product/addToCart'
import { showBasketLogoBus } from '@/event/product/showBasketBus'
import { goToNextOption } from '@/utils/product/goToNextOption'
import { validateWarning } from '@/utils/product/validateWarning'
import { priceBasketBus } from '@/event/product/priceBasketBus'
import AlertProductSuccess from '@/components/product/AlertProductSuccess.vue'
import type { GroupedProducts } from '@/types/product/GroupedProductsInterface'
import { watchersProduct } from '@/utils/watchers/product/watchersProduct'

// Route parameters
const route = useRoute()

watchersProduct(route)
// interface GroupedProducts {
//   [optionName: string]: any[] // Définissez un type approprié si vous savez quel type d'éléments contiendra le tableau
// }

// watch(() => cartBus.value, clearSelection, { deep: true })
// watch(
//   () => route.params,
//   () => {
//     updateValues(route)
//   }
// )
// watch(
//   () => indexUpdateElement.value,
//   (newValue) => {
//     clearSelection()
//     if (newValue >= 0) updateValues(route)
//   }
// )
// watch(
//   () => productBus.value,
//   () => {
//     if (productBus.value) {
//       buttonNextOption(productBus.value.options, showNextButtonBus)
//     }
//   }
// )
// watch(
//   () => relatedGroupBus.value,
//   () => {
//     if (productBus.value) {
//       buttonNextOption(productBus.value.options, showNextButtonBus)
//     }
//   },
//   { deep: true }
// )
// watch(
//   () => products.state.productData,
//   (newValue) => {
//     updateValues(route)
//   },
//   { deep: true }
// )

updateValues(route)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)

  if (productBus.value) {
    buttonNextOption(productBus.value.options, showNextButtonBus)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

// const groupedProducts = computed<GroupedProducts>(() => {
//   return addElementBasketBus.value
//     .sort((a, b) => a.sort_order - b.sort_order)
//     .reduce((acc: GroupedProducts, item) => {
//       if (!acc[item.optionName]) {
//         acc[item.optionName] = []
//       }
//       acc[item.optionName].push(item.productName) // Poussez le productName ou l'élément complet selon votre besoin
//       return acc
//     }, {} as GroupedProducts) // Assurez-vous que l'accumulateur est du bon type
// })

// addBasketAlert.value = 'Ajouter au panier'
</script>

<template>
  <div class="product-option">
    <Ariane />

    <section class="section-product">
      <div v-if="productBus" class="product-layout">
        <button class="thumbnails" @click.prevent="openImage(productBus.image)">
          <img :src="`${URL_IMAGE}${productBus.image}`" width="" alt="" />
        </button>
        <div class="div-section-product">
          <div class="div-info">
            <h1>{{ getProductName(productBus) }}</h1>
            <p
              class="text-info"
              v-if="hasDescriptionForLanguage(productBus.description, languageIdBus)"
            >
              {{ getProductDescription(productBus) }}
            </p>
            <p class="border-b"></p>
            <div class="flex items-center justify-between pr-2">
              <p>Prix: {{ convertFinalPrice(productBus.price) }} €</p>
              <ButtonClearSelection @click="clearSelection" />
            </div>
          </div>

          <div v-if="isImageOpen" class="image-overlay" @click="closeImage">
            <div class="image-container" @click.stop>
              <button class="close-btn" @click="closeImage">X</button>
              <img :src="`${URL_IMAGE}${selectedImage}`" class="zoom-image" />
            </div>
          </div>

          <div v-if="productBus" class="catalog-option">
            <div>
              <InputQuantity @update:quantity="updateParentQuantity" :quantity="quantityBus" />
            </div>

            <h3 v-if="productBus.options.length > 0" class="text-option">Options disponibles</h3>
            <div v-for="option in productBus.options" :key="option.option_id" class="">
              <div v-if="!relatedGroupBus.includes(option.option_id)">
                <h4
                  :id="getOptionName(option, languageIdBus)"
                  v-if="option.required"
                  class="flex items-center h4-option flex-wrap"
                >
                  <p class="">{{ getOptionName(option, languageIdBus) }}</p>

                  <p
                    class="choice"
                    v-if="option.free_option_count > 0 && option.max_option_count == 0"
                  >
                    <span class="choice">*</span> ({{ option.free_option_count + ' choix' }})
                  </p>
                  <p v-else-if="option.max_option_count > 0" class="choice">
                    <span class="choice">*</span>({{ option.free_option_count }} choix gratuit +
                    {{ option.max_option_count - option.free_option_count + ' choix maximum' }})
                  </p>
                </h4>

                <h4 v-else :id="getOptionName(option, languageIdBus)" class="h4-option">
                  {{ getOptionName(option, languageIdBus) }}
                </h4>
                <div :id="`div-${getOptionName(option, languageIdBus)}`" class="option-item">
                  <div
                    v-for="value in option.values"
                    :key="value.option_value_id"
                    class="item-wrapper"
                  >
                    <input
                      v-if="option.type == 'select'"
                      type="checkbox"
                      class="item-input"
                      :name="`radio-group-${option.option_id}`"
                      :id="`radio-${value.option_value_id}`"
                      :checked="isSelected(value.option_value_id, option)"
                      @click="handleCheckboxClick(value, option)"
                    />
                    <input
                      v-else
                      type="radio"
                      class="item-input"
                      :name="`radio-group-${option.option_id}`"
                      :id="`radio-${value.option_value_id}`"
                      :checked="isSelected(value.option_value_id, option)"
                      @click="handleRadioClick(value, option)"
                    />

                    <p
                      v-if="
                        extraPricesBus[getOptionName(option, languageIdBus)] &&
                        extraPricesBus[getOptionName(option, languageIdBus)][
                          value.option_value_id
                        ] > 0
                      "
                      class="banner-price"
                    >
                      {{
                        '+ ' +
                        formatPrice(
                          extraPricesBus[getOptionName(option, languageIdBus)][
                            value.option_value_id
                          ]
                        )
                      }}
                    </p>

                    <img
                      class="item"
                      v-if="value.image"
                      :src="`${URL_IMAGE}${value.image}`"
                      width=""
                      :alt="getOptionValueName(value.option_value_id, languageIdBus, productBus)"
                      :class="{
                        'item-white': !isSelected(value.option_value_id, option),
                        'item-lime': isSelected(value.option_value_id, option)
                      }"
                    />

                    <div class="text">
                      <p>
                        {{ getOptionValueName(value.option_value_id, languageIdBus, productBus) }}
                      </p>
                      <!-- <p>Sort Order: {{ value.sort_order }}</p> -->
                      <!-- <p>Prix: {{ convertPriceToEuro(value.ov_price) }} €</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div v-if="" class="alert-overlay"></div> -->
            <div
              v-if="addBasketAlert"
              class="w-full flex bg-lime-eronor rounded-md py-1 px-2 mr-8 justify-center"
            >
              <p class="">{{ addBasketAlert }}</p>
            </div>
            <div class="relative flex items-center justify-between">
              <p class="text-lg font-semibold">Prix: {{ convertFinalPrice(productBus.price) }} €</p>
              <ButtonBasket @click="addToCart" id="button-basket" />

              <div
                v-if="showBasketLogoBus"
                class="basket-logo animated"
                :class="{ 'animate-up': showBasketLogoBus }"
              >
                <svg
                  class="fill-white stroke-white w-8"
                  viewBox="0 0 512 440"
                  xmlns="http://www.w3.org/2000/svg"
                  data-v-inspector="src/components/main/Navbar.vue:171:11"
                  data-v-b3401b07=""
                >
                  <path
                    d="M256 219.989C261.52 219.989 266 215.508 266 209.989C266 204.469 261.52 199.989 256 199.989C250.48 199.989 246 204.469 246 209.989C246 215.508 250.48 219.989 256 219.989Z"
                    data-v-inspector="src/components/main/Navbar.vue:172:13"
                    data-v-b3401b07=""
                  ></path>
                  <path
                    d="M472 139.989H412.863L321.895 14.8364C313.723 0.832482 295.723 -4.15189 281.426 3.76608C266.934 11.8169 261.723 30.0708 269.777 44.5669C270.008 44.977 270.262 45.3715 270.547 45.7465L341.898 139.989H170.102L241.453 45.7465C241.734 45.3715 241.992 44.977 242.223 44.5669C250.258 30.1059 245.105 11.8364 230.562 3.75826C216.297 -4.14408 198.297 0.83639 190.109 14.8286L99.1367 139.989H40C17.9453 139.989 0 157.934 0 179.989C0 197.383 11.2891 212.528 27.1914 217.887C28.8867 219.2 31.0039 219.989 33.3086 219.989C33.7695 219.989 34.2031 220.016 34.6562 220.079C38.9609 220.657 42.3711 223.922 43.1523 228.196L77.1719 415.36C79.7695 429.629 92.1836 439.989 106.691 439.989H405.309C419.816 439.989 432.23 429.629 434.828 415.356L468.848 228.2C469.629 223.922 473.043 220.657 477.363 220.079C477.801 220.016 478.234 219.989 478.691 219.989C481.012 219.989 483.145 219.192 484.84 217.864C500.754 212.469 512 197.352 512 179.989C512 157.934 494.055 139.989 472 139.989ZM286.988 34.3286C284.703 29.5981 286.477 23.8364 291.125 21.2583C295.965 18.5747 302.066 20.3051 304.734 25.1137C304.93 25.4731 305.152 25.8169 305.391 26.145L388.137 139.989H366.98L286.988 34.3286ZM206.609 26.1489C206.848 25.8208 207.062 25.4809 207.262 25.1294C209.937 20.3169 216.043 18.5825 220.863 21.2505C225.52 23.8364 227.301 29.5903 225.012 34.3286L145.02 139.989H123.863L206.609 26.1489ZM472 199.989H296C290.477 199.989 286 204.465 286 209.989C286 215.512 290.477 219.887 296 219.887H450.398C449.875 221.379 449.461 222.926 449.172 224.52L415.148 411.778C414.285 416.536 410.145 419.989 405.309 419.989H106.691C101.852 419.989 97.7148 416.536 96.8477 411.782L62.8281 224.618C62.5391 223.024 62.125 221.477 61.6016 219.989H216C221.523 219.989 226 215.512 226 209.989C226 204.465 221.523 199.989 216 199.989H40C28.8789 199.989 20 190.926 20 179.989C20 168.961 28.9727 159.989 40 159.989H472C483.027 159.989 492 168.961 492 179.989C492 191.094 482.914 199.989 472 199.989Z"
                    data-v-inspector="src/components/main/Navbar.vue:175:13"
                    data-v-b3401b07=""
                  ></path>
                  <path
                    d="M256 249.989C239.457 249.989 226 263.446 226 279.989V359.989C226 376.532 239.457 389.989 256 389.989C272.543 389.989 286 376.532 286 359.989V279.989C286 263.415 272.574 249.989 256 249.989ZM266 359.989C266 365.504 261.516 369.989 256 369.989C250.484 369.989 246 365.504 246 359.989V279.989C246 274.473 250.484 269.989 256 269.989C261.52 269.989 266 274.469 266 279.989V359.989Z"
                    data-v-inspector="src/components/main/Navbar.vue:178:13"
                    data-v-b3401b07=""
                  ></path>
                  <path
                    d="M356 389.989C372.543 389.989 386 376.532 386 359.989V279.989C386 263.415 372.574 249.989 356 249.989C339.457 249.989 326 263.446 326 279.989V359.989C326 376.532 339.457 389.989 356 389.989ZM346 279.989C346 274.473 350.484 269.989 356 269.989C361.52 269.989 366 274.469 366 279.989V359.989C366 365.504 361.516 369.989 356 369.989C350.484 369.989 346 365.504 346 359.989V279.989Z"
                    data-v-inspector="src/components/main/Navbar.vue:181:13"
                    data-v-b3401b07=""
                  ></path>
                  <path
                    d="M156 249.989C139.457 249.989 126 263.446 126 279.989V359.989C126 376.532 139.457 389.989 156 389.989C172.543 389.989 186 376.532 186 359.989V279.989C186 263.415 172.574 249.989 156 249.989ZM166 359.989C166 365.504 161.516 369.989 156 369.989C150.484 369.989 146 365.504 146 359.989V279.989C146 274.473 150.484 269.989 156 269.989C161.52 269.989 166 274.465 166 279.989V359.989Z"
                    data-v-inspector="src/components/main/Navbar.vue:184:13"
                    data-v-b3401b07=""
                  ></path>
                </svg>
              </div>
              <ButtonNext v-if="showNextButtonBus" @click="goToNextOption()" />
              <!-- <button v-if="showNextButton" @click="goToNextOption()" class="next-button">
                Suivant
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="thumbnails-placeholder">
          <IconPlaceholder />
        </div>
      </div>

      <div class="div-alert">
        <!-- <div v-if="addBasketAlert" class="alert-overlay"></div> -->
        <div v-if="alertMessage || alertWarning" class="alert-overlay"></div>
        <div v-if="alertWarning" class="alert-warning">
          <p class="text-red-eronor">Si vous validez, la sélection sera ajoutée au panier !</p>
          <div v-for="(e, i) of alertWarningMessage" :key="i">{{ e }}</div>
          <div class="div-warning-button">
            <ButtonCloseProduct class="button-warning" :function="closeWarning" />
            <ButtonValidate class="button-warning" :function="validateWarning" />
          </div>
        </div>

        <!-- <AlertProductSuccess v-if="addBasketAlert" :groupedProducts="groupedProducts" /> -->

        <div v-if="alertMessage" class="alert">
          {{ alertMessage }}
          <p v-for="(element, key) of alertElement" :key="key">{{ element }}</p>
          <ButtonCloseProduct :function="closeAlert" />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.basket-logo {
  @apply z-30 absolute right-4 bottom-4;
}

.basket-logo.animated {
}
.animate-up {
  animation: slide-up 0.5s ease-in-out;
}
@keyframes slide-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}
* .btn-add-cart {
  @apply bg-slate-eronor px-3 py-2 rounded-lg hover:bg-slate-hover text-white-eronor;
}
.btn-cancel-cart {
  @apply bg-slate-eronor px-3 py-2 rounded-lg hover:bg-slate-hover text-white-eronor;
}

.div-alert {
  @apply flex justify-center;
}

.alert-overlay {
  @apply fixed top-0 left-0 w-full h-screen flex content-center items-center z-40 opacity-100;

  background-color: rgba(0, 0, 0, 0.5); /* Fond noir semi-transparent */
}

.alert-warning {
  @apply flex flex-col items-center justify-between gap-2 fixed min-h-28 max-w-[22rem]  top-20 bg-amber-eronor p-4 rounded-xl z-50 text-slate-eronor;
}
.alert-warning p {
  @apply text-red-eronor p-1;
}
.div-warning-button {
  @apply w-full flex justify-between pt-3 px-4;
}
/* .alert-warning p {
  @apply p-1;
} */
.button-warning {
  @apply bg-amber-5-eronor p-2 rounded-md shadow-xl hover:bg-amber-hover;
}

/* .success-alert {
  @apply fixed z-50 flex flex-col items-center min-w-[20rem] gap-1 bg-lime-eronor top-20 p-3 rounded-lg;
} */
/* .success-alert button {
  @apply bg-limedark-eronor rounded-3xl px-4 py-2 hover:bg-lime-hover shadow-xl;
} */
/* .div-success {
  @apply flex flex-nowrap flex-col items-center;
} */
/* .div-success h3 {
  @apply text-slate-hover;
} */
/* .div-button-validate {
  @apply flex justify-center pt-3;
} */

.alert {
  @apply flex flex-col items-center gap-1 fixed min-h-36 max-w-md  top-20 bg-red-eronor p-7 rounded-xl z-50;
}

.alert button {
  @apply bg-reddark-eronor py-2 px-4 rounded-lg mt-4;
}
.alert button:hover {
  @apply bg-red-hover-eronor;
}
.alert p {
  @apply text-amber-eronor;
}

/* option-product  */
.choice {
  @apply text-red-eronor pl-1 text-sm;
}

.h4-option {
  @apply flex items-center uppercase font-medium ml-3 py-1;
}
.text-option {
  @apply text-xl ml-2 underline;
}

.banner-price {
  @apply absolute bg-red-eronor z-10 top-1 right-0 px-1 ps-4 pe-1 text-center text-sm font-medium;
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 16% 50%, 0 0);
}

.item-input {
  @apply z-20;
}
.item-wrapper {
  @apply relative inline-block;
}
.item-wrapper:hover .item {
  @apply bg-limedark-eronor; /* Assurez-vous que le hover est appliqué à l'élément item */
}

.item-wrapper input[type='checkbox'],
.item-wrapper input[type='radio'] {
  @apply absolute top-0 left-0 w-full h-full;
  opacity: 0; /* Rend l'input invisible mais toujours cliquable */
  cursor: pointer;
}

.item-wrapper img {
  @apply block w-full;
}

.item {
  @apply rounded-md flex flex-col min-h-[5.5rem] items-center justify-center p-2 relative;
  object-fit: contain;
}

.item-white {
  @apply bg-white-eronor;
}

.item-lime {
  @apply bg-lime-eronor;
}

.item img {
  @apply w-full h-auto;
  object-fit: cover;
}

.text {
  @apply mt-2 text-sm  text-center;
}

.option-item {
  @apply my-2 grid grid-cols-3 gap-3 items-start justify-items-center;
}

.catalog-option {
  @apply bg-slate-eronor rounded-md px-2 py-3;
}

.product-option {
  @apply px-3 pb-5 w-full;
}

@media screen and (min-width: 360px) {
  .option-item {
    @apply grid-cols-4;
  }
  .item-wrapper {
    @apply min-h-20;
  }
}
@media screen and (min-width: 400px) {
  .item {
    @apply min-h-24;
  }
}
@media screen and (min-width: 480px) {
  .item {
    @apply min-h-28;
  }
}
@media screen and (min-width: 560px) {
  .item {
    @apply min-h-32;
  }
  .item .text {
    @apply text-lg;
  }
}
@media screen and (min-width: 650px) {
  .option-item {
    @apply grid-cols-5;
  }
  .item {
    @apply min-h-32;
  }
}
@media screen and (min-width: 800px) {
  .item {
    @apply min-h-36;
  }
  .product-option {
    @apply mx-4;
  }
}
@media screen and (min-width: 900px) {
  .item {
    @apply min-h-36;
  }

  .option-item {
    @apply grid-cols-6;
  }
}
@media screen and (min-width: 1020px) {
  /* .item {
    @apply min-h-40;
  } */
  .product-option {
    @apply mx-8;
  }
}
@media screen and (min-width: 1100px) {
  /* .item {
    @apply min-h-44;
  } */
}
@media screen and (min-width: 1200px) {
  .div-section-product {
    @apply w-full;
  }
  .item {
    @apply min-h-32;
  }
  .div-info {
    @apply w-full;
  }
  .option-item {
    @apply grid-cols-4;
  }
}
/* @media screen and (min-width: 1400px) {
  .option-item {
    @apply grid-cols-5;
  }
} */

@media screen and (min-width: 1500px) {
  .product-option {
    @apply mx-5 container;
  }
  .option-item {
    @apply grid-cols-5;
  }
  .item {
    @apply min-h-32;
  }
}

/* product */
.image-overlay img {
  @apply bg-slate-eronor rounded-md;
}

.image-overlay {
  @apply flex items-center justify-center fixed top-0 left-0 w-full h-full z-50;
  background: rgba(0, 0, 0, 0.8);
}

.image-container {
  @apply relative max-w-full max-h-full m-2;
}

.zoom-image {
  @apply w-full h-auto;
}

.close-btn {
  @apply absolute bg-slate-eronor top-1 right-1 text-lg py-3 px-4;
}

.close-btn:hover {
  @apply bg-lime-eronor;
}
.thumbnails {
  @apply relative flex flex-col items-center justify-center bg-slate-eronor rounded-md min-h-52 w-full;
}
.thumbnails-placeholder {
  @apply flex flex-col items-center bg-slate-eronor rounded-md max-h-80 w-full;
}

.div-info {
  @apply flex flex-col my-1 py-1 gap-2 font-normal text-2xl;
}
.text-info {
  @apply font-thin;
}
.product-layout {
  @apply flex flex-col;
}

@media screen and (min-width: 1200px) {
  .product-layout {
    @apply flex-row flex-nowrap gap-4;
  }
  .thumbnails {
    @apply max-h-80 max-w-[28rem];
  }
  .thumbnails img {
    @apply overflow-hidden;
  }
}

/* fil-ariane */

.fil {
  @apply border-s ps-3;
}
.fil:hover {
  @apply text-lime-eronor;
}
.ariane-div {
  @apply w-full bg-slate-eronor rounded-md flex items-center gap-3 px-3 py-2 mb-3 mt-3;
}

.svg-home {
  @apply w-6 fill-white-eronor;
}
.svg-home:hover {
  @apply fill-lime-eronor;
}
/* layout */
/* @media screen and (min-width: 320px) {
  .thumbnails {
    @apply min-h-64;
  }
} */
@media screen and (min-width: 800px) {
  /* .alert {
    @apply right-8;
  } */
}
</style>
