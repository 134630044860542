<script setup lang="ts">
import { ref } from 'vue'

const hasTransaction = ref(false)
</script>

<template>
  <section class="section-trasaction">
    <div v-if="hasTransaction"></div>
    <div v-else>Aucunes Transactions effectuées</div>
  </section>
</template>

<style scoped>
.section-trasaction {
  @apply min-h-96 bg-slate-eronor rounded-md p-2 flex flex-col items-center;
}
</style>
