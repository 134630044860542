<script setup lang="ts">
import IconSuccessRounded from '@/components/icons/iconEronor/IconSuccessRounded.vue'
import IconWarning from '@/components/icons/iconEronor/IconWarning.vue'
import { URL_BACK } from '@/config/constants'
import { idTimeout } from '@/event/account/idTimeout'
import { messageConfirmRegister } from '@/event/account/register/messageConfirmRegister'
import stores from '@/storage/stores'
import { getUserConfirmRegister } from '@/utils/account/register/getUserConfirmRegister'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const token = ref()
const email = ref()
const storeId = ref()
const csrfToken = ref()
const emailValid = ref(false)
const emailInvalide = ref(false)
const configTelephone = ref()
const configEmail = ref()
// const messageConfirmRegister = ref({
//   message: '',
//   class: '',
//   errors: []
// })

watch(
  () => stores.state.storeData,
  () => {
    const data: any = stores.state.storeData.setting
    configEmail.value = data.find(
      (setting: { key: string }) => setting.key === 'config_email'
    ).value
    configTelephone.value = data.find(
      (setting: { key: string }) => setting.key === 'config_telephone'
    ).value
  }
)

onMounted(() => {
  token.value = route.params.token // Accès au paramètre token
  // email.value = route.params.email
  // storeId.value = route.params.store_id
  getUserConfirmRegister(emailValid, emailInvalide, token.value, router)
})
// async function getUserConfirmRegister(email: { value: string }, token: any, storeId: number) {
//   const data = {
//     email: email,
//     token: token,
//     store_id: storeId
//   }
//   try {
//     await fetchCsrf(csrfToken)
//     const url = `https://${URL_BACK}/api/customer/confirm-register`
//     const res = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'X-CSRF-Token': csrfToken.value
//       },
//       credentials: 'include',
//       body: JSON.stringify(data)
//     })

//     const response = await res.json()
//     messageConfirmRegister.value = response
//     if (response.class == 'success') {
//       emailValid.value = true
//       idTimeout.value = setTimeout(() => {
//         router.push('/login')
//       }, 6000)
//     } else {
//       emailInvalide.value = true
//     }
//   } catch (e) {}
// }
</script>

<template>
  <section class="section-confirm-register">
    <div class="div-confirm-register">
      <div v-if="emailValid" class="div-verify">
        <p class="text-confirm">
          {{ messageConfirmRegister.message }}
        </p>
        <IconSuccessRounded />
      </div>
      <div v-else-if="emailInvalide">
        <div class="div-verify">
          <div v-if="messageConfirmRegister.errors && messageConfirmRegister.errors.length > 0">
            <p v-for="(error, index) of messageConfirmRegister.errors" :key="index">
              {{ error }}
            </p>
          </div>
          <p v-else class="text-confirm">
            <!-- Vos informations ne sont pas valide ! -->
            {{ messageConfirmRegister.message }}
          </p>
          <IconWarning />
          <div v-if="messageConfirmRegister.class !== 'emailconfirm'">
            <p>Veuillez nous contacter !</p>
            <p v-if="configTelephone">
              <a class="link-confirm">{{ configTelephone }}</a>
            </p>
            <p v-if="configEmail">
              Email: <a class="link-confirm">{{ configEmail }}</a>
            </p>
          </div>
        </div>
        <!-- ajouter les informations du restaurant pour qu'il soit contacté en cas de problème -->
      </div>
      <div v-else>
        <div class="div-verify">Nous sommes entrain de vérifier vos informations</div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-confirm-register {
  @apply flex flex-col justify-center items-center h-full p-2 min-h-[30rem];
}
.div-confirm-register {
  @apply bg-slate-eronor  max-w-96 rounded-md px-3 py-4;
}
.div-verify {
  @apply flex flex-col justify-center min-h-44 items-center gap-3;
}
.text-confirm {
  @apply text-center;
}
.link-confirm {
  @apply underline text-amber-eronor;
}
</style>
