import { isAuthenticated } from '@/event/account/auth/authentification'
import { showValidateOrderModal } from '@/event/basket/showValidateOrderModal'
import router from '@/router'
import { toggleCartDetails } from './toggleCartDetails'

export const toggleValidateOrderModal = () => {
  if (isAuthenticated.value) {
    router.push({ path: '/basket/order' })
    toggleCartDetails()
  } else {
    showValidateOrderModal.value = !showValidateOrderModal.value
  }
}
