import {
  messageDeleteAddress,
  messageEmptyDeleteAddress
} from '@/event/account/dashboard/address/messageDeleteAddress'
import { showAlertDeleteAddress } from '@/event/account/dashboard/address/showAlertDeleteAddress'

export function toggleDeleteAddress() {
  messageDeleteAddress.value = { ...messageEmptyDeleteAddress }
  showAlertDeleteAddress.value = !showAlertDeleteAddress.value
}
