<script setup lang="ts">
import IconSuccessRounded from '@/components/icons/iconEronor/IconSuccessRounded.vue'
import customer from '@/storage/customer'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { order_id } = route.params

const customerGet = computed(() => customer.state.customerData)
// async function getInformationOrder(orderId: string | string[]) {
//   const url = 'order/find'

//   const data = {
//     order_id: Number(orderId)
//   }

//   const response = await fetchPostAuthorization(url, data)
// }

// getInformationOrder(order_id)
</script>

<template>
  <section class="section-success-view">
    <div class="div-customer-success">
      <!-- <p>{{ customerGet.lastname }}</p>
      <p>{{ customerGet.firstname }}</p> -->
    </div>
    <div class="div-success-content-view">
      <div class="div-success-payment">
        <div class="w-full flex flex-col items-center gap-3">
          <p class="p-payment-success">
            Félicitations, votre paiement a été effectué avec succès !
          </p>
          <p class="p-payment-success">
            numéro de commande: <span class="text-lime-eronor">{{ order_id }}</span>
          </p>
        </div>
        <IconSuccessRounded class="mt-6" />
      </div>
      <p class="p-content-success">
        Le restaurant vous enverra un email
        <span class="p-link-mail">({{ customerGet.email }})</span> pour vous confirmer l'heure de
        retrait
      </p>
    </div>
    <div class="mt-10">
      <p>En vous souhaitant une bonne soirée est un agréable repas</p>
    </div>
  </section>
</template>

<style scoped>
.section-success-view {
  @apply flex flex-col items-center px-2 mt-6 gap-2;
}
.div-customer-success {
  @apply w-full flex gap-2 text-2xl font-semibold;
}
.div-success-content-view {
  @apply flex flex-col gap-2 mt-2;
}
.div-success-payment {
  @apply flex flex-col items-center mt-3 w-full;
}
.p-payment-success {
  @apply font-bold text-2xl;
}
.p-content-success {
  @apply flex flex-row justify-center flex-wrap gap-1 mt-8;
}
.p-link-mail {
  @apply text-lime-eronor;
}

@media screen and (min-width: 700px) {
  .section-success-view {
    @apply max-w-[43rem] mt-12;
  }
}
</style>
