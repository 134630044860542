<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps({
  isVisible: Boolean,
  onClick: Function // Fonction pour les actions personnalisées
})

const isVisible = ref(props.isVisible)
watch(
  () => props.isVisible,
  (newValue) => {
    isVisible.value = newValue
  }
)

const toggleDisplay = () => {
  if (props.onClick) {
    props.onClick()
  }
}
</script>

<template>
  <button class="custom-button" @click="toggleDisplay">
    <span class="cross-button" :class="{ rotated: isVisible }">
      <span></span>
      <span></span
    ></span>
  </button>
</template>

<style scoped>
.custom-button {
  @apply absolute py-2 z-10 w-full h-9 flex justify-between;
}
.cross-button {
  @apply w-5 h-5 relative;
  left: 94%;
}
.cross-button span {
  @apply absolute top-2/4 left-2/4 w-full bg-white-eronor;
  transform: translate(-50%, -50%);
  height: 2px;
}

.cross-button span::before {
  content: '';
  @apply absolute top-2/4 left-2/4 w-full bg-inherit;
  transform: translate(-50%, -50%) rotate(90deg);
  height: 2.5px;
  transition: transform 0.3s ease-in-out;
}

.cross-button.rotated span::before {
  transform: translate(-50%, -50%) rotate(0deg);
}

@media screen and (min-width: 800px) {
  .custom-button {
    @apply hidden;
  }
}
</style>
