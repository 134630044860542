<template>
  <div class="div-btn-back">
    <router-link to="/" class="btn-back">
      <svg viewBox="0 0 800 744" xmlns="http://www.w3.org/2000/svg" class="svg-home">
        <path
          d="M672.627 425.905C676.257 428.748 678.378 433.103 678.378 437.715V728.659C678.378 736.943 671.663 743.659 663.378 743.659H496.752C488.468 743.659 481.752 736.943 481.752 728.659V562.911C481.752 554.626 475.036 547.911 466.752 547.911H333.254C324.969 547.911 318.254 554.626 318.254 562.911V728.659C318.254 736.943 311.538 743.659 303.254 743.659H136.627C128.343 743.659 121.627 736.943 121.627 728.659V437.715C121.627 433.103 123.748 428.748 127.379 425.905L390.755 219.652C396.187 215.398 403.82 215.398 409.252 219.652L672.627 425.905Z"
          fill=""
        />
        <path
          d="M7.97584 362.725C3.48676 356.233 4.79676 347.373 10.9724 342.458L390.659 40.2628C396.126 35.9113 403.874 35.9113 409.341 40.2628L789.028 342.458C795.203 347.373 796.513 356.233 792.024 362.725L762.578 405.31C757.641 412.45 747.692 413.921 740.899 408.515L409.341 144.625C403.874 140.274 396.126 140.274 390.659 144.625L59.1005 408.515C52.3082 413.921 42.359 412.45 37.4217 405.31L7.97584 362.725Z"
          fill=""
        />
        <path
          d="M672.271 0.34082C680.555 0.34082 687.271 7.05655 687.271 15.3408V172.968C687.271 185.53 672.752 192.527 662.925 184.701L594.05 129.845C590.477 126.999 588.396 122.68 588.396 118.111V15.3408C588.396 7.05655 595.111 0.34082 603.396 0.34082H672.271Z"
          fill=""
        />
      </svg>
      <p>Retour</p>
    </router-link>
  </div>
</template>

<style scoped>
.div-btn-back {
  @apply flex w-full pl-4;
}
.btn-back {
  @apply bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white  py-1 px-2 rounded-md flex gap-2;
}
.btn-back:hover p,
.btn-back:hover .svg-home {
  @apply text-black fill-black;
}
.svg-home {
  @apply w-5 fill-white-eronor;
}
@media screen and (min-width: 800px) {
  .div-btn-back {
    @apply w-[50rem];
  }
}
</style>
