import customer from '@/storage/customer'
import { fetchPostAuthorization } from '../fetch/fetchPostAuthorization'
import address from '@/storage/address'

export async function fetchAddress() {
  const url = 'address/get_by_user'

  const data = {
    customer_id: customer.getCustomerData().customer_id
  }

  try {
    const res = await fetchPostAuthorization(url, data)

    if (res.class == 'success') address.setAddressData(res.message)
  } catch (e) {}
}
