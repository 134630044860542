<script setup lang="ts">
import ButtonOrder from '@/components/button/order/ButtonValidateOrder.vue'
import ButtonContinue from '@/components/button/ButtonContinueOrder.vue'
import { ref, watch } from 'vue'
import { cartBus, showCartBus } from '@/event/cartBus'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { showValidateOrder } from '@/event/order/showValidateOrder'
import IconBasket from '@/components/icons/iconEronor/IconBasket.vue'
import ButtonLogin from '@/components/button/account/ButtonLogin.vue'
import ButtonRegister from '@/components/button/account/ButtonLinkRegister.vue'
import ButtonClose from '@/components/button/ButtonClose.vue'
import { useRoute } from 'vue-router'
import ButtonLinkBasket from '@/components/button/basket/ButtonLinkBasket.vue'
import { sectionOrderBus } from '@/event/sectionOrderBus'

// const validateOrder = ref(false)
const route = useRoute()

// const toggleValidateOrder = () => {
//   validateOrder.value = !validateOrder.value
//   showValidateOrder.value = !showValidateOrder.value
// }

// watch(
//   () => route.name,
//   (routeName, oldRouteName) => {
//     const sectionOrder = document.getElementById('section-order')
//     if (
//       (routeName !== 'racine' && routeName !== 'error_urlchecker' && window.innerWidth > 800) ||
//       routeName == 'product' ||
//       routeName == 'catalog-product' ||
//       oldRouteName == 'basket'
//     ) {
//       if (sectionOrder) sectionOrder.classList.add('isFlex')
//     }

//     if (routeName == 'basket' || routeName == 'login' || routeName == 'register') {
//       if (sectionOrder) sectionOrder.classList.remove('isFlex')
//     }
//   }
// )
watch(
  () => sectionOrderBus.value,
  () => {
    const sectionOrder = document.getElementById('section-order')
    if (sectionOrder) {
      if (sectionOrderBus.value == true) {
        sectionOrder.classList.add('isFlex')
      } else {
        sectionOrder.classList.remove('isFlex')
      }
    }
  },
  { deep: true }
)
</script>

<template>
  <section
    id="section-order"
    v-if="cartBus.length > 0"
    class="section-order"
    :class="{ isFlex: sectionOrderBus }"
  >
    <section class="section-button-order">
      <ButtonContinue />
      <!-- <ButtonOrder @click="toggleValidateOrder" /> -->
      <!-- rajouter une vérification si l'utilisateur est connecté -->
      <ButtonLinkBasket />
    </section>
  </section>
</template>

<style scoped>
.section-order {
  @apply hidden relative  w-full px-1 pt-1;
}
.section-order.isFlex {
  @apply flex;
}
.section-button-order {
  @apply flex justify-between w-full px-1  gap-1;
}

@media screen and (min-width: 800px) {
  .section-order {
    @apply hidden w-full container px-2;
  }
}
@media screen and (min-width: 1030px) {
  .section-order {
    @apply w-[60rem];
  }
}
@media screen and (min-width: 1500px) {
  .section-order {
    @apply w-[75rem];
  }
}
</style>
