import { isAuthenticated } from '@/event/account/auth/authentification'
import { showNavAccount } from '@/event/account/showNavAccount'
import { cartBus } from '@/event/cartBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { dashboardBus } from '@/event/dashboard/dashboardBus'
import { dataLoaded } from '@/event/dataLoaded'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory'
import { showDropdownSidebarSiteMap } from '@/event/main/navbar/showDropdownSidebarSitemap'
import { productBus } from '@/event/product/productBus'
import { productIdBus } from '@/event/product/productIdBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import router from '@/router'
import customer, { isCustomerDefined } from '@/storage/customer'
import {
  excludeRouteDropdownCategoryArray,
  excludeRouteShowNavbar,
  excludeRouteShowSectionOrderBar
} from '@/storage/navbar/excludeRoute'
import { fetchUser } from '@/utils/account/fetchUser'
import { selectLinkAriane } from '@/utils/ariane/selectLinkAriane'
import { selectLinkArianeOrder } from '@/utils/ariane/selectLinkArianeOrder'
import { checkAndRefreshToken } from '@/utils/jwt/checkAndrefreshToken'
import { goLogin } from '@/utils/main/navbar/goLogin'
import { goOutLogin } from '@/utils/main/navbar/goOutLogin'
import { shouldShowSectionOrder } from '@/utils/main/navbar/shouldShowSectionOrder'
import { clearSelection } from '@/utils/product/clearSelection'
import { watch } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export function watcherNavbarMobileDesktop(route: RouteLocationNormalizedLoaded) {
  const handleLoginRedirect = (routePath: string) => {
    if (['dashboard'].includes(routePath) && !isAuthenticated.value) {
      router.push('/')
    }
  }

  watch(
    [() => route.name, () => route.path, () => dataLoaded.value],
    ([routeName, routePath, dataLoadedValue]) => {
      // Vérifier si la route est différente de 'racine' et 'error_urlchecker'
      checkAndRefreshToken()

      if (routeName !== 'login') {
        // if (showNavAccount.value) {
        showNavAccount.value = false
        // }
      }

      if (routeName !== 'register') {
        // if (showNavAccount.value) {
        showNavAccount.value = false
        // }
      }

      dashboardBus.value = routePath.includes('dashboard')

      selectLinkAriane(routePath)
      selectLinkArianeOrder(routePath)

      if (routeName !== 'catalog-product' && routeName !== 'product') {
        categoryIdBus.value = 0
        categoryNameBus.value = ''
      }
      if (routeName !== 'product') {
        productIdBus.value = 0
        productBus.value = null
        clearSelection()
      }
      if (goLogin(routeName) || goOutLogin(routeName)) {
        router.push('/')
      }

      handleLoginRedirect(routePath)

      // console.log(customer.state.customerData.customer_id)

      // if (
      //   dataLoaded.value == 2 &&
      //   isAuthenticated.value &&
      //   customer.state.customerData.customer_id == undefined
      // ) {
      //   fetchUser()
      // }

      if (goOutLogin(routeName)) {
        router.push('/')
      }

      if (routeName) {
        const routeNameString = String(routeName)
        if (!excludeRouteDropdownCategoryArray.includes(routeNameString)) {
          showDropdownSidebarCategory.value = false
        } else {
          showDropdownSidebarSiteMap.value = false
        }

        // if (routeName !== 'racine' && routeName !== 'error_urlchecker' && dataLoadedValue === true) {
        if (!excludeRouteShowNavbar.includes(routeNameString) && dataLoadedValue === 2) {
          isNavbarVisible.value = true // Afficher la navbar
          if (shouldShowSectionOrder(routeName, cartBus.value)) {
            sectionOrderBus.value = true
          } else {
            sectionOrderBus.value = false
          }
        } else if (window.innerWidth > 800 && dataLoadedValue === 2) {
          isNavbarVisible.value = true // Afficher la navbar
          if (shouldShowSectionOrder(routeName, cartBus.value)) {
            sectionOrderBus.value = true // Activer la section de commande
          } else {
            sectionOrderBus.value = false
          }
        } else if (
          isNavbarVisible.value == true &&
          !excludeRouteShowSectionOrderBar.includes(routeNameString)
        ) {
          sectionOrderBus.value = true
        }
      }
    }
  )
}
