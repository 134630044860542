import { categoryIdBus } from '@/event/category/categoryIdBus'
import { productBus } from '@/event/product/productBus'
import { productIdBus } from '@/event/product/productIdBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import products from '@/storage/products'

export const updateProduct = () => {
  const foundProduct = products.state.productData.find(
    (product: { category_id: number; product_id: number }) =>
      product.category_id === Number(categoryIdBus.value) &&
      product.product_id === Number(productIdBus.value)
  )
  productBus.value = foundProduct || null
  relatedGroupBus.value = [] // Réinitialiser avant l'extraction
}
