import { ref, watch } from 'vue'
import { cartBus, type CartItem } from '@/event/cartBus'
import products, { type Product } from '@/storage/products'
import { convertPriceToEuro } from '@/utils/price/convertPrice'

import type { GoodProduct } from '@/types/basket/GoodProductInterface'
import { transformProductOptions } from './transformProductOptions'

export function useCart() {
  const product = ref<Product[]>(products.state.productData)
  const getGoodProduct = ref<GoodProduct[]>([])
  const quantity = ref<number[]>([])
  const isHovered = ref<boolean[]>([])

  // Observer les changements de produits dans le store
  watch(
    () => products.state.productData,
    (newValue) => {
      product.value = newValue
      mapCartToProducts()
    }
  )

  const getProduct = (productId: number) => {
    return product.value.find((prod) => prod.product_id === productId)
  }

  const updateGoodProductPrice = (cartItem: CartItem) => {
    const productFound = getProduct(cartItem.product_id)
    const totalPriceGoodProduct = cartItem.total_price

    if (productFound) {
      const transformedOptions = transformProductOptions(cartItem)

      getGoodProduct.value.push({
        category_id: cartItem.category_id,
        product_id: cartItem.product_id,
        product: cartItem.product,
        image: productFound.image,
        options: transformedOptions,
        price: totalPriceGoodProduct,
        rate_tax: cartItem.rate_tax,
        quantity: quantity.value[cartItem.product_id]
      })
    }
  }

  const mapCartToProducts = () => {
    getGoodProduct.value = []
    quantity.value = []
    isHovered.value = []

    cartBus.value.forEach((cartItem: CartItem, index) => {
      isHovered.value[index] = false
      quantity.value[cartItem.product_id] = cartItem.quantity
      updateGoodProductPrice(cartItem)
    })
  }

  // Observer les changements dans le panier
  watch(
    () => cartBus.value,
    () => {
      mapCartToProducts()
    },
    { deep: true }
  )

  mapCartToProducts()

  const updateParentQuantity = (newQuantity: number, index: number) => {
    const cartItem = cartBus.value[index]

    if (cartItem) {
      const quantityCartItem = cartItem.quantity
      const initialPrice = cartItem.total_price / quantityCartItem
      const productTax = cartItem.price_tax / quantityCartItem

      cartItem.quantity = newQuantity
      cartItem.total_price = convertPriceToEuro(initialPrice * newQuantity)
      cartItem.price_tax = convertPriceToEuro(productTax * newQuantity)
    }
  }

  return {
    getGoodProduct,
    quantity,
    isHovered,
    updateParentQuantity,
    mapCartToProducts
  }
}
