// Footer.vue

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue'
import Section from '@/components/main/Section.vue'
import SectionContact from '@/components/main/SectionContact.vue'
import SectionHours from '@/components/main/SectionHours.vue'
import stores, { type StoresDataType } from '@/storage/stores'
import { URL_BACK } from '@/config/constants'

// const storeSetting = ref<Partial<StoresDataType>>()
const nameStore = ref<string>('Eronor.com')

watch(
  () => stores.state.storeData,
  () => {
    // storeSetting.value = newSettingData.setting
    nameStore.value = stores.state.storeData.name || 'Eronor.com'
  },
  { immediate: true }
)

// onMounted(async () => {
// const url = `https://${URL_BACK}/api/place-details`
// try {
//   const res = await fetch(url)
//   if (!res.ok) {
//     throw new Error('Network response was not ok ' + res.statusText)
//   }
//   const response = await res.json()
// } catch (e) {
// }
// })
</script>

<template>
  <footer class="">
    <div class="middle-footer">
      <SectionContact :nameStore="nameStore" title="Contact" />
      <Section title="Informations du site" />
      <SectionHours title="Horaires / Livraison" />
    </div>
    <!-- <div class="elfsight-app-54dce338-7974-404b-86d0-2a83c26a99fe" data-elfsight-app-lazy></div> -->
  </footer>
</template>

<style scoped>
footer {
  @apply w-full pt-4;
}
.middle-footer {
  @apply flex flex-col flex-grow bg-black w-full bottom-0 p-2 min-h-full;
}

@media screen and (min-width: 800px) {
  .middle-footer {
    @apply grid grid-cols-3 justify-items-center mx-auto;
  }
}
</style>
