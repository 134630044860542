<script setup lang="ts">
import products, { type Product } from '@/storage/products'
import { URL_IMAGE } from '@/config/constants'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import stores from '@/storage/stores'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { hasDescriptionForLanguage } from '@/utils/product/hasDescriptionForLanguage'
import { getCategoryName } from '@/utils/category/category'
import IconPlaceholder from '@/components/icons/iconEronor/IconPlaceholder.vue'
import Ariane from '@/components/ariane/Ariane.vue'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { getProductName } from '@/utils/product/getProductName'
import { getProductDescription } from '@/utils/product/getProductDescription'
import { useCatalogProductWatchers } from '@/utils/watchers/catalogProduct/useCatalogProductWatchers'

const route = useRoute()
const categoryId = ref(route.params.category_id)
// const categoryName = ref(route.params.category_name)
const categoryNameParam = route.params.category_name

const catalogProduct = ref<Product[]>(products.state.productData)
const languageId = ref<number>(3)
const catalogueBase = ref(stores.state.storeData.category)
// const conversionRate = ref<number>(1.1)
const product = ref()

categoryNameBus.value = categoryNameParam // Prendre le premier élément si c'est un tableau
const SHIPPING_OPTIONS = [2, 3]

product.value = catalogProduct.value.filter(
  (product: { category_id: number; shipping: number }) =>
    product.category_id === Number(categoryId.value) && SHIPPING_OPTIONS.includes(product.shipping)
)

const filterProductsByCategory = (categoryId: string | string[]) => {
  product.value = catalogProduct.value.filter(
    (product: { category_id: number }) => product.category_id === Number(categoryId)
  )
  // product.value = []
}

useCatalogProductWatchers(
  catalogueBase,
  catalogProduct,
  categoryId,
  categoryNameBus,
  filterProductsByCategory
)

// watch(
//   () => stores.state.storeData,
//   (newValue) => {
//     catalogueBase.value = newValue.category
//   }
// )
// watch(
//   () => products.state.productData,
//   (newValue) => {
//     catalogProduct.value = newValue
//     filterProductsByCategory(categoryId.value)
//   }
// )
// watch(
//   () => route.params.category_id,
//   (newCategoryId) => {
//     categoryNameBus.value = route.params.category_name
//     categoryId.value = newCategoryId
//     filterProductsByCategory(newCategoryId)
//   }
// )
// watch([() => catalogueBase.value, () => catalogProduct.value], () => {
//   // Filtre les produits selon la catégorie chaque fois que catalogueBase ou catalogProduct change
//   if (categoryId.value) {
//     filterProductsByCategory(categoryId.value)
//   }
// })
</script>

<template class="">
  <section class="catalog-product">
    <Ariane />

    <aside class="aside-section">
      <div class="aside-div-category">
        <div
          v-for="category in catalogueBase"
          :key="category.category_id"
          class="aside-category-item"
        >
          <router-link
            v-if="category.category_id == Number(categoryId)"
            class="category-link isactive"
            :to="{
              name: 'catalog-product',
              params: {
                category_id: category.category_id,
                category_name: getCategoryName(category)
              }
            }"
          >
            <div class="">
              {{ getCategoryName(category) }}
            </div>
          </router-link>
          <router-link
            v-else
            class="category-link"
            :to="{
              name: 'catalog-product',
              params: {
                category_id: category.category_id,
                category_name: getCategoryName(category)
              }
            }"
          >
            <div class="">
              {{ getCategoryName(category) }}
            </div>
          </router-link>
        </div>
      </div>
      <section class="section-mobile-first">
        <div class="catalog-layout">
          <div class="catalog" v-if="product.length > 0">
            <div v-for="item in product" :key="item.model" class="">
              <router-link
                class="product"
                :to="{
                  name: 'product',
                  params: {
                    category_id: categoryId,
                    product_id: item.product_id
                  }
                }"
              >
                <img class="img" :src="`${URL_IMAGE}${item.image}`" :alt="item.model" />

                <div class="text-content">
                  <h2>{{ getProductName(item) }}</h2>

                  <p
                    class="truncate"
                    v-if="hasDescriptionForLanguage(item.description, languageId)"
                  >
                    {{ getProductDescription(item) }}
                  </p>
                  <!-- {{ item.sort_order }} -->
                  <p>Prix: {{ convertPriceToEuro(item.price) }} €</p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div class="placeholder-product">
              <div class="placeholder-image" v-for="n in 6" :key="n">
                <IconPlaceholder />
              </div>
            </div>
          </div>
        </div>
      </section>
    </aside>
  </section>
</template>

<style scoped>
.placeholder-product {
  @apply grid grid-cols-2 gap-2 my-2;
}
/* .placeholder-image {
  @apply rounded-lg;
} */

/* Style pour la div contenant les catégories */
.aside-div-category {
  @apply hidden;
  /*  max-width: 250px;
  overflow-y: auto; */
}

.aside-category-item img {
  @apply object-cover w-full h-32 rounded-t-lg;
}

.aside-category-item .category-link {
  @apply hover:text-lime-eronor w-full text-center p-2;
}
.isactive {
  @apply text-lime-eronor;
}
/* fil-ariane */

.fil {
  @apply border-s px-3;
}
.fil:hover {
  @apply text-lime-eronor;
}
.ariane-div {
  @apply w-full bg-slate-eronor rounded-md flex items-center gap-3 px-3 py-2 mb-3 mt-3;
}

.svg-home {
  @apply w-6 fill-white-eronor;
}
.svg-home:hover {
  @apply fill-lime-eronor;
}

/* catalog product */
.catalog-product {
  @apply px-3 w-full;
}
.img {
  @apply object-contain w-full rounded-xl max-h-44;
}

.product {
  @apply flex flex-col  items-center justify-center bg-slate-eronor rounded-xl relative py-2 h-full;
}
.product:hover {
  @apply bg-slate-hover;
}
.product:hover h2 {
  @apply text-lime-eronor text-center/* Couleur du texte au survol, ajuste selon tes besoins */;
}
.product:active {
  @apply bg-slate-hover;
}
.catalog {
  @apply grid grid-cols-2 gap-2 my-2;
}

.product .text-content {
  @apply flex flex-col text-center w-full p-1 text-sm;
}
.text-content h2 {
  @apply uppercase font-semibold;
}

@media screen and (min-width: 600px) {
  .placeholder-product {
    @apply grid-cols-3;
  }
  .catalog {
    @apply grid-cols-3;
  }
}

@media screen and (min-width: 800px) {
  .product {
    @apply pb-5 max-h-60;
  }
  .catalog {
    @apply grid-cols-4 justify-center;
  }
  .placeholder-product {
    @apply grid-cols-4 justify-center;
  }
  .catalog-product {
    @apply mx-4;
  }
  /* .section-mobile-first {
    @apply flex justify-center;
  } */
}

@media screen and (min-width: 1020px) {
  .aside-div-category {
    @apply flex flex-col items-start gap-2 mt-2;
    max-width: 250px;
  }
  .section-mobile-first {
    @apply flex-1 ml-4; /* Prend tout l'espace restant */
    /* Ajoute une marge à gauche pour espacer avec la catégorie */
  }
  .aside-category-item {
    @apply flex flex-col items-center bg-slate-eronor rounded-md;
    width: 100%;
  }
  .aside-section {
    @apply flex;
  }
  .catalog {
    @apply grid grid-cols-4 gap-3;
  }
  .placeholder-product {
    @apply grid-cols-4;
  }
  .catalog-product {
    @apply mx-8;
  }
}

@media screen and (min-width: 1280px) {
  .aside-category-item:hover {
    @apply bg-slate-hover;
  }
  /* .catalog {
    @apply grid grid-cols-5 gap-4;
  } */
  /* .placeholder-product {
    @apply grid-cols-5;
  } */
}

@media screen and (min-width: 1500px) {
  .catalog {
    @apply grid grid-cols-5 gap-4;
  }
  .placeholder-product {
    @apply grid-cols-5;
  }
  .catalog-product {
    @apply mx-auto container;
  }
}
</style>
