import { messageUpdatePassword } from '@/event/account/dashboard/messageUpdatePassword'
import { passwordUpdate } from '@/event/account/dashboard/password/passwordUpdate'
import { showAlertUpdatePassword } from '@/event/account/dashboard/showAlertUpdatePassword'
import { messageAlert } from '@/event/alert/messageAlert'
import { configEmail } from '@/storage/configEmail'
import customer from '@/storage/customer'
import stores from '@/storage/stores'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'

export async function dashboardModifyPassword(password: any, newPassword: any, oldPassword: any) {
  const url = 'customer/update-password'

  console.log(password)

  const data = {
    old_password: oldPassword,
    customer_id: customer.state.customerData.customer_id,
    email: customer.state.customerData.email,
    password: password,
    newpassword: newPassword,
    store_name: stores.state.storeData.name,
    store_email: configEmail.value
  }
  try {
    messageUpdatePassword.value = await fetchPostAuthorization(url, data)

    if (messageUpdatePassword.value.class) {
      showAlertUpdatePassword.value = true

      if (messageUpdatePassword.value.class == 'success') passwordUpdate.value = true
      setTimeout(() => {
        messageUpdatePassword.value = messageAlert
        showAlertUpdatePassword.value = false
      }, 5000)
    }
  } catch (e) {}
}
