<script setup lang="ts">
// OrderView.vue
import AlertDeliveryZones from '@/components/account/order/AlertDeliveryZones.vue'
import MyInformationOrder from '@/components/account/order/MyInformationOrder.vue'
import OptionOrder from '@/components/account/order/OptionOrder.vue'
import PaymentOrder from '@/components/account/order/PaymentOrder.vue'
import SelectHoursOrder from '@/components/account/order/SelectHoursOrder.vue'
import ValidateOrder from '@/components/account/order/ValidateOrder.vue'
import Alert from '@/components/alert/Alert.vue'
import Ariane from '@/components/ariane/Ariane.vue'
import ButtonOrderSend from '@/components/button/order/ButtonOrderSend.vue'
import ButtonPaymentOrder from '@/components/button/order/ButtonPaymentOrder.vue'
import { messageAddAddress } from '@/event/account/dashboard/messageAddAddress'
import { cartBus } from '@/event/cartBus'
import {
  orderData,
  updateFieldOrderData,
  type OrderDataProductsOptionType,
  type OrderDataProductsType
} from '@/event/order/data/orderData'
import { shippingCost } from '@/event/order/data/shippingCost'
import { messageSend } from '@/event/order/messageSend'
import { showDeliveryZones } from '@/event/order/showDeliveryZones'
import { baseUrl } from '@/event/url/url'
import { configCheckoutRestaurant } from '@/storage/configCheckoutRestaurant'
import { configDeliveryZones } from '@/storage/configDeliveryZones'
import customer from '@/storage/customer'
import { paymentStripeproStatus } from '@/storage/paymentStripeproStatus'
import { printerDefault } from '@/storage/printerDefault'
import products from '@/storage/products'
import stores from '@/storage/stores'
import { getFormattedDate } from '@/utils/date/getFormattedDate'
import { submitOrder } from '@/utils/order/submitOrder'
import { toggleSendOrder } from '@/utils/order/toggleSendOrder'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { toggleAddAddress } from '@/utils/toggle/dashboard/toggleAddAddress'
import { ref, watch } from 'vue'

const currentStep = ref('step0')
const takeOrder = ref(false)
const deliveryOrder = ref(false)
const finalStep = ref(true)

const userAgent = navigator.userAgent
// console.log(userAgent)

// function costByDeliveryZones(price: number, codePostalId: number) {
//   const zone = configDeliveryZones.value.find((z) => z.city_id === codePostalId)

//   if (zone) {
//     const { free_price, minimum_price, price: deliveryPrice } = zone
//     if (price >= Number(free_price)) return 0
//     if (price >= Number(minimum_price)) return Number(deliveryPrice)
//   }

//   return 0
// }

const handleContinue = (eventData: any) => {
  if (['step0', 'step1', 'step2', 'step3', 'step4'].includes(eventData.step)) {
    currentStep.value = eventData.step
    finalStep.value = true
  }

  if (eventData.step == 'step5') {
    finalStep.value = false
    updateFieldOrderData({ user_agent: userAgent })
  }

  if (eventData.method_payment === 'Card') {
    deliveryOrder.value = true
    takeOrder.value = false
    updateFieldOrderData({ order_status: 12 })
  } else if (eventData.method_payment === 'Counter') {
    deliveryOrder.value = false
    takeOrder.value = true
    updateFieldOrderData({ order_status: 1 })
  }

  // if (eventData.option_order == 'take') {
  //   deliveryOrder.value = false
  //   takeOrder.value = true
  // }

  // if (eventData.option_order == 'delivery') {
  //   takeOrder.value = false
  //   deliveryOrder.value = true
  // }
}

watch(
  () => finalStep.value,
  () => {
    if (finalStep.value == true) {
      deliveryOrder.value = false
      takeOrder.value = false
    }
  }
)

// function submitOrder() {
//   // const date = getFormattedDate()

//   const productArray: OrderDataProductsType[] = []

//   cartBus.value.forEach((product) => {
//     const optionArray: OrderDataProductsOptionType[] = []
//     product.product_option.forEach((option) => {
//       optionArray.push({
//         product_option_id: option.product_option_id,
//         product_option_value_id: option.valueId,
//         option_id: option.option_id,
//         name: option.optionName,
//         value: option.productName,
//         type: option.type,
//         price: String(option.price),
//         title: option.title,
//         po_sort_order: option.sort_order,
//         reset: option.reset
//       })
//     })

//     productArray.push({
//       lbl_printer_id: product.lbl_printer_id,
//       printer_port: 9100,
//       printer_id: product.printer_id,
//       product_id: product.product_id,
//       name: product.product,
//       quantity: product.quantity,
//       price: String(product.price.toFixed(2)),
//       total: String(product.total_price.toFixed(2)),
//       tax: String(product.price_tax.toFixed(2)),
//       tax_rate: String(product.rate_tax),
//       option: optionArray
//     })
//   })

//   updateFieldOrderData({
//     store_id: stores.state.storeData.store_id,
//     store_url: baseUrl,
//     store_name: stores.state.storeData.name,
//     // order_time: date.toString(),
//     customer_id: customer.state.customerData.customer_id,
//     customer_firstname: customer.state.customerData.firstname,
//     customer_lastname: customer.state.customerData.lastname,
//     customer_email: customer.state.customerData.email,
//     customer_telephone: customer.state.customerData.telephone,
//     printer_id: printerDefault.value?.printer_id,
//     printer_port: printerDefault.value?.printer_port,
//     printer_ip: printerDefault.value?.printer_ip,
//     template_id: printerDefault.value?.template_id,
//     products: productArray
//   })
// }
</script>

<template>
  <section class="section-basket-order">
    <Alert :function="toggleAddAddress" :message="messageAddAddress" />
    <!-- <Alert :function="toggleSendOrder" :message="messageSend" /> -->
    <AlertDeliveryZones v-if="showDeliveryZones" />
    <Ariane />

    <section class="section-content-basket-order">
      <div class="div-content-basket-order">
        <h3 class="h3-validate-order">Validation de commande</h3>
        <p class="text-rising">
          Montant de la commande:
          <span class="text-amber-eronor">{{ convertPriceToEuro(totalPrice) }}€</span>
        </p>
      </div>

      <p
        v-if="paymentStripeproStatus === '0' && configCheckoutRestaurant === '0'"
        class="text-center bg-red-eronor rounded-md"
      >
        Les moyens de paiements ne sont pas disponible !
      </p>

      <MyInformationOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <OptionOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <SelectHoursOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <PaymentOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <ValidateOrder :isOpenInitial="currentStep" @continue="handleContinue" />
    </section>

    <section class="pt-3">
      <ButtonPaymentOrder
        v-if="!finalStep && deliveryOrder"
        :finishStep="finalStep"
        @click="submitOrder"
      />
      <ButtonOrderSend v-if="!finalStep && takeOrder" @click="submitOrder" />
    </section>
    <p v-if="messageSend.message" :class="messageSend.class">{{ messageSend.message }}</p>
  </section>
</template>

<style scoped>
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}
.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}
.section-basket-order {
  @apply px-3 w-full flex flex-col items-center pb-12 relative;
}
.div-content-basket-order {
  @apply border-b border-b-slate-eronor flex flex-col justify-between items-center p-1 pb-3;
}
.h3-validate-order {
  @apply font-semibold text-lg;
}
.text-rising {
  @apply pt-1;
}
.section-content-basket-order {
  @apply pt-2 w-full flex flex-col gap-4;
}
@media screen and (min-width: 700px) {
  /* .section-content-dashboard-history {
    @apply flex-row justify-between gap-4;
  } */
  .div-content-basket-order {
    @apply flex-row px-4;
  }
}

@media screen and (min-width: 900px) {
  .section-basket-order {
    @apply w-[55rem];
  }
}
@media screen and (min-width: 1200px) {
  .section-basket-order {
    @apply w-[70rem];
  }
}
</style>
