<script setup lang="ts">
import ButtonClose from '@/components/button/ButtonClose.vue'
import ButtonLogin from '@/components/button/account/ButtonLogin.vue'
import ButtonValidateOrder from '@/components/button/order/ButtonValidateOrder.vue'
import ButtonLinkRegister from '@/components/button/account/ButtonLinkRegister.vue'
import ButtonSubstract from '@/components/button/basket/ButtonSubstract.vue'
import ButtonUpdate from '@/components/button/ButtonUpdate.vue'
import InputQuantityIndex from '@/components/form/InputQuantityIndex.vue'
import IconBasket from '@/components/icons/iconEronor/IconBasket.vue'
import { URL_IMAGE } from '@/config/constants'
import { cartBus, type CartItem } from '@/event/cartBus'
import { showValidateOrder } from '@/event/order/showValidateOrder'
import products, { type Product } from '@/storage/products'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { computed, onMounted, ref, watch } from 'vue'
import ButtonBack from '@/components/button/ButtonBack.vue'
import Ariane from '@/components/ariane/Ariane.vue'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { useRouter } from 'vue-router'
import ButtonContinue from '@/components/button/ButtonContinueOrder.vue'
import BasketTax from '@/components/basket/BasketTax.vue'
import { numberBasket } from '@/event/basketBus'
import { toggleValidateOrder } from '@/utils/toggle/basket/toggleValidateOrder'
import type {
  GoodProduct,
  TransformedOption,
  TransformedProduct
} from '@/types/basket/GoodProductInterface'
import ButtonClearBasket from '@/components/button/basket/ButtonClearBasket.vue'
import { useProducts } from '@/utils/basket/useProducts'
import { useCart } from '@/utils/basket/useCart'

// const product = ref<Product[]>(products.state.productData)
// const getGoodProduct = ref<GoodProduct[]>([])
// const quantity = ref<number[]>([])
// const isHovered = ref<boolean[]>([])
// const validateOrder = ref(false)
const router = useRouter()

// watch(
//   () => products.state.productData,
//   (newValue) => {
//     product.value = newValue
//     mapCartToProducts()
//   }
// )

// const getProduct = (productId: number) => {
//   return product.value.find((prod) => prod.product_id === productId)
// }

// const transformProductOptions = (cartItem: CartItem): TransformedOption[] => {
//   const groupedOptions: Record<string, TransformedProduct[]> = {}

//   cartItem.product_option.forEach((option) => {
//     const { optionName, productName, valueId, option_id, price } = option

//     const numericPrice = typeof price === 'string' ? parseFloat(price) : price
//     const roundedPrice = numericPrice > 0 ? numericPrice.toFixed(2) : 0

//     if (!groupedOptions[optionName]) {
//       groupedOptions[optionName] = []
//     }

//     groupedOptions[optionName].push({
//       productName,
//       price: roundedPrice
//     })
//   })

//   return Object.keys(groupedOptions).map((optionName) => ({
//     optionName,
//     products: groupedOptions[optionName],
//     price_product: groupedOptions[optionName][0]?.price || 0
//   }))
// }

// const updateGoodProductPrice = (cartItem: CartItem) => {
//   const productFound = getProduct(cartItem.product_id)
//   const totalPriceGoodProduct = cartItem.total_price

//   if (productFound) {
//     const transformedOptions = transformProductOptions(cartItem)

//     getGoodProduct.value.push({
//       category_id: cartItem.category_id,
//       product_id: cartItem.product_id,
//       product: cartItem.product,
//       image: productFound.image,
//       options: transformedOptions,
//       price: totalPriceGoodProduct,
//       // price: totalPrice,
//       rate_tax: cartItem.rate_tax,
//       quantity: quantity.value[cartItem.product_id]
//     })
//   }
// }

// const mapCartToProducts = () => {
//   getGoodProduct.value = []
//   quantity.value = []
//   isHovered.value = []

//   cartBus.value.forEach((cartItem: CartItem, index) => {
//     isHovered.value[index] = false
//     quantity.value[cartItem.product_id] = cartItem.quantity
//     updateGoodProductPrice(cartItem)
//   })
// }

// watch(
//   () => cartBus.value,
//   () => {
//     mapCartToProducts()
//   },
//   { deep: true }
// )

// mapCartToProducts()

// const updateParentQuantity = (newQuantity: number, index: number) => {
//   const cartItem = cartBus.value[index]

//   if (cartItem) {
//     const quantityCartItem = cartItem.quantity
//     const initialPrice = cartItem.total_price / quantityCartItem
//     const productTax = cartItem.price_tax / quantityCartItem

//     cartItem.quantity = newQuantity
//     cartItem.total_price = convertPriceToEuro(initialPrice * newQuantity)
//     cartItem.price_tax = convertPriceToEuro(productTax * newQuantity)
//   }
// }

const { getGoodProduct, quantity, isHovered, updateParentQuantity, mapCartToProducts } = useCart()
const { product, getProduct } = useProducts()
</script>

<template>
  <section class="section-basketview">
    <section class="w-full flex flex-col items-center">
      <Ariane />

      <!-- <ButtonBack /> -->
      <section v-if="showValidateOrder" class="section-validate-order">
        <div class="validate-order">
          <div class="absolute top-4 right-3">
            <ButtonClose :function="toggleValidateOrder" />
          </div>
          <IconBasket />
          <p class="text-center">Vous devez être connecté pour commander</p>
          <div class="div-button-register">
            <ButtonLinkRegister @click="toggleValidateOrder" />
            <ButtonLogin @click="toggleValidateOrder" />
          </div>
        </div>
      </section>

      <div class="div-product-backset">
        <p class="text-quantity">Nombre total de produit : {{ numberBasket }}</p>
        <p class="text-total">Prix total du panier : {{ convertPriceToEuro(totalPrice) }} €</p>
      </div>

      <section class="section-content-basketview">
        <div class="div-basketview">
          <div class="title-basket">
            <IconBasket />
            <h3>Mon panier</h3>
          </div>
          <section v-if="getGoodProduct.length > 0" class="section-product">
            <div v-for="(e, index) in getGoodProduct" :key="index" class="">
              <div class="element">
                <div class="div-img">
                  <img class="img" :src="`${URL_IMAGE}${e.image}`" alt="" />
                </div>

                <div class="w-full mt-2 relative">
                  <p class="font-semibold underline">{{ e.product }}</p>
                  <p class="details-order">
                    Details de la commande
                    <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="_x32_"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                    class="svg-threepoint"
                    @mouseover="isHovered[index] = true"
                    @mouseleave="isHovered[index] = false"
                  >
                    <g>
                      <circle class="st0" cx="55.091" cy="256" r="55.091" />
                      <circle class="st0" cx="256" cy="256" r="55.091" />
                      <circle class="st0" cx="456.909" cy="256" r="55.091" />
                    </g>
                  </svg> -->
                    <svg
                      viewBox="0 0 500 500"
                      xmlns="http://www.w3.org/2000/svg"
                      @mouseover="isHovered[index] = true"
                      @mouseleave="isHovered[index] = false"
                      class="svg-info"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500ZM250 465C368.741 465 465 368.741 465 250C465 131.259 368.741 35 250 35C131.259 35 35 131.259 35 250C35 368.741 131.259 465 250 465Z"
                      />
                      <path
                        d="M250 124C261.046 124 270 132.954 270 144C270 155.046 261.046 164 250 164C238.954 164 230 155.046 230 144C230 132.954 238.954 124 250 124Z"
                      />
                      <path
                        d="M230 202.334C230 191.288 238.954 182.334 250 182.334C261.046 182.334 270 191.288 270 202.334V356.334C270 367.38 261.046 376.334 250 376.334C238.954 376.334 230 367.38 230 356.334V202.334Z"
                      />
                    </svg>
                  </p>
                  <div v-if="isHovered[index]" class="div-info-order">
                    <div
                      v-for="(category, ind) in e.options"
                      :key="ind"
                      class="flex flex-wrap gap-1"
                    >
                      <p class="text-category">{{ category.optionName }} :</p>
                      <div class="flex gap-2" v-for="(product, i) in category.products" :key="i">
                        <p>
                          {{ product.productName
                          }}<span v-if="product.price !== 0" class="text-red-eronor">{{
                            ' (' + product.price + '€)'
                          }}</span>
                          <span v-if="i < category.products.length - 1">,</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <InputQuantityIndex
                    @update:quantity="updateParentQuantity"
                    :index="index"
                    :quantity="e.quantity"
                  />
                </div>

                <div class="div-modify">
                  <p class="font-bold text-nowrap mt-2">{{ e.price + ' €' }}</p>
                  <div class="relative flex gap-2 items-center pt-4">
                    <ButtonUpdate
                      :product_id="e.product_id"
                      :category_id="e.category_id"
                      :index="index"
                    />
                    <ButtonSubstract :index="index" />
                  </div>
                  <!-- <div class="relative"></div> -->
                </div>
              </div>
              <div class="border-b"></div>
            </div>
          </section>
          <section v-else class="mt-5 mb-5"><p>Votre panier est vide</p></section>
        </div>

        <div>
          <BasketTax :totalPrice="totalPrice" />
        </div>
      </section>
    </section>

    <div class="div-button-validate">
      <ButtonContinue />
      <ButtonClearBasket v-if="cartBus.length !== 0" />
      <ButtonValidateOrder v-if="cartBus.length !== 0" @click="toggleValidateOrder" />
    </div>
  </section>
</template>

<style scoped>
.text-quantity {
  @apply text-center pt-1;
}
.text-total {
  @apply text-amber-5-eronor text-center font-bold underline py-2;
}

.section-validate-order {
  @apply fixed flex justify-center mx-2  z-40;
}

.validate-order {
  @apply relative flex flex-col items-center justify-center gap-3 bg-slate-eronor p-3 min-w-72 rounded-lg;
}
.div-button-register {
  @apply flex  w-full justify-between p-1;
}
.div-button-validate {
  @apply flex  flex-col-reverse gap-2 mt-4;
}
/* .div-validate-order {
  @apply h-10;
} */

.div-modify {
  @apply gap-2 h-full;
}

/** */

.details-order {
  @apply flex flex-col  text-sm my-1;
}
.svg-info {
  @apply fill-white w-6 hover:fill-lime-eronor;
}
/* .svg-info-content {
  @apply fill-white;
} */

.svg-threepoint {
  @apply w-6 fill-white-eronor hover:fill-lime-eronor;
}
.text-category {
  @apply text-lime-eronor;
}

/** product */

.div-info-order {
  @apply bg-slate-hover p-2 rounded-lg -left-20 -right-16  absolute z-10;
}
.element {
  @apply flex justify-between  py-2 relative;
}

.section-product {
  @apply w-full;
}

.div-img {
  @apply flex items-center;
}
.img {
  @apply w-40;
}

/** section */
.section-basketview {
  @apply relative w-full min-h-[32rem] flex flex-col items-center justify-between px-3;
}

.title-basket {
  @apply flex items-center gap-2 border-b w-full justify-center pb-2 font-semibold;
}
.title-basket svg {
  @apply w-7;
}

.div-basketview {
  @apply bg-slate-eronor  w-full flex flex-col items-center p-3 rounded-lg;
}

@media screen and (min-width: 425px) {
  .details-order {
    @apply flex flex-row gap-2  text-base mr-1;
  }
}
@media screen and (min-width: 550px) {
  .div-button-validate {
    @apply flex-row justify-between w-full;
  }
  .div-info-order {
    @apply left-auto right-auto w-96;
  }
}
@media screen and (min-width: 600px) {
  .div-product-backset {
    @apply flex items-center justify-around w-full;
  }
}
@media screen and (min-width: 800px) {
  .section-basketview {
    @apply max-w-[70rem];
  }
  .div-basketview {
    @apply max-w-[70rem];
  }
}
@media screen and (min-width: 1000px) {
  .section-content-basketview {
    @apply flex gap-2 w-full;
  }
}
</style>
