import { ref } from 'vue'
import { messageRegister, typeMessageRegister } from './messageRegister'

export const showAlertRegister = ref(false)

export const toggleAlertRegister = () => {
  showAlertRegister.value = !showAlertRegister.value
  if (showAlertRegister.value === false) {
    messageRegister.value = typeMessageRegister
  }
}
