<script setup lang="ts">
import { indexUpdateElement, showCartBus } from '@/event/cartBus'
import router from '@/router'
import { ref } from 'vue'

const props = defineProps({
  product_id: Number,
  category_id: Number,
  index: Number
})

const modifyProduct = (product_id: number, category_id: number, index: number) => {
  indexUpdateElement.value = index
  if (product_id && category_id && index >= 0) {
    if (showCartBus.value == true) {
      showCartBus.value = !showCartBus.value
    }
    router.push({
      name: 'product',
      params: {
        category_id: category_id,
        product_id: product_id
      }
    })
  }
}

const showUpdate = ref<{ [key: number]: boolean }>({})
</script>

<template>
  <button
    @mouseover="props.index !== undefined && (showUpdate[props.index] = true)"
    @mouseleave="props.index !== undefined && (showUpdate[props.index] = false)"
    class="button-update-basket"
    @click="
      props.product_id !== undefined &&
        props.index !== undefined &&
        props.category_id !== undefined &&
        modifyProduct(props.product_id, props.category_id, props.index)
    "
  >
    <p class="text-update" v-if="props.index !== undefined && showUpdate[props.index]">Modifier</p>
    <svg class="svg-update" viewBox="0 0 800 800" fill="" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_338_3526)">
        <path
          d="M768.576 251.68L715.568 343.488V711.104C715.568 713.408 713.408 715.568 711.136 715.568H88.896C86.608 715.568 84.448 713.392 84.448 711.104V88.896C84.448 86.608 86.608 84.448 88.896 84.448H543.616L564.272 48.688L576.176 28.048C582.32 17.408 590.032 8.112 598.8 0H88.896C39.872 0 0 39.872 0 88.896V711.12C0 760.128 39.872 800 88.896 800H711.12C760.128 800 800 760.128 800 711.088V197.216L768.576 251.68Z"
          fill=""
        />
        <path
          d="M414.225 419.68L415.089 585.424L559.089 503.376L729.505 208.208L584.609 124.56L414.225 419.68Z"
          fill=""
        />
        <path
          d="M769.216 139.424C789.216 104.752 772.928 58.0322 732.928 34.9282C692.928 11.8242 644.304 21.0882 624.272 55.7922L603.312 92.1602L748.24 175.792L769.216 139.424Z"
          fill=""
        />
      </g>
      <defs>
        <clipPath id="clip0_338_3526">
          <rect width="800" height="800" fill="" />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<style scoped>
.button-update-basket {
  @apply relative;
}
.svg-update {
  @apply w-5 fill-white-eronor hover:fill-limedark-eronor;
}

.text-update {
  @apply absolute -top-10 -right-6 bg-slate-eronor px-2 py-1 rounded-lg border z-50;
}
</style>
