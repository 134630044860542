import { computed } from 'vue'
import stores from './stores'

export const configHomeDeliveryService = computed(() => {
  const dataConfigHomeDeliveryService = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key === 'config_home_delivery_service'
  )

  return dataConfigHomeDeliveryService?.value || []
})
