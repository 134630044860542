<script setup lang="ts">
import ButtonClose from '@/components/button/ButtonClose.vue'
import ButtonAddressAdd from '@/components/button/dashboard/ButtonAddressAdd.vue'
import { indexAddress } from '@/event/account/dashboard/address/indexAddress'
import {
  messageAddAddress,
  messageAddAddressEmpty
} from '@/event/account/dashboard/messageAddAddress'
import { showAlertAddAddress } from '@/event/account/dashboard/showAlertAddAddress'
import { showAdressForm } from '@/event/account/dashboard/showFormAddress'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'
import { messageAlert } from '@/event/alert/messageAlert'
import address from '@/storage/address'
import customer from '@/storage/customer'
import { configDeliveryZones } from '@/storage/configDeliveryZones'
import stores from '@/storage/stores'
import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { toggleShowAddressForm } from '@/utils/toggle/toggleShowAddressForm'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useAddress } from '@/utils/account/dashboard/addAddress'
import { useModifyAddress } from '@/utils/account/dashboard/useModifyAddress'

// const h1 = typeof indexAddress.value === 'number' ? "Modifier l'adresse" : 'Ajouter une adresse'
// const textButton = typeof indexAddress.value === 'number' ? 'Modifier' : 'Ajouter'

const addressModify = computed(() => {
  if (indexAddress.value !== null && indexAddress.value !== undefined)
    return address.state.addressData[indexAddress.value]
})

// const company = ref(addressModify.value?.company ? addressModify.value.company : '')
// const addressForm = ref(addressModify.value?.address_1 ? addressModify.value.address_1 : '')
// const supaddress = ref(addressModify.value?.address_2 ? addressModify.value.address_2 : '')
// const selectedRegion = ref('')
// const selectedCity = ref(
//   addressModify.value?.city
//     ? addressModify.value.city + ' (' + addressModify.value.postcode + ')'
//     : ''
// )
// const optionRegion = ref<string[]>([])
// const optionCity = ref<string[]>([])

// const dropdownOpen = ref<string | null>(null)

// const defaultAddress = ref('false')
const focused = ref('')

// const selectedCountryId = ref<number | string>('')
// const selectedZoneId = ref<number | string>('')
// const selectedCityId = ref<number | string>('')

// const loadOptions = () => {
//   optionRegion.value = [
//     ...new Set(configDeliveryZones.value.map((zone: DeliveryZone) => zone.zone))
//   ].sort((a, b) => a.localeCompare(b)) // Supprime les doublons
//   optionCity.value = []
// }
// loadOptions()

// async function loadOptionsByZoneId() {
//   if (configDeliveryZones.value) {
//     const foundZone = await configDeliveryZones.value.find(
//       (zone: DeliveryZone) => zone.zone_id === addressModify.value?.zone_id
//     )
//     if (foundZone) selectedRegion.value = foundZone.zone
//   }
//   if (addressModify.value?.default) {
//     defaultAddress.value = 'true'
//   }
//   updateCityOptionModify()
// }

// if (addressModify.value) {
//   loadOptionsByZoneId()
// }

// watch(
//   () => stores.state.storeData.setting,
//   (newValue) => {
//     if (newValue) {
//       loadOptions()
//     }
//   },
//   {
//     immediate: true
//   }
// )

// function updateCityOptions() {
//   optionCity.value = []
//   selectedCity.value = ''
//   if (selectedRegion.value) {
//     optionCity.value = configDeliveryZones.value
//       .filter((zone) => zone.zone === selectedRegion.value)
//       .map((zone) => zone.city)
//       .sort((a, b) => a.localeCompare(b))
//   }
// }

// function updateCityOptionModify() {
//   optionCity.value = []
//   if (selectedRegion.value) {
//     optionCity.value = configDeliveryZones.value
//       .filter((zone) => zone.zone === selectedRegion.value)
//       .map((zone) => zone.city)
//       .sort((a, b) => a.localeCompare(b))
//   }
// }

// function updateSelectedCityId() {
//   const selectedCityData = configDeliveryZones.value.find(
//     (zone) => zone.city === selectedCity.value && zone.zone === selectedRegion.value
//   )

//   if (
//     selectedCityData &&
//     selectedCityData.zone_id &&
//     selectedCityData.country &&
//     selectedCityData.city_id
//   ) {
//     selectedZoneId.value = selectedCityData.zone_id
//     selectedCountryId.value = selectedCityData.country
//     selectedCityId.value = selectedCityData.city_id
//   }
// }

// function toggleDropdown(type: 'region' | 'city') {
//   dropdownOpen.value = dropdownOpen.value === type ? null : type
// }

// function selectOptionRegion(option: string) {
//   selectedRegion.value = option
//   dropdownOpen.value = null
//   updateCityOptions()
// }

// function selectOptionCity(option: string) {
//   selectedCity.value = option
//   dropdownOpen.value = null
//   updateSelectedCityId()
// }

function handleClickOutside(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (!target.closest('.dropdown-select')) {
    dropdownOpen.value = null
  }
}
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// async function modifyAddress() {
//   const url = 'address/modify'

//   const addressData = addressModify.value

//   const data = {
//     address_id: addressModify.value?.address_id,
//     customer_id: customer.state.customerData.customer_id,
//     customer_firstname: customer.state.customerData.firstname,
//     customer_lastname: customer.state.customerData.lastname,
//     address: addressForm.value || addressData?.address_1,
//     code_postal_id: selectedCityId.value || addressData?.code_postal_id,
//     city: selectedCity.value || addressData?.city + ' (' + addressData?.postcode + ')',
//     country_id: selectedCountryId.value || addressData?.country_id,
//     zone_id: selectedZoneId.value || addressData?.zone_id,

//     company: company.value || addressData?.company || '',
//     address_2: supaddress.value || addressData?.address_2 || '',
//     default: defaultAddress.value || addressData?.default
//   }
//   const isDataNotEmpty = data.address == '' && data.city == ''

//   if (isDataNotEmpty) {
//     // showAlertAddAddress.value = true
//     const messageNotAdd = {
//       message: 'Les données necessaire sont manquantes !',
//       class: 'error',
//       errors: []
//     }
//     messageAddAddress.value = messageAddAddressEmpty
//     return
//   }

//   const isDataUnchanged =
//     data.address === (addressData?.address_1 || addressForm.value) &&
//     data.code_postal_id === (addressData?.code_postal_id || selectedCityId.value) &&
//     data.city === (addressData?.city + ' (' + addressData?.postcode + ')' || selectedCity.value) &&
//     data.country_id === (addressData?.country_id || selectedCountryId.value) &&
//     data.zone_id === (addressData?.zone_id || selectedZoneId.value) &&
//     data.company === (addressData?.company || company.value) &&
//     data.address_2 === (addressData?.address_2 || supaddress.value) &&
//     data.default === (String(addressData?.default) || defaultAddress.value)

//   if (isDataUnchanged) {
//     // Si aucune donnée n'a été modifiée, fermer le formulaire et afficher un message
//     const messageIdenticalData = {
//       message: 'Les données sont identiques. Aucun changement effectué.',
//       class: 'error',
//       errors: [],
//       address: 0
//     }
//     messageAddAddress.value = messageIdenticalData

//     // Fermer le formulaire
//     setTimeout(() => {
//       messageAddAddress.value = messageAddAddressEmpty
//       toggleShowAddressForm()
//     }, 2000) // Temps d'affichage du message avant de fermer le formulaire

//     return
//   }

//   try {
//     messageAddAddress.value = await fetchPostAuthorization(url, data)
//     if (messageAddAddress.value.class == 'success') {
//       // showAlertAddAddress.value = true
//       updateAddressData.value = true
//       setTimeout(() => {
//         // showAlertAddAddress.value = false
//         messageAddAddress.value = messageAddAddressEmpty

//         if (showAdressForm.value == true) {
//           toggleShowAddressForm()
//         }
//       }, 5000)
//     }
//   } catch (e) {}
// }

// async function addAddress() {
//   const url = 'address/add'

//   const data = {
//     customer_id: customer.state.customerData.customer_id,
//     customer_firstname: customer.state.customerData.firstname,
//     customer_lastname: customer.state.customerData.lastname,
//     address: addressForm.value,
//     code_postal_id: selectedCityId.value,
//     city: selectedCity.value,
//     country_id: selectedCountryId.value,
//     zone_id: selectedZoneId.value,

//     company: company.value,
//     address_2: supaddress.value,
//     default: defaultAddress.value
//   }

//   const isDataNotEmpty = data.address == '' && data.city == ''

//   if (isDataNotEmpty) {
//     // showAlertAddAddress.value = true
//     const messageNotAdd = {
//       message: 'Les données necessaire sont manquantes !',
//       class: 'error',
//       errors: [],
//       address: 0
//     }
//     messageAddAddress.value = messageNotAdd
//     return
//   }

//   try {
//     messageAddAddress.value = await fetchPostAuthorization(url, data)
//     if (messageAddAddress.value.class == 'success') {
//       // showAlertAddAddress.value = true
//       updateAddressData.value = true
//       setTimeout(() => {
//         // showAlertAddAddress.value = false
//         messageAddAddress.value = messageAddAddressEmpty
//         if (showAdressForm.value) {
//           toggleShowAddressForm()
//         }
//       }, 5000)
//     }
//   } catch (e) {}
// }
const {
  h1,
  textButton,
  company,
  addressForm,
  supaddress,
  selectedRegion,
  selectedCity,
  optionRegion,
  optionCity,
  dropdownOpen,
  defaultAddress,
  selectedCountryId,
  selectedZoneId,
  selectedCityId,
  selectOptionRegion,
  selectOptionCity,
  toggleDropdown,
  addAddress
} = useAddress(indexAddress, address, customer, stores)

const { modifyAddress } = useModifyAddress(
  addressModify,
  addressForm,
  supaddress,
  selectedCityId,
  selectedCity,
  selectedCountryId,
  selectedZoneId,
  company,
  defaultAddress,
  customer
)
</script>

<template>
  <section class="section-dashboard-adress-form">
    <div class="w-full flex justify-end">
      <ButtonClose :function="toggleShowAddressForm" class="" />
    </div>

    <h3 class="text-form-address">{{ h1 }}</h3>
    <span>* (obligatoire)</span>
    <form
      class="form-dashboard-address"
      @submit.prevent="typeof indexAddress === 'number' ? modifyAddress() : addAddress()"
    >
      <div class="form-group-address">
        <input
          type="text"
          v-model="company"
          id="company-address"
          @focus="focused = 'company'"
          @blur="focused = ''"
          class="input-text"
          :class="{ 'has-value': company !== '' || focused === 'company' }"
        />
        <label
          class="label-text"
          for="company-address"
          :class="{ active: company !== '' || focused === 'company' }"
        >
          Société
        </label>
      </div>

      <div class="form-group-address">
        <input
          type="text"
          v-model="addressForm"
          id="address-address"
          required
          @focus="focused = 'address'"
          @blur="focused = ''"
          class="input-text"
          :class="{ 'has-value': addressForm !== '' || focused === 'address' }"
        />
        <label
          class="label-text"
          for="address-address"
          :class="{ active: addressForm !== '' || focused === 'address' }"
        >
          <span>*</span>Adresse
        </label>
      </div>

      <div class="form-group-address">
        <textarea
          v-model="supaddress"
          id="address-supaddress"
          @focus="focused = 'supaddress'"
          @blur="focused = ''"
          class="input-textarea"
          :class="{ 'has-value': supaddress !== '' || focused === 'supaddress' }"
          rows="4"
        ></textarea>
        <label
          class="label-text"
          for="address-supaddress"
          :class="{ active: supaddress !== '' || focused === 'supaddress' }"
          >Informations complémentaire
        </label>
      </div>

      <div class="form-group-select-address">
        <div
          class="dropdown-select input-style"
          @click="toggleDropdown('region')"
          :class="{ open: dropdownOpen === 'region', 'has-value': selectedRegion }"
          tabindex="0"
        >
          <div class="absolute pl-52">
            <div class="arrow" :class="{ 'rotate-up': dropdownOpen === 'region' }">&#9660;</div>
          </div>
          <p class="" :class="{ pselect: dropdownOpen === 'region' }">{{ selectedRegion || '' }}</p>
          <ul v-if="dropdownOpen === 'region'" class="dropdown-options">
            <li class="border-t" @click="selectOptionRegion('')">--</li>
            <li
              class="pselectborder"
              v-for="(option, index) in optionRegion"
              :key="index"
              @click="selectOptionRegion(option)"
            >
              {{ option }}
            </li>
          </ul>
        </div>
        <label
          class="label-select"
          @click.stop="toggleDropdown('region')"
          :class="{ active: selectedRegion || dropdownOpen === 'region' }"
        >
          <span>*</span>Région
        </label>
      </div>

      <div class="form-group-select-address">
        <div
          class="dropdown-select input-style"
          @click="toggleDropdown('city')"
          :class="{ open: dropdownOpen === 'city', 'has-value': selectedCity }"
        >
          <p class="" :class="{ pselect: dropdownOpen === 'city' }">{{ selectedCity }}</p>
          <div class="absolute pl-52 top-0">
            <div class="arrow" :class="{ 'rotate-up': dropdownOpen === 'city' }">&#9660;</div>
          </div>
          <ul v-if="dropdownOpen === 'city'" class="dropdown-options">
            <li class="border-t" @click="selectOptionCity('')">--</li>
            <li
              class="pselectborder"
              v-for="(option, index) in optionCity"
              :key="index"
              @click="selectOptionCity(option)"
            >
              {{ option }}
            </li>
          </ul>
        </div>
        <label
          class="label-select"
          @click.stop="toggleDropdown('city')"
          :class="{ active: selectedCity || dropdownOpen === 'city' }"
        >
          <span>*</span>Ville
        </label>
      </div>

      <div class="form-group-radio-address">
        <p>Adresse par default</p>
        <label class="radio-address-dashboard" for="">
          Oui<input name="radio-default" type="radio" v-model="defaultAddress" value="true" />
        </label>
        <label class="radio-address-dashboard" for="">
          Non<input
            name="radio-default"
            type="radio"
            v-model="defaultAddress"
            value="false"
            checked
          />
        </label>
      </div>
      <ButtonAddressAdd :text="textButton" />
    </form>
  </section>
</template>

<style scoped>
.input-style {
  @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none transition-all ease-in-out h-7;
}
.dropdown-select {
  @apply relative  cursor-pointer  bg-white rounded-2xl;
}
.input-textarea {
  @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
  padding: 8px; /* add padding to make it comfortable to type in */
  min-height: 4rem; /* control initial height of textarea */
  resize: vertical; /* allow resizing vertically only */
}
.pselect {
  @apply relative z-40;
}
.pselectborder {
  @apply border-t;
}
.dropdown-options {
  @apply absolute bg-white-eronor max-h-40 z-20 left-0 right-0;

  top: 50%;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.dropdown-options {
  @apply pt-4 p-2;
  transition: background-color 0.2s ease;
}

.dropdown-options li:hover {
  @apply bg-white;
}

.dropdown-select.open {
  @apply border-red-eronor;
}
.section-dashboard-adress-form {
  @apply absolute flex flex-col items-center bg-slate-eronor z-20 p-3 rounded-md;
}
.text-form-address {
  @apply text-lg font-semibold;
}
.form-dashboard-address {
  @apply flex flex-col items-center gap-7 pt-8 bg-slate-eronor p-3 rounded-md;
}
.form-group-address {
  @apply relative flex flex-col items-center;
}
.form-group-radio-address {
  @apply flex gap-2 text-sm;
}
.form-group-select-address {
  @apply relative flex flex-col items-center;
}
.label-text {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
.label-select {
  @apply absolute  text-slate-eronor  transition-all ease-in-out duration-300;
  transition: all 1s ease;
}
.label-select.active {
  @apply -top-6 text-white-eronor;
}
.input-select.has-value + .label-select,
.input-text.has-value + .label-text,
.input-select:focus + .label-select,
.input-text:focus + .label-text {
  @apply -top-6 text-white-eronor;
}
.input-text.has-value + label,
.input-text:focus + label {
  @apply -top-6 text-white-eronor;
}
.input-textarea.has-value + .label-text,
.input-textarea:focus + .label-text {
  @apply -top-6 text-white-eronor;
}
.input-text {
  @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
}
.input-select {
  @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
}
label.active {
  transition: all 1s ease;
}
span {
  @apply text-red-eronor;
}
.radio-address-dashboard {
  @apply flex gap-1;
}
.arrow {
  @apply absolute bg-white-eronor w-10;
}
.rotate-up {
  @apply z-30;
  transform: rotate(180deg); /* Rotation à 180 degrés pour pointer vers le haut */
}
</style>
