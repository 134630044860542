import { idTimeout } from '@/event/account/idTimeout'
import { indexUpdateElement, showCartBus } from '@/event/cartBus'
import { dataLoaded } from '@/event/dataLoaded'
import router from '@/router'
import { excludeRouteApp } from '@/storage/navbar/excludeRoute'
import stores from '@/storage/stores'
import { loadData } from '@/utils/loadData'
import { useStoreInfo } from '@/utils/useStoreInfo'
import { watch } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

const resetIndexUpdateElement = () => {
  indexUpdateElement.value = null
}

const { testDay, testHour, testMinute, updateStore } = useStoreInfo()

export function watchersApp(route: RouteLocationNormalizedLoaded) {
  watch(
    () => route.name,
    (newRoute, oldRoute) => {
      const oldRouteValid = oldRoute
      if (oldRoute === 'error_urlchercker') {
        loadData()
      }

      if (idTimeout.value && oldRoute) {
        if (excludeRouteApp.includes(String(oldRouteValid))) {
          clearTimeout(idTimeout.value)
          idTimeout.value = null
        }
      }
    }
  )

  watch(
    () => route.params,
    (newParams) => {
      if (newParams.product_id === undefined) {
        resetIndexUpdateElement()
      }
    }
  )

  //   watch(
  //     () => showCartBus.value,
  //     (newValue) => {
  //       showOverlay.value = newValue
  //     }
  //   )

  watch([testDay, testHour, testMinute], () => {
    const data: any = stores.getStoresData()
    if (data) {
      updateStore(data)
    }
  })

  // watch(
  //   () => dataLoaded.value,
  //   async () => {
  //     if (dataLoaded.value === false) {
  //       await router.push({ name: 'error_urlchecker' })
  //     }
  //   }
  // )
}
