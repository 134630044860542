<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{ quantity: number }>()

const quantity = ref<number>(props.quantity) // Initial quantity

// if (props.quantity >= quantity.value) {
//   quantity.value = props.quantity
// }

const emit = defineEmits(['update:quantity'])

const increment = () => {
  quantity.value++
  emit('update:quantity', quantity.value)
}

// Function to decrement quantity
const decrement = () => {
  if (quantity.value > 1) {
    quantity.value--
    emit('update:quantity', quantity.value)
  }
}

watch(
  () => props.quantity,
  (newQuantity) => {
    quantity.value = newQuantity
  }
)
</script>

<template>
  <div class="section-quantity">
    <h4>Quantités</h4>
    <div class="div-quantity">
      <button @click="decrement" class="btn-quantity"><p>-</p></button>
      <input type="text" name="quantity" v-model="quantity" class="" disabled />
      <button @click="increment" class="btn-quantity">+</button>
    </div>
  </div>
</template>

<style scoped>
/* quantity */
.quantity-input {
  @apply w-8;
}
/* .section-quantity {
  @apply pb-4;
} */
.section-quantity h4 {
  @apply text-xl ml-2 underline;
}
.div-quantity {
  @apply flex gap-2 m-3;
}

.div-quantity input {
  @apply w-14 bg-transparent font-semibold text-3xl text-center align-middle;
}

.btn-quantity {
  @apply flex justify-center min-w-10 min-h-10 bg-white-eronor text-black font-bold text-2xl rounded-3xl;
}
.btn-quantity:hover {
  @apply bg-limedark-eronor text-white-eronor shadow-md;
}
</style>
