<script setup lang="ts"></script>

<template>
  <button class="button-connexion">
    <p class="">Connexion</p>
    <svg class="svg-mouse" viewBox="0 0 811 635" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M810.246 305.828C810.175 289.46 800.056 274.816 784.756 268.914L91.702 2.93453C75.0784 -3.43527 56.2176 2.02429 45.5726 16.3138C34.955 30.6198 35.1232 50.2501 46.0295 64.3258L235.202 309.012L9.99124 568.432C-2.01358 582.261 -3.00787 602.494 7.56033 617.431C8.87998 619.295 10.3367 621.019 11.8976 622.58C22.9373 633.619 39.6853 637.332 54.5799 631.437L785.096 342.965C800.323 336.953 810.328 322.21 810.246 305.828Z"
        fill=""
      />
    </svg>
  </button>
</template>

<style scoped>
.button-connexion {
  @apply bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white flex items-center gap-2 py-1 px-2 rounded-lg;
}
.svg-mouse {
  @apply w-5 fill-white-eronor;
}
.button-connexion p {
  @apply align-middle;
}
</style>
