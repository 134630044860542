import { productBus } from '@/event/product/productBus'
import { getSelectedProducts } from './getSelectedProducts'
import { convertFinalPrice } from '../price/convertFinalPrice'
import { convertPriceToEuro } from '../price/convertPrice'

export const getTotalPrice = (): number => {
  let totalPrice = 0
  const selectedProducts = getSelectedProducts()

  if (productBus.value) {
    totalPrice = totalPrice + convertFinalPrice(productBus.value.price)
  }
  return convertPriceToEuro(totalPrice)
}
