import { languageIdBus } from '@/event/languageIdBus'
import { getOptionName } from './getOptionName'
import { selectionsBus } from '@/event/product/selectionsBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { productBus } from '@/event/product/productBus'

export const handleCheckboxClick = (value: any, option: any) => {
  const optionName = getOptionName(option, languageIdBus.value)
  // console.log(value)

  if (!selectionsBus.value[optionName] && !extraPricesBus.value[optionName]) {
    selectionsBus.value[optionName] = []
    extraPricesBus.value[optionName] = {}
  }

  const numberItemFree = option.free_option_count

  const resetIndex = selectionsBus.value[optionName].findIndex((valId: number) => {
    const val = option.values.find((v: any) => v.option_value_id === valId)
    return val && val.reset === 1
  })

  if (resetIndex !== -1) {
    selectionsBus.value[optionName].splice(resetIndex, 1)
  }

  if (value.reset && productBus.value) {
    selectionsBus.value[optionName] = [value.option_value_id]
    extraPricesBus.value[optionName] = {}

    return
  }

  const index = selectionsBus.value[optionName].indexOf(value.option_value_id)

  if (index === -1) {
    if (value.price_status === 'general') {
      if (
        selectionsBus.value[optionName].length < option.free_option_count &&
        option.required &&
        option.max_option_count === 0
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        // if (selections.value[optionName].length > option.free_option_count) {
        if (!extraPricesBus.value[optionName][value.option_value_id]) {
          extraPricesBus.value[optionName][value.option_value_id] = 0
        }
      } else if (
        selectionsBus.value[optionName].length < option.max_option_count &&
        option.required
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        // if (!extraPrices.value[optionName][value.option_value_id]) {
        // }
        if (selectionsBus.value[optionName].length > option.free_option_count) {
          extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
        } else {
          extraPricesBus.value[optionName][value.option_value_id] = 0
        }
      } else if (
        option.free_option_count === 0 &&
        option.max_option_count === 0 &&
        option.required
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        // if (!extraPrices.value[optionName][value.option_value_id]) {
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
        // }
      } else if (
        option.free_option_count === 0 &&
        option.max_option_count === 0 &&
        !option.required
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
      } else {
        let numberFree = 0
        Object.values(extraPricesBus.value[optionName]).forEach((value) => {
          if (value == 0) {
            numberFree++
          }
        })
        selectionsBus.value[optionName].push(value.option_value_id)

        if (
          !extraPricesBus.value[optionName][value.option_value_id] &&
          numberFree == option.free_option_count
        ) {
          extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
        } else {
          extraPricesBus.value[optionName][value.option_value_id] = 0
        }
      }
    } else if (value.price_status === 'special') {
      selectionsBus.value[optionName].push(value.option_value_id)
      if (!extraPricesBus.value[optionName][value.option_value_id]) {
        extraPricesBus.value[optionName][value.option_value_id] = 0
      }
      extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
    }
  } else {
    selectionsBus.value[optionName].splice(index, 1)

    delete extraPricesBus.value[optionName][value.option_value_id]
  }
}
