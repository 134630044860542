import stores from '@/storage/stores'

export function formatStoreName(storeName: string) {
  //   const storeName = stores.getStoresData().name ?? null
  // Remplacer tous les espaces et caractères non-alphanumériques (autre que _)
  // if (storeName)
  return storeName.replace(/[^a-zA-Z0-9]/g, '_')

  //   return null
}
