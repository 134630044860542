import { languageIdBus } from '@/event/languageIdBus'
import type { Product } from '@/storage/products'

export const getProductName = (product: Product) => {
  if (!product || !product.description) {
    return 'Nom non disponible'
  }

  const desc = product.description.find(
    (d: { language_id: number }) => d.language_id === languageIdBus.value
  )

  return desc ? desc.name : 'Nom non disponible'
}
