<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import ButtonRegister from '@/components/button/ButtonRegister.vue'
import IconEye from '@/components/icons/iconEronor/IconEye.vue'
import IconEyeOff from '@/components/icons/iconEronor/IconEyeOff.vue'
import stores from '@/storage/stores'
import { languageIdBus } from '@/event/languageIdBus'
import { firstPartUrl } from '@/event/url/url'
import { URL_BACK } from '@/config/constants'
import { firstLetterUppercase } from '@/utils/text/firstLetteUppercase'
import { showAlertRegister, toggleAlertRegister } from '@/event/account/showAlertRegister'
import { useRouter } from 'vue-router'
import ButtonClose from '@/components/button/ButtonClose.vue'
import { messageRegister } from '@/event/account/messageRegister'
import { fetchCsrf } from '@/utils/csrf/fetchCsrf'
import { idTimeout } from '@/event/account/idTimeout'
import IconSuccessRoundedPassword from '@/components/icons/iconEronor/IconSuccessRoundedPassword.vue'

const firstname = ref('')
const lastname = ref('')
const email = ref('')
const telephone = ref('')
const password = ref('')
const newPassword = ref('')
const focused = ref('')
const showPassword = ref(false)
const showNewPassword = ref(false)

const isMinLengthValid = computed(() => password.value.length >= 10)
const hasUpperCase = computed(() => /[A-Z]/.test(password.value))
const hasLowerCase = computed(() => /[a-z]/.test(password.value))
const hasNumber = computed(() => /[0-9]/.test(password.value))

const identicPassword = computed(
  () =>
    password.value.length > 0 &&
    newPassword.value.length > 0 &&
    password.value === newPassword.value
)

const hasSpecialChar = computed(() => {
  const forbiddenSpecialCharRegex = /[<>$"'&]/
  const allowedSpecialCharRegex = /[!@#%^*()[\]{};:,.\/?\\|`~+=_-]/
  return (
    password.value.length > 0 &&
    allowedSpecialCharRegex.test(password.value) &&
    !forbiddenSpecialCharRegex.test(password.value)
  )
})
// const idTimeOut = ref()
// const typeMessageRegister = {
//   message: '',
//   class: '',
//   errors: []
// }
// const messageRegister = ref(typeMessageRegister)
const csrfToken = ref('')
const route = useRouter()

const formattedTelephone = computed({
  get() {
    return telephone.value.replace(/(\d{2})(?=\d)/g, '$1.')
  },
  set(value) {
    telephone.value = value.replace(/\./g, '')
  }
})

async function register() {
  await fetchCsrf(csrfToken)

  const data = {
    store_id: stores.state.storeData.store_id,
    store_name: stores.state.storeData.name,
    language_id: languageIdBus.value,
    firstname: firstLetterUppercase(firstname.value),
    lastname: lastname.value.toUpperCase(),
    email: email.value,
    telephone: telephone.value,
    password: password.value,
    newpassword: newPassword.value
  }

  const url =
    firstPartUrl === 'http'
      ? `${firstPartUrl}://127.0.0.1:8080/api/customer/add`
      : `${firstPartUrl}://${URL_BACK}/api/customer/add`
  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value,
        Accept: '*/*'
      },

      body: JSON.stringify(data)
    })

    const response = await res.json()
    messageRegister.value = response
    if (messageRegister.value.class) {
      showAlertRegister.value = true
      if (messageRegister.value.class === 'success') {
        idTimeout.value = setTimeout(() => {
          if (showAlertRegister.value) {
            toggleAlertRegister()
          }
          route.push('/')
        }, 6000)
      }
    }
  } catch (e: any) {
    showAlertRegister.value = true
    messageRegister.value = { message: e, class: 'error', errors: [] }
  }
}
</script>

<template>
  <section class="section-register-main">
    <div
      v-if="messageRegister.errors && messageRegister.errors.length > 0"
      :class="messageRegister.class"
      class="hidden"
    >
      <div class="div-button-alert">
        <ButtonClose class="button" :function="toggleAlertRegister" />
      </div>
      <p v-for="(error, index) in messageRegister.errors" :key="index" class="bullet">
        {{ error }}
      </p>
    </div>
    <div v-else :class="messageRegister.class" class="hidden">
      <div class="div-button-alert"><ButtonClose :function="toggleAlertRegister" /></div>
      <p>{{ messageRegister.message }}</p>
    </div>

    <section class="section-register-form">
      <section>
        <h3>S'inscrire</h3>

        <div>
          <form class="form-register" id="form-register" @submit.prevent="register">
            <h4>Données personnelles</h4>
            <div class="form-group">
              <input
                type="text"
                v-model="firstname"
                id="firstname"
                required
                @focus="focused = 'firstname'"
                @blur="focused = ''"
                :class="{ 'has-value': firstname !== '' || focused === 'firstname' }"
              />
              <label
                for="firstname"
                :class="{ active: firstname !== '' || focused === 'firstname' }"
              >
                <span>*</span> Prénom
              </label>
            </div>

            <div class="form-group">
              <input
                type="text"
                v-model="lastname"
                id="lastname"
                required
                @focus="focused = 'lastname'"
                @blur="focused = ''"
                :class="{ 'has-value': lastname !== '' || focused === 'lastname' }"
              />
              <label for="lastname" :class="{ active: lastname !== '' || focused === 'lastname' }">
                <span>*</span> Nom de famille
              </label>
            </div>

            <div class="form-group">
              <input
                type="email"
                v-model="email"
                id="email"
                required
                @focus="focused = 'email'"
                @blur="focused = ''"
                :class="{ 'has-value': email !== '' || focused === 'email' }"
              />
              <label for="email" :class="{ active: email !== '' || focused === 'email' }">
                <span>*</span> Email
              </label>
            </div>

            <div class="form-group">
              <input
                type="tel"
                v-model="formattedTelephone"
                id="telephone"
                required
                @focus="focused = 'telephone'"
                @blur="focused = ''"
                :class="{ 'has-value': telephone !== '' || focused === 'telephone' }"
              />
              <label
                for="telephone"
                :class="{ active: telephone !== '' || focused === 'telephone' }"
              >
                <span>*</span> Téléphone
              </label>
            </div>

            <div>
              <h4>Mot de passe</h4>

              <section class="section-indicate-register">
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="identicPassword" />
                  <p>Les 2 mot de passe sont identique</p>
                </div>
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="hasUpperCase" />
                  <p>1 lettre en majuscule</p>
                </div>
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="hasLowerCase" />
                  <p>1 lettre en minuscule</p>
                </div>
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="hasNumber" />
                  <p>1 chiffre</p>
                </div>
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="hasSpecialChar" />
                  <p>1 caractère spéciale</p>
                </div>
                <div class="text-indicate-register">
                  <IconSuccessRoundedPassword :validate="isMinLengthValid" />
                  <p class="text-pretty-register">
                    Le mort de passe doit contenir minimum 10 caractères
                  </p>
                </div>
              </section>
            </div>

            <div class="form-group">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                id="password"
                required
                @focus="focused = 'password'"
                @blur="focused = ''"
                :class="{ 'has-value': password !== '' || focused === 'password' }"
              />
              <label for="password" :class="{ active: password !== '' || focused === 'password' }">
                <span>*</span> Mot de passe
              </label>
              <span class="eye-icon" @click="showPassword = !showPassword"
                ><template v-if="showPassword"><IconEye /></template
                ><template v-else><IconEyeOff /></template
              ></span>
            </div>

            <div class="form-group">
              <input
                :type="showNewPassword ? 'text' : 'password'"
                v-model="newPassword"
                id="newpassword"
                required
                @focus="focused = 'newpassword'"
                @blur="focused = ''"
                :class="{ 'has-value': newPassword !== '' || focused === 'newpassword' }"
              />
              <label
                for="newpassword"
                :class="{ active: newPassword !== '' || focused === 'newpassword' }"
              >
                <span>*</span> Confirmer le mot de passe </label
              ><span class="eye-icon" @click="showNewPassword = !showNewPassword">
                <template v-if="showNewPassword"><IconEye /></template
                ><template v-else><IconEyeOff /></template
              ></span>
            </div>
            <ButtonRegister />
          </form>
        </div>
      </section>
    </section>

    <section class="section-indicate-register-desktop">
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="identicPassword" />
        <p>Les 2 mot de passe sont identique</p>
      </div>
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="hasUpperCase" />
        <p>1 lettre en majuscule</p>
      </div>
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="hasLowerCase" />
        <p>1 lettre en minuscule</p>
      </div>
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="hasNumber" />
        <p>1 chiffre</p>
      </div>
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="hasSpecialChar" />
        <p>1 caractère spéciale</p>
      </div>
      <div class="text-indicate-register">
        <IconSuccessRoundedPassword :validate="isMinLengthValid" />
        <p class="text-pretty-register">Le mort de passe doit contenir minimum 10 caractères</p>
      </div>
    </section>
  </section>
</template>

<style scoped>
.section-register-main {
  @apply flex flex-col items-center relative;
}
.section-indicate-register-desktop {
  @apply hidden;
}
.section-indicate-register {
  @apply flex flex-col gap-1  max-w-72 pt-2;
}
.text-indicate-register {
  @apply flex gap-1 items-start;
}

/** */

.div-button-alert {
  @apply flex justify-end;
}

.bullet {
  @apply relative pl-4;
}

.bullet::before {
  content: '•';
  position: absolute;
  left: 0;
}
.success {
  @apply flex flex-col bg-lime-eronor mt-24 px-3 pt-2 pb-3 rounded-md absolute z-30 top-16;
}
.error {
  @apply flex flex-col bg-red-eronor mt-24 px-3 pt-1 pb-3 rounded-md absolute z-30 top-16;
}
.eye-icon {
  @apply absolute top-2/4 right-2;

  transform: translateY(-50%);
  cursor: pointer;
}

.svg-eye,
.svg-eye-off {
  @apply w-5 h-5;

  /* stroke: #666; */
}
h3 {
  @apply font-bold;
}
h4 {
  @apply font-semibold border-b w-full text-center py-1;
}
.text-password {
  @apply w-full text-center max-w-80 text-xs;
}
span {
  @apply text-red-eronor;
}

.section-register-form {
  @apply relative bg-slate-eronor rounded-md  flex flex-col p-3 gap-2 items-center mt-3;
}
.form-register {
  @apply flex flex-col items-center pb-1;
}
.form-register input {
  @apply rounded-2xl border-2 outline-none focus:border-red-eronor;
}
.form-group {
  @apply mt-7 relative flex flex-col items-center;
}
label {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor font-semibold;
}
input {
  @apply text-slate-eronor text-center min-w-64;
}
label.active {
  transition: all 1s ease;
}

@media screen and (min-width: 450px) {
  .section-register-form {
    @apply min-w-[22rem];
  }
}

@media screen and (min-width: 800px) {
  /* .section-register-form {
    @apply flex-row items-start gap-3;
  } */
  .section-register-main {
    @apply flex-row gap-5 items-center justify-center;
  }
  .section-indicate-register {
    @apply hidden;
  }
  .section-indicate-register-desktop {
    @apply flex flex-col gap-1 max-w-80 bg-slate-eronor p-3 mt-3 mb-auto rounded-md;
  }
}
</style>
