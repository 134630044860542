// Fonction pour transformer les options de produit
export function transformBasketProductOption(productOptions: any[]) {
  // Affiche les options du produit à transformer

  return productOptions.reduce((acc, option) => {
    const key = option.optionName || 'Sans nom' // Utiliser 'Sans nom' si optionName est vide
    if (!acc[key]) {
      acc[key] = [] // Initialiser le tableau si la clé n'existe pas
    }
    acc[key].push(option.productName) // Ajouter le productName au tableau correspondant
    return acc // Retourner l'accumulateur
  }, {})
}
