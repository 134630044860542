<script setup lang="ts">
import { indexAddress } from '@/event/account/dashboard/address/indexAddress'
import { toggleShowAddressForm } from '@/utils/toggle/toggleShowAddressForm'
import { computed, ref } from 'vue'

const props = defineProps({ index: Number })
const showAddressUpdate = ref<{ [key: number]: boolean }>({})
const sizeMobile = computed(() => window.innerWidth == 700)

function modifyAddress() {
  toggleShowAddressForm()
  indexAddress.value = typeof props.index == 'number' ? props.index : null
}
</script>

<template>
  <button
    @mouseover="props.index !== undefined && (showAddressUpdate[props.index] = true)"
    @mouseleave="props.index !== undefined && (showAddressUpdate[props.index] = false)"
    class="button-update-address"
    @click="modifyAddress"
  >
    <svg
      class="svg-address-update"
      viewBox="0 0 800 800"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_338_3526)">
        <path
          d="M768.576 251.68L715.568 343.488V711.104C715.568 713.408 713.408 715.568 711.136 715.568H88.896C86.608 715.568 84.448 713.392 84.448 711.104V88.896C84.448 86.608 86.608 84.448 88.896 84.448H543.616L564.272 48.688L576.176 28.048C582.32 17.408 590.032 8.112 598.8 0H88.896C39.872 0 0 39.872 0 88.896V711.12C0 760.128 39.872 800 88.896 800H711.12C760.128 800 800 760.128 800 711.088V197.216L768.576 251.68Z"
          fill=""
        />
        <path
          d="M414.225 419.68L415.089 585.424L559.089 503.376L729.505 208.208L584.609 124.56L414.225 419.68Z"
          fill=""
        />
        <path
          d="M769.216 139.424C789.216 104.752 772.928 58.0322 732.928 34.9282C692.928 11.8242 644.304 21.0882 624.272 55.7922L603.312 92.1602L748.24 175.792L769.216 139.424Z"
          fill=""
        />
      </g>
      <defs>
        <clipPath id="clip0_338_3526">
          <rect width="800" height="800" fill="" />
        </clipPath>
      </defs>
    </svg>
    <p class="text-update" v-if="props.index !== undefined && showAddressUpdate[props.index]">
      Modifier
    </p>
    <p class="text-update-static">Modifier</p>
  </button>
</template>

<style scoped>
.button-update-address {
  @apply flex items-center gap-2 p-2 relative bg-lime-eronor w-fit rounded-md hover:bg-limedark-eronor;
}
.svg-address-update {
  @apply w-5 fill-white;
}
.text-update {
  @apply absolute bg-slate-medium-dark border rounded-md p-1 left-10 z-10;
}
.text-update-static {
  @apply hidden;
}

@media screen and (min-width: 800px) {
  .text-update {
    @apply hidden;
  }
  .text-update-static {
    @apply flex;
  }
}
</style>
