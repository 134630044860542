import {
  messageAddAddress,
  messageAddAddressEmpty
} from '@/event/account/dashboard/messageAddAddress'
import { showAlertAddAddress } from '@/event/account/dashboard/showAlertAddAddress'
import { messageAlert } from '@/event/alert/messageAlert'

export function toggleAddAddress() {
  //   showAlertAddAddress.value = !showAlertAddAddress.value
  messageAddAddress.value = messageAddAddressEmpty
}
