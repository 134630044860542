<script setup lang="ts"></script>

<template>
  <button class="btn-register">
    <p>S'enregistrer</p>
    <svg class="svg-register" viewBox="0 0 800 765" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M723.111 118.144H700.611V140.644V185.676C700.611 190.858 696.413 195.054 691.234 195.054C686.054 195.054 681.856 190.858 681.856 185.676V140.644V118.144H659.356H614.324C609.144 118.144 604.946 113.948 604.946 108.766C604.946 103.585 609.144 99.3887 614.324 99.3887H659.356H681.856V76.8887V31.8776C681.856 26.6961 686.054 22.5 691.234 22.5C696.413 22.5 700.611 26.6961 700.611 31.8776V76.8887V99.3887H723.111H768.122C773.292 99.3887 777.5 103.596 777.5 108.766C777.5 113.948 773.302 118.144 768.122 118.144H723.111ZM464.945 494.622L443.238 520.489L475.469 530.561L699.815 600.668C699.818 600.669 699.821 600.669 699.824 600.67C712.658 604.69 721.353 616.535 721.353 629.922V711.189C721.353 728.142 707.658 741.818 690.724 741.818H53.1293C36.1954 741.818 22.5 728.142 22.5 711.189V629.922C22.5 616.486 31.1997 604.673 44.0187 600.674L44.0285 600.671L268.404 530.561L300.652 520.485L278.923 494.614C234.447 441.66 206.221 355.543 206.221 274.147C206.221 213.508 223.477 165.89 251.831 133.755C279.931 101.907 320.616 83.3012 371.948 83.3012C423.255 83.3012 463.931 101.906 492.026 133.754C520.376 165.889 537.632 213.508 537.632 274.147C537.632 355.559 509.387 441.662 464.945 494.622Z"
        stroke=""
        stroke-width="45"
      />
    </svg>
  </button>
</template>

<style scoped>
.btn-register {
  @apply bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white flex rounded-lg py-2 px-3 gap-1 mt-3;
}
.svg-register {
  @apply w-5 stroke-white-eronor;
}

.btn-register:hover {
  @apply bg-limedark-eronor;
}
</style>
