import { ref } from 'vue'

export const theme = ref('dark')

// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//   theme.value = 'dark'
// } else {
//   theme.value = 'light'
// }
// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//   console.log('The user prefers dark mode')
// } else {
//   console.log('The user prefers light mode')
// }

// window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
//   console.log(e.matches ? 'Dark mode' : 'Light mode')
// })

// console.log('User agent:', navigator.userAgent)
// console.log(window.matchMedia('(prefers-color-scheme: dark)'))

// console.log(localStorage)
