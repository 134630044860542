import { URL_BACK } from '@/config/constants'
import { firstPartUrl } from '@/event/url/url'
import products from '@/storage/products'

export async function getProductToCategory(categoryIdArray: Array<number>) {
  let responseProduct: Response

  try {
    const url =
      firstPartUrl === 'http'
        ? `${firstPartUrl}://127.0.0.1:8080/api/product/search_product`
        : `${firstPartUrl}://${URL_BACK}/api/product/search_product`

    responseProduct = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      // credentials: 'include',
      body: JSON.stringify({ category_id_array: categoryIdArray })
    })

    const fetchData = await responseProduct.json()
    products.setProductData(fetchData)
  } catch (e) {
    throw e
  }
}
