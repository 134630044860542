<script setup lang="ts">
import ButtonModify from '@/components/button/ButtonModify.vue'
import IconEye from '@/components/icons/iconEronor/IconEye.vue'
import IconEyeOff from '@/components/icons/iconEronor/IconEyeOff.vue'
import IconSuccessRoundedPassword from '@/components/icons/iconEronor/IconSuccessRoundedPassword.vue'
import { messageAlert } from '@/event/alert/messageAlert'
import { showAlertUpdatePassword } from '@/event/account/dashboard/showAlertUpdatePassword'
import customer from '@/storage/customer'
import { configEmail } from '@/storage/configEmail'
import stores from '@/storage/stores'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { computed, ref, watch } from 'vue'
import Alert from '@/components/alert/Alert.vue'
import { toggleUpdatePassword } from '@/utils/toggle/dashboard/toggleUpdatePassword'
import { messageUpdatePassword } from '@/event/account/dashboard/messageUpdatePassword'
import { dashboardModifyPassword } from '@/utils/account/dashboard/dashboardModifyPassword'
import { passwordUpdate } from '@/event/account/dashboard/password/passwordUpdate'

const oldPassword = ref('')
const password = ref('')
const newPassword = ref('')
const showOldPassword = ref(false)
const showPassword = ref(false)
const showNewPassword = ref(false)
const focused = ref('')

const isMinLengthValid = computed(() => password.value.length >= 10)
const hasUpperCase = computed(() => /[A-Z]/.test(password.value))
const hasLowerCase = computed(() => /[a-z]/.test(password.value))
const hasNumber = computed(() => /[0-9]/.test(password.value))

const hasSpecialChar = computed(() => {
  const forbiddenSpecialCharRegex = /[<>$"'&]/
  const allowedSpecialCharRegex = /[!@#%^*()[\]{};:,.\/?\\|`~+=_-]/
  return (
    password.value.length > 0 &&
    allowedSpecialCharRegex.test(password.value) &&
    !forbiddenSpecialCharRegex.test(password.value)
  )
})
const identicPassword = computed(
  () =>
    password.value.length > 0 &&
    newPassword.value.length > 0 &&
    password.value === newPassword.value
)

watch(
  () => passwordUpdate.value,
  () => {
    if (passwordUpdate.value == true) {
      password.value = ''
      oldPassword.value = ''
      newPassword.value = ''
      passwordUpdate.value = false
    }
  }
)
</script>

<template>
  <section class="section-form-password">
    <section class="section-indicate">
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="identicPassword" />
        <p>Les 2 mot de passe sont identique</p>
      </div>
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="hasUpperCase" />
        <p>1 lettre en majuscule</p>
      </div>
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="hasLowerCase" />
        <p>1 lettre en minuscule</p>
      </div>
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="hasNumber" />
        <p>1 chiffre</p>
      </div>
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="hasSpecialChar" />
        <p>1 caractère spéciale</p>
      </div>
      <div class="text-indicate">
        <IconSuccessRoundedPassword :validate="isMinLengthValid" />
        <p class="text-pretty">Le mort de passe doit contenir minimum 10 caractères</p>
      </div>
    </section>

    <form
      action=""
      class="form-dashboard-password"
      @submit.prevent="dashboardModifyPassword(password, newPassword, oldPassword)"
    >
      <div class="form-group-password">
        <input
          :type="showOldPassword ? 'text' : 'password'"
          v-model="oldPassword"
          id="oldpassword"
          required
          @focus="focused = 'oldpassword'"
          @blur="focused = ''"
          :class="{ 'has-value': oldPassword !== '' || focused === 'oldpassword' }"
        />
        <label for="oldpassword" :class="{ active: oldPassword !== '' || focused === 'password' }">
          <span>*</span> Ancien mot de passe
        </label>
        <span class="eye-icon-dashboard" @click="showOldPassword = !showOldPassword"
          ><template v-if="showOldPassword"><IconEye /></template
          ><template v-else><IconEyeOff /></template
        ></span>
      </div>

      <div class="form-group-password">
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          id="password"
          required
          @focus="focused = 'password'"
          @blur="focused = ''"
          :class="{ 'has-value': password !== '' || focused === 'password' }"
        />
        <label for="password" :class="{ active: password !== '' || focused === 'password' }">
          <span>*</span> Mot de passe
        </label>
        <span class="eye-icon-dashboard" @click="showPassword = !showPassword"
          ><template v-if="showPassword"><IconEye /></template
          ><template v-else><IconEyeOff /></template
        ></span>
      </div>

      <div class="form-group-password">
        <input
          :type="showNewPassword ? 'text' : 'password'"
          v-model="newPassword"
          id="newpassword"
          required
          @focus="focused = 'newpassword'"
          @blur="focused = ''"
          :class="{ 'has-value': newPassword !== '' || focused === 'newpassword' }"
        />
        <label
          for="newpassword"
          :class="{ active: newPassword !== '' || focused === 'newpassword' }"
        >
          <span>*</span> Confirmer le mot de passe </label
        ><span class="eye-icon-dashboard" @click="showNewPassword = !showNewPassword">
          <template v-if="showNewPassword"><IconEye /></template
          ><template v-else><IconEyeOff /></template
        ></span>
      </div>
      <ButtonModify />
    </form>
  </section>
</template>

<style scoped>
.div-button-alert {
  @apply flex justify-end;
}

.bullet {
  @apply relative pl-4;
}

.bullet::before {
  content: '•';
  position: absolute;
  left: 0;
}
/* .success {
  @apply flex flex-col bg-lime-eronor px-3 pt-2 pb-3 rounded-md absolute z-30 top-10;
}
.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-3 rounded-md absolute z-30 top-10;
} */
.eye-icon-dashboard {
  @apply absolute top-2/4 right-2;

  transform: translateY(-50%);
  cursor: pointer;
}

.svg-eye,
.svg-eye-off {
  @apply w-5 h-5;
}

/** */

.section-form-password {
  @apply relative flex flex-col items-center;
}
.form-dashboard-password {
  @apply flex flex-col items-center gap-8 pt-8 bg-slate-eronor p-3 rounded-md;
}
.form-dashboard-password input {
  @apply rounded-2xl border-2 outline-none focus:border-red-eronor;
}
.form-group-password {
  @apply relative flex flex-col items-center;
}
label {
  @apply absolute  text-slate-eronor;
  transition: all 1s ease;
}
input {
  @apply text-slate-eronor text-center min-w-64;
}
label.active {
  transition: all 1s ease;
}
input.has-value + label,
input:focus + label {
  @apply -top-6 text-white-eronor;
}
span {
  @apply text-red-eronor;
}
.section-indicate {
  @apply flex flex-col gap-1 pb-3 max-w-72;
}
.text-indicate {
  @apply flex gap-1 items-start;
}
@media screen and (min-width: 1000px) {
  .section-form-password {
    @apply flex-row justify-center;
  }
}
</style>
