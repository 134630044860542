import { computed } from 'vue'
import orders from '../orders'

export const orderGet = computed(() => {
  return orders.state.orderData
    .map((order) => {
      // Formatage de la date_added
      const dateAdded = order.date_added?.date ? new Date(order.date_added.date) : null
      const dateModified = order.date_modified?.date ? new Date(order.date_modified.date) : null
      const orderDate = order.order_date?.date ? new Date(order.order_date.date) : null

      // Formater date_added et date_modified pour afficher année, mois, jour, heure et minute
      const formatDate = (date: Date | null) => {
        if (!date) return ''
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Mois de 01 à 12
        const day = String(date.getDate()).padStart(2, '0') // Jour de 01 à 31
        const hour = String(date.getHours()).padStart(2, '0') // Heure de 00 à 23
        const minute = String(date.getMinutes()).padStart(2, '0') // Minute de 00 à 59
        return `${year}/${month}/${day} ${hour}h${minute}`
      }

      // Formater order_date pour afficher seulement année, mois et jour
      const formatOrderDate = (date: Date | null) => {
        if (!date) return ''
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Mois de 01 à 12
        const day = String(date.getDate()).padStart(2, '0') // Jour de 01 à 31
        return `${year}/${month}/${day}`
      }

      const isPaid = (order: any) => {
        // Vérification si total_NKT, total_TIC, ou total_CC sont non vides et égaux à total
        const total = parseFloat(order.total)
        const totalNKT = parseFloat(order.total_NKT)
        const totalCC = parseFloat(order.total_CC)
        const totalTIC = parseFloat(order.total_TIC)

        // Si l'un des totals est égal au total de la commande, on considère comme "réglé"
        if (
          (totalNKT > 0 && total === totalNKT) ||
          (totalCC > 0 && total === totalCC) ||
          (totalTIC > 0 && total === totalTIC)
        ) {
          return 'Réglé'
        } else {
          return 'Non réglé'
        }
      }

      return {
        ...order,
        total: parseFloat(order.total),
        date_added: formatDate(dateAdded),
        date_modified: formatDate(dateModified),
        order_date: formatOrderDate(orderDate),
        raw_date_added: dateAdded,
        payment_status: isPaid(order)
      }
    })
    .sort((a, b) => {
      // Trier par date_added (raw_date_added) du plus récent au plus ancien
      const dateA = a.raw_date_added ? a.raw_date_added.getTime() : 0
      const dateB = b.raw_date_added ? b.raw_date_added.getTime() : 0
      return dateB - dateA
    })
})
