import { ref } from 'vue'

export const messageEmptyDeleteAddress = {
  city: '',
  address: '',
  class: '',
  address_id: 0
}

export const messageDeleteAddress = ref({ ...messageEmptyDeleteAddress })

export interface TypeMessageDeleteAddress {
  city: string
  address: string
  class: string
  address_id: number
}
