import { productBus } from '@/event/product/productBus'

export const getOptionNameById = (optionId: number, languageId: number) => {
  const productValue = productBus.value

  if (!productValue) return undefined // Check if product is null

  const option = productValue.options.find((opt) => opt.option_id === optionId)
  if (option) {
    const language = option.language.find((lang) => lang.language_id === languageId)
    return language ? language.name : undefined // Return the name or undefined if not found
  }

  return undefined
}
