import { dashboardArianeBus } from '@/event/dashboard/dashboardArianeBus'

export function selectLinkAriane(routePath: string) {
  const data = {
    active: false,
    link: '',
    name: ''
  }

  if (routePath.includes('edit')) {
    data.active = true
    data.link = '/dashboard/edit'
    data.name = 'Modifier mes informations'
  } else if (routePath.includes('password')) {
    data.active = true
    data.link = '/dashboard/password'
    data.name = 'Mot de passe'
  } else if (routePath.includes('address')) {
    data.active = true
    data.link = '/dashboard/address'
    data.name = "Carnet d'adresses"
  } else if (routePath.includes('dashboard/order')) {
    data.active = true
    data.link = '/dashboard/order'
    data.name = 'Historique de commandes'
  } else if (routePath.includes('transaction')) {
    data.active = true
    data.link = '/dashboard/transaction'
    data.name = 'Transactions'
  } else if (routePath.includes('recurring')) {
    data.active = true
    data.link = '/dashboard/recurring'
    data.name = 'Carte de paiement'
  } else if (routePath.includes('basket')) {
    data.active = true
    data.link = '/basket'
    data.name = 'Panier'
  }
  dashboardArianeBus.value = data
}
