import { isAuthenticated } from '@/event/account/auth/authentification'
import { excludeRouteLoginArray } from '@/storage/navbar/excludeRoute'

// const excludedRouteLogin = (routeName: any) => {
//   return ['login', 'register', 'confirm-register', 'forget'].includes(routeName)
// }

export const goOutLogin = (routeName: any) => {
  //   return excludedRouteLogin(routeName) && isAuthenticated.value
  return excludeRouteLoginArray.includes(routeName) && isAuthenticated.value
}
