<script setup lang="ts"></script>

<template>
  <button class="button-continue-order">Continuer</button>
</template>

<style scoped>
.button-continue-order {
  @apply bg-lime-eronor p-2 text-sm rounded-md;
}
</style>
