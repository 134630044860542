import { cartBus, indexUpdateElement } from '@/event/cartBus'
import { productBus } from '@/event/product/productBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import { showNextButtonBus } from '@/event/product/showNextButtonBus'
import products from '@/storage/products'
import { buttonNextOption } from '@/utils/product/buttonNextOption'
import { clearSelection } from '@/utils/product/clearSelection'
import { updateValues } from '@/utils/product/updateValues'
import { watch } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export function watchersProduct(route: RouteLocationNormalizedLoaded) {
  watch(() => cartBus.value, clearSelection, { deep: true })

  watch(
    () => route.params,
    () => {
      updateValues(route)
    }
  )

  watch(
    () => indexUpdateElement.value,
    (newValue) => {
      clearSelection()
      if (newValue >= 0) updateValues(route)
    }
  )

  watch(
    () => productBus.value,
    () => {
      if (productBus.value) {
        buttonNextOption(productBus.value.options, showNextButtonBus)
      }
    }
  )

  watch(
    () => relatedGroupBus.value,
    () => {
      if (productBus.value) {
        buttonNextOption(productBus.value.options, showNextButtonBus)
      }
    },
    { deep: true }
  )

  watch(
    () => products.state.productData,
    (newValue) => {
      updateValues(route)
    },
    { deep: true }
  )
}
