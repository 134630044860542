import { URL_BACK, URL_IMAGE } from '@/config/constants'
import { baseUrl, firstPartUrl, url } from '@/event/url/url'
import setting from '@/storage/setting'
import stores from '@/storage/stores'
import { reactive } from 'vue'
import type { Router } from 'vue-router'

export async function getStore() {
  let response: Response

  try {
    const url =
      firstPartUrl === 'http'
        ? `${firstPartUrl}://127.0.0.1:8080/api/store/search_url`
        : `${firstPartUrl}://${URL_BACK}/api/store/search_ssl`

    response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      // credentials: 'include',
      body: JSON.stringify({ url: baseUrl })
    })

    if (!response.ok) {
      throw new Error('Failed to fetch store data')
    }
    const storeData = await response.json()

    if (storeData && storeData.setting) {
      // Now check for the opening hours setting
      const openingHoursSetting = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_opening_hours'
      )
      const deliveryZonesSetting = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_delivery_zones'
      )
      const deliveryTimeSettings = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
      )

      if (
        openingHoursSetting &&
        typeof openingHoursSetting.value === 'string' &&
        openingHoursSetting.value.length > 0
      ) {
        const openingHoursString = openingHoursSetting.value
        const openingHoursJson = JSON.parse(openingHoursString)
        if (openingHoursJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_opening_hours'
          ).value = openingHoursJson
        }
      }
      if (
        deliveryZonesSetting &&
        typeof deliveryZonesSetting.value === 'string' &&
        deliveryZonesSetting.value.length > 0
      ) {
        const deliveryZonesJson = JSON.parse(deliveryZonesSetting.value)
        if (deliveryZonesJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_delivery_zones'
          ).value = deliveryZonesJson
        }
      }
      if (
        deliveryTimeSettings &&
        typeof deliveryTimeSettings.value === 'string' &&
        deliveryTimeSettings.value > 0
      ) {
        const deliveryTimeSettingsJson = JSON.parse(deliveryTimeSettings.value)
        if (deliveryTimeSettingsJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
          ).value = deliveryTimeSettingsJson
        }
      }
    }

    stores.setStoresData(storeData)

    if (stores.state.storeData.name && stores.state.storeData.setting) {
      document.title = stores.state.storeData.name

      updateFavicon(
        stores.state.storeData.setting.find((s: { key: string }) => s.key === 'config_logo')?.value
      )
    }
  } catch (e) {
    // console.log(e)
  }
  //   loading = false
}

function updateFavicon(iconUrl: string) {
  let link = document.getElementById('icon-tab') as HTMLLinkElement
  if (link) {
    link.href = URL_IMAGE + iconUrl
  }
}
