import { computed } from 'vue'
import stores from './stores'

export interface DeliveryTimeType {
  forward_ordering: string
  after_opening: number
  before_closing: number
  time_range: string | null
  waiting_time: number
}

export const configDeliveryTime = computed(() => {
  const dataDeliveryTime = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'config_delivery_time_settings'
  )
  return dataDeliveryTime?.value || []
})
