import { getCurrentStoreId } from '@/storage/stores'
import { decodeBase64Url } from './decodeBase64Url'

export function isTokenExpired(token: string) {
  if (!token) return true

  const parts = token.split('.')
  if (parts.length !== 3) return true

  const payloadBase64Url = parts[1]
  const payloadBase64 = decodeBase64Url(payloadBase64Url)
  const payload = JSON.parse(atob(payloadBase64)) // Décoder le payload

  const currentTime = Math.floor(Date.now() / 1000) // Temps actuel en secondes
  const currentStoreId = getCurrentStoreId()

  return payload.exp < currentTime || payload.storeId !== currentStoreId // Vérifie si le token est expiré
}
